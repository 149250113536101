.carousel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(17);
    transition: .9s all ease;

    &-numbers {
        overflow-x: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-number {
        background: none;
        border: none;
        color: $black;
        font-size: rem(20);
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 rem(3);
        padding: 0 rem(6);
        transition: 0.6s all ease;
        min-width: rem(32);
        min-height: rem(32);

		div {
			font-size: rem(14);
			display: flex;
			align-items: center;
			vertical-align: middle;
		}
    }

    &-isActive {
        color:  $white;
        background-color: $light_blue;
        border-radius: rem(4);
        opacity: 1;
    }

    &-btn {
        background: none;
        border: none;
        min-width: rem(32);
        min-height: rem(32);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 3px;

        div {
            display: flex;
            align-items: center;
            vertical-align: middle;
        }
    }

    &-prev {
        transform: rotate(180deg);
        //margin-top: rem(5);
    }
    &-next {
        //margin-top: rem(-5);
        cursor: pointer;
    }
}

