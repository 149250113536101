.wrapper {
    .fc {
        .fc-holiday {
            .fc-daygrid-day-frame {
                background-color: var(--lightBlue)0d;
            }

            .fc-daygrid-day-number {
                color: lightBlue;
            }
        }

        .fc-weekend .fc-holiday {
            .fc-daygrid-day-frame {
                background-color: var(--lightBlue)0d;
            }

            .fc-daygrid-day-number {
                color: var(--lightBlue)4d;
            }
        }

        .fc-daygrid-day .fc-day-today {
            .fc-daygrid-day-frame {
                background-color: var(--lightBlue)1a;
            }
        }

        .fc-popover {
            .fc-popover-close {
                &:hover {
                    color: lightBlue;
                }
            }
        }
    }
}