.modal {
	&__container {
		position: fixed;
		inset: 0;
		background: rgba(0, 0, 0, 0.50);
		z-index: 10;
	}

	&__block {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: none;
		overflow: auto;
		outline: none;
		//max-height: 90vh;
		width: 90%;
		background-color: rgb(255, 255, 255);
		//padding: 0;
		border-radius: rem(10);
		box-shadow: 0 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);

		//min-width: rem(532);
		padding: rem(20);
		max-width: rem(532);
		//height: calc(-100px + 100vh);
		max-height: rem(846);

		@media (max-width: 1020px) {
			min-width: unset;
		}

		@media (max-width: 768px) {
			width: 100%;
			transform: unset;
			top: unset;
			left: 0;
			bottom: 0;
			max-width: unset !important;
			min-width: unset;
			max-height: 100%;
			overflow: unset;
			height: fit-content;
			overflow: scroll;
		}

		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: rem(15);
			z-index: 2;

			@media (max-width: 768px) {
				position: fixed;
				right: rem(20);
			}

			p {
				color: $black;
				font-size: rem(24);
				line-height: rem(32);
				font-weight: 700;
			}
		}

		&-close {
			margin-left: auto;
			border: none;
			background-color: transparent;
			display: flex;
			align-items: center;
		}
	}

	&__setting {
		overflow: visible;
		min-width: rem(420);
		width: auto;

		&__header {
			padding-bottom: rem(34);

			p {
				font-size: rem(15);
				text-transform: uppercase;
			}
		}
	}

	&__close-outside {
		.modal__block-close {
			position: absolute;
			bottom: 100%;
			left: 100%;
		}
	}
}

.buy-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: $white;

	&-icon {
		padding-bottom: rem(20);

		span {
			border-radius: rem(4);
			background: linear-gradient(90deg, #467FFF 0%, #A414FF 100%);
			padding: rem(5);
			max-width: rem(65);
			width: 100%;
		}
	}

	&-header {
		padding-bottom: rem(20);

		h4 {
			font-size: rem(20);
			line-height: rem(22);
		}
	}

	&-text {
		font-size: rem(14);
		line-height: rem(18);
		text-align: center;
		opacity: 0.5;
		margin-bottom: rem(20);
	}

	&-btn {
		width: 100%;
	}
}

.dialog {
	&__right {
		&__container {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			background: rgba(0, 0, 0, 0.50);
			z-index: 99;
			margin-right: rem(-30);
		}

		&__block {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			height: 100%;
		}
	}
}

.info-pc {
	//padding: rem(10) 0;

	&__header {
		display: flex;
		align-items: center;
		padding: rem(10) 0;

		.btn {
			border-radius: rem(4);
			background: rgba(255, 255, 255, 0.10);
			padding: rem(2) rem(8);

			&:hover {
				color: white;
			}

			&-red {
				&:hover {
					color: #FF5858;
				}
			}
		}
	}

	&__content {}

	&__block {
		padding: rem(15) 0;
		color: $white;

		p {
			font-size: rem(16);
			line-height: rem(22);
		}

		p + p {
			margin-top: rem(5);
		}
	}
}
