.feed {
    display: flex;
    padding: rem(40) rem(60);
    gap: rem(20);

    @media (max-width: 1430px) {
        padding: rem(40) rem(0) rem(40) rem(20);
    }

    @media (max-width: 1020px) {
        gap: 1.875rem;
        padding: 0;
        flex-direction: column;
    }

    &-left {
        display: flex;
        flex-direction: column;
        gap: rem(10);
        width: rem(315);

        @media (max-width: 1020px) {
            padding: 0 1rem;
            width: 100%;
            min-height: unset;
            gap: 0;
        }
    }

    &-right {
        display: flex;
        flex-direction: column;
        position: relative;
        width: calc(100% - 21rem);
        gap: rem(20);

        @media (max-width: 1430px) {
            padding: 0;
        }

        @media (max-width: 1020px) {
            gap: 1.25rem !important;
            padding: 0 0 6.25rem 0;
            width: 100%;
        }
    }

    &-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1430px) {
            padding: 0 rem(20) 0 0;
        }

        @media (max-width: 1020px) {
            padding: 0 1rem;
        }

        h3 {
            font-weight: 700;
            font-size: 2rem;
            line-height: 2.5rem;


            @media (max-width: 1020px) {
                font-size: 1.125rem;
                line-height: 2.375rem;
            }
        }

        div {
            display: flex;
            align-items: center;
            gap: 0.313rem;
            cursor: pointer;

            h4 {
                color: #000;
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;

                @media (max-width: 1020px) {
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                }
            }
        }
    }

    &-title {
        h1 {
            font-weight: 700;
            font-size: 2rem;
            line-height: 2.375rem;

            @media (max-width: 1020px) {
                font-size: 1.125rem;
                line-height: 1.375rem;
            }
        }
    }

    &-birthdays {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1.875rem;

        &__title {
            display: flex;
            align-items: center;
            gap: 0.625rem;

            span {
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 2rem;

                @media (max-width: 1020px) {
                    font-weight: 400;
                    font-size: 1.125rem;
                    line-height: 2rem;
                }
            }
        }

        &__bodyHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;


            @media (max-width: 1020px) {
                padding-right: 1rem;
            }

            & p {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
                color: #007BFF;
                cursor: pointer;
                display: flex;
                align-items: center;

                @media (max-width: 1020px) {
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                }
            }
        }

        &__birthdaysList {
            display: grid;
            grid-template-columns: repeat(7, minmax(8.25rem, 1fr));
            grid-auto-rows: 1fr;
            grid-gap: 0.625rem;
            margin-top: rem(15);

            @media (max-width: 1020px) {
                overflow: scroll;
                padding: 0 1rem;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            @media (max-width: 1020px) {
                overflow: scroll;
                grid-template-columns: repeat(7, minmax(6.25rem, 1fr));
                grid-gap: 0.625rem;
                padding-right: 1rem;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            & > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 10.625rem;
                gap: 0.625rem;
                background: #ffffff;
                border-radius: 1rem;
                padding: 0.625rem;
                transition: 0.5s all ease;
                cursor: pointer;

                @media (max-width: 1020px) {
                    overflow-x: scroll;
                    height: fit-content;
                }

                &:hover {
                    transform: scale(1.1);

                    @media (max-width: 1020px) {
                        transform: unset;
                    }
                }

                & > img {
                    width: 5rem;
                    height: 5rem;
                    border-radius: 1.25rem;
                }

                & > p {
                    text-align: center;
                    text-wrap: wrap;
                    width: 6.25rem;
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.25rem;

                    @media (max-width: 1020px) {
                        font-weight: 500;
                        font-size: 0.75rem;
                        width: 3.25rem;
                    }
                }
            }
        }
    }

    &-banners {
        position: relative;
        height: rem(300);
        display: grid;
        grid-gap: 1.25rem;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, minmax(30rem, 1fr));

        @media (max-width: 1430px) {
            overflow-y: scroll;
            padding: 0 rem(20) 0 0;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @media (max-width: 1020px) {
            padding: rem(10);
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: rem(15);
            height: unset;
        }

        &__block {
            color: #003166;
            width: 100%;
            padding: 0;
            position: relative;
            min-height: rem(102);
            overflow-x: hidden;
            overflow-y: hidden;
            font-weight: 500;
            border-radius: 8px;

            @media (max-width: 1020px) {
                height: rem(215);
            }

            & h1 {
                width: rem(210);
                position: absolute;
                color: #fff;
                font-size: rem(24);
                font-weight: 900;
                line-height: rem(26);
                left: rem(30);
                bottom: rem(30);

                @media (max-width: 1020px) {
                    font-size: rem(20);
                    left: rem(20);
                    bottom: rem(20);
                }
            }

            &__icon {
                position: absolute;
                width: rem(50);
                height: rem(50);
                top: rem(30);
                left: rem(30);
                z-index: 1;

                @media (max-width: 1020px) {
                    left: rem(20);
                    bottom: rem(20);
                    width: rem(35);
                    height: rem(35);
                }
            }

            &__image {
                position: relative;
                width: 100%;
                height: 100%;
                display: block;
                max-width: 100%;
                transition: 0.2s all ease;
                border-radius: 0.5rem;

                &:hover {
                    transform: scale(1.04);
                }
            }
        }
    }


    &-leftBanners {
        color: #003166;
        width: 100%;
        padding: 0;
        position: relative;
        min-height: 102px;
        overflow-x: hidden;
        overflow-y: hidden;
        font-weight: 500;
        border-radius: 8px;

        &__block {
            cursor: pointer;
            height: 100%;
            min-height: 6.375rem;
        }

        &__image {
            height: 100%;
            display: block;
            max-width: 100%;
            transition: 0.2s all ease;
            border-radius: 0.5rem;
        }
    }

    &-groups {
        position: relative;

        &__container {
            margin-top: 1.25rem;
            display: grid;
            grid-template-columns: repeat(3, minmax(19.688rem, 1fr));
            grid-auto-rows: 1fr;
            grid-gap: rem(15);

            @media (max-width: 1430px) {
                overflow: scroll;
                padding: 0 rem(20) 0 0;
            }

            @media (max-width: 1020px) {
                margin-top: rem(15);
                overflow: scroll;
                padding: 0 1rem;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-slider {
            position: relative;
            height: 12.5rem;
            user-select: none;
            border-radius: 0.5rem;
            cursor: pointer;

            .fade-enter {
                opacity: 0;
            }

            .fade-enter-active {
                opacity: 1;
                transition: opacity 0.5s;
            }

            .fade-exit {
                opacity: 1;
            }

            .fade-exit-active {
                opacity: 0;
                transition: opacity 0.5s;
            }

            .removeBackFace {
                width: 100%;
                height: 100%;
                border-radius: 0.5rem;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                transform: translate3d(0, 0, 0);
            }

            &__typeContent {
                display: flex;
                align-items: center;
                gap: 0.375rem;
                justify-content: center;
                position: absolute;
                font-weight: 700;
                font-size: 1.125rem;
                left: 1rem;
                top: 1rem;
                z-index: 1;

                & p {
                    color: #FFFFFF;
                    font-weight: 700;
                    font-size: 0.75rem;
                    line-height: 1.25rem;
                }
            }

            &__title {
                position: absolute;
                font-weight: 700;
                font-size: 1.125rem;
                left: 1rem;
                bottom: 2rem;
                color: #FFFFFF;
                z-index: 1;
            }

            &__dotContainer {
                position: absolute;
                left: 1rem;
                /* top: 140px; */
                bottom: 1rem;
                display: flex;
                z-index: 1;
            }

            &__dot {
                width: 0.5rem;
                height: 0.5rem;
                margin-right: 0.5rem;
                border-radius: 50%;
                cursor: pointer;
            }

            &__backgroundContent {
                width: 100%;
                position: absolute;
                height: 100%;
                z-index: 1;
                background: #000000;
                opacity: 0.3;
                border-radius: .5rem;
            }

            &__container {
                & img {
                    max-width: 100%;
                    width: 100%;
                    border-radius: 0.5rem;
                    height: 100%;
                    object-fit: cover;
                    transition: .5s all ease !important
                }
            }

            &__innerContainer {
                width: 100%;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                transform: translate3d(0, 0, 0);

                & div {
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                }
            }

        }
    }


    &-news {
        &__content {
            margin-top: 1.25rem;
            display: grid;
            grid-template-columns: repeat(3, minmax(19.625rem, 1fr));
            grid-auto-rows: 1fr;
            grid-gap: 1.25rem;

            @media (max-width: 1430px) {
                overflow-y: scroll;
                padding: 0 rem(20) 0 0;
                grid-template-columns: repeat(6, minmax(19.625rem, 1fr));

                &::-webkit-scrollbar {
                    display: none;
                }
            }
            
            @media (max-width: 1020px) {
                display: flex;
                align-items: center;
                overflow-x: scroll;
                padding: 0 1rem;
                margin-top: 0.625rem;
                grid-template-columns: repeat(6, minmax(rem(315), 1fr));

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &-card {
            position: relative;
            cursor: pointer;
            margin-bottom: 0;
            border-radius: 1rem;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            transition: 0.2s all ease;

            @media (max-width: 1020px) {
                //flex-direction: row;
                //justify-content: center;
                //align-items: center;
                //height: fit-content;
                //gap: 0.625rem;
                //padding: 0.313rem 0.938rem 0.313rem 0.313rem;
            }

            &:hover {
                box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.1);

                @media (max-width: 1020px) {
                    box-shadow: unset;
                }
            }

            &__img {
                max-height: rem(172);
                height: 100%;
                overflow: hidden;
                position: relative;
            }

            img {
                border-top-right-radius: rem(16);
                border-top-left-radius: rem(16);
                width: 100%;
                height: 100%;
                object-fit: cover;

                //@media (max-width: 1020px) {
                //    width: 7.5rem;
                //    height: 7.5rem;
                //    border-radius: 0.75rem;
                //}
            }

            .item__status-block {
                bottom: 0.625rem !important;
                right: 0.625rem !important;
            }

            &__clip, &__active {
                position: absolute;
            }

            &__clip {
                top: rem(20);
                left: rem(20);
            }

            &__active {
                bottom: rem(10);
                right: rem(10);
                height: rem(24);
                border-radius: rem(15);
                padding: rem(3) rem(12) rem(3) rem(7);
                gap: rem(8);
                display: flex;
                align-items: center;
                background: #00A7B559;
                font-size: rem(12);
                line-height: rem(18);
                font-weight: 500;
                color: $white;
                backdrop-filter: blur(8px);

                &.hide {
                    background: #E1000059;
                }

                div {
                    width: rem(10);
                    height: rem(10);
                    border-radius: 50%;
                    background: #E6E6E6;

                    &.active {
                        background: #00A7B5;
                    }

                    &.hide {
                        background: #E10000;
                    }
                }
            }

            &__infoBox {
                position: relative;
                padding: 1.25rem;
                color: #003166;
                font-size: 1.125rem;
                line-height: 1.625rem;
                font-weight: 600;
                display: flex;
                flex-direction: column;
                gap: rem(10);
                height: 100%;

                &__top {
                    display: flex;
                    gap: rem(20);
                    justify-content: space-between;
                }

                //@media (max-width: 1020px) {
                //    padding: 0.875rem 0;
                //    gap: 0;
                //}

                h5 {
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: rem(24);
                    text-align: left;
                    min-height: 3.25rem;
                    margin-bottom: auto;
                    color: #000;

                    @media (max-width: 1020px) {
                        font-size: 0.875rem;
                        line-height: 1.125rem;
                    }
                }
            }

            &__catTitleStyle {
                max-width: fit-content;
                padding: 0.25rem 1rem;
                font-size: rem(10);
                font-weight: 600;
                line-height: rem(18);
                text-align: left;

                color: #00A7B5;
                background: #00A7B51A;
                border-radius: 6.25rem;

                @media (max-width: 1020px) {
                    padding: 0.25rem 0.75rem;
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                }
            }

            &__placeholder {
                height: calc(100% - 9.5rem);
                width: 100%;
                background-color: $blueColor + '15'; // Assuming blueColor is defined as a SCSS variable
                border-radius: 1.25rem 1.25rem 0 0;
            }

            &__dateBox {
                display: flex;
                text-wrap: nowrap;
                align-items: center;
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 1.25rem;
                color: #003166;
                padding-top: 0.25rem;
                opacity: 0.5;
                width: 100%;

                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                    opacity: 0.3;

                    @media (max-width: 1020px) {
                        width: 1rem;
                        height: 1rem;
                    }
                }

                @media (max-width: 1020px) {
                    font-size: 0.625rem;
                    justify-content: center;
                }
            }

            &__likeDateBox {
                display: flex;
                text-wrap: nowrap;
                align-items: center;
                font-size: 0.75rem;
                font-weight: 700;
                line-height: 1.25rem;
                color: #003166;
                padding-top: 0.25rem;
                width: 100%;

                svg {
                    width: 1.25rem;
                    height: 1.25rem;

                    @media (max-width: 1020px) {
                        width: 1rem;
                        height: 1rem;
                    }
                }

                @media (max-width: 1020px) {
                    font-size: 0.625rem;
                    justify-content: center;
                }
            }

            &__eyeBlock {
                display: flex;
                align-items: center;
                margin-left: auto;
            }

            &__likeContent {
                svg {
                    width: 1.25rem;
                    height: 1.25rem;

                    @media (max-width: 1020px) {
                        width: 1rem;
                        height: 1rem;
                    }
                }
            }

            &__cardBackground {
                min-height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
            }

            &__content {
                display: flex;
                align-items: center;
                gap: 1rem;
                justify-content: space-between;
            }

            &__stats {
                display: flex;
                align-items: center;
                gap: 1rem;

                &__block {
                    display: flex;
                    align-items: center;
                    gap: rem(6);

                    & h1 {
                        font-size: rem(12);
                        font-weight: 600;
                        line-height: rem(20);
                    }

                }
            }

            &__statsLeft {
                display: flex;
                align-items: center;
                gap: 1.563rem;
            }
        }
    }


    &-links {
        position: relative;

        @media (max-width: 1020px) {
            padding-right: 0;
        }

        &__content {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(4, minmax(14.375rem, 1fr));
            gap: 1.25rem;
            margin-top: 1.25rem;

            //@media (max-width: 1020px) {
            //    grid-template-columns: repeat(4, minmax(25%, 1fr));
            //    gap: 0;
            //}

            @media (max-width: 1020px) {
                display: flex;
                overflow-x: scroll;
                gap: 0.625rem;
                margin-top: 0;
                padding: 0 1rem;

                & a {
                    & div {
                        width: 9.688rem;
                        height: 8.875rem;
                    }
                }

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 6.25rem;
            padding: 1.5rem 1.25rem;
            cursor: pointer;
            background: #ffffff;
            border-radius: 1rem;
            gap: 0.625rem;
            transition: 0.5s all ease;

            @media (max-width: 1020px) {
                //margin: 0.625rem;
                height: 8.875rem;
                justify-content: center;
            }

            //@media (max-width: 1020px) {
            //    justify-content: center;
            //    margin: 0;
            //
            //    &:hover {
            //        transform: unset;
            //    }
            //}

            &:hover {
                transform: scale(1.1);

                @media (max-width: 1020px) {
                    transform: unset;
                }
            }

            &__imageBox {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 2.5rem;
                width: 2.5rem;
                color: blue;
                font-size: 0.875rem;
                font-weight: 600;
            }

            &__title {
                max-width: 100%;
                text-align: center;
                font-size: 0.875rem;
                line-height: 1.375rem;
                font-weight: 600;
            }
        }
    }


    &-events {

        &__content {
            margin-top: 1.25rem;
            display: grid;
            grid-template-columns: repeat(4, minmax(rem(230), 1fr));
            grid-auto-rows: 1fr;
            grid-gap: rem(10);

            @media (max-width: 1430px) {
                overflow-y: scroll;
                padding: 0 rem(20) 0 0;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            @media (max-width: 1020px) {
                overflow-y: scroll;
                padding: 0 1rem;
                margin-bottom: rem(10);

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &-card {
            position: relative;
            height: rem(315);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 0.5rem;

            &__backgroundLinear {
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.6) 100%);
                border-radius: 0.5rem;
            }

            &__content {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                color: #fff;
            }

            &__type {
                position: absolute;
                top: rem(20);
                left: rem(20);
                display: flex;
                align-items: center;
                gap: rem(10);

                & h1 {
                    font-size: rem(12);
                    font-weight: 700;
                    line-height: rem(16);
                }
            }

            &__desc {
                display: flex;
                flex-direction: column;
                position: absolute;
                bottom: rem(20);
                left: rem(20);
                gap: rem(20);

                & h1 {
                    font-size: rem(20);
                    font-weight: 700;
                    line-height: rem(26);
                }

                &__date {
                    background: #fff;
                    color: #000;
                    padding: rem(1) rem(10);
                    width: fit-content;
                    border-radius: rem(100);
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: rem(22);
                }
            }

            &__image {
                width: 100%;
            }
        }
    }


    &-bottomMobile {
        display: flex;
        flex-direction: column;
        margin-top: 0.625rem;
        padding: 0 1rem;
    }
}