.wrapper {
    background-color: var(--backgroundColor);
    opacity: var(--status);

    &:hover {
        background-color: var(--backgroundActionColor);
    }

    &:active {
        background-color: var(--backgroundActionColor);
    }
}

.name {
    color: var(--color);
}