.blogs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-item {
        padding: rem(30) 0;

        &-line {
            border-bottom: 1px solid #0000001A;
        }
    }

    &-card {
        &-user {
            display: flex;
            align-items: center;
            gap: rem(20);

            &__image {
                min-height: rem(44);
                min-width: rem(44);
                width: rem(44);
                height: rem(44);
                border-radius: rem(10);
            }

            &__content {
                display: flex;
                flex-direction: column;

                &__name {
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: 20px;
                }

                &__position {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 22px;
                    opacity: 0.5;
                }
            }
        }

        &-block {
            max-width: rem(650);
            position: relative;
            margin-top: rem(12);

            &__image {
                width: rem(650);
                max-width: rem(650);
                height: rem(355);
                background-position: center;
                background-size: cover;
                border-top-right-radius: rem(16);
                border-top-left-radius: rem(16);
            }

            &-content {
                position: relative;
                background: #fff;
                padding: rem(20);
                border-bottom-left-radius: rem(16);
                border-bottom-right-radius: rem(16);

                &__top {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                &__title {
                    font-size: rem(18);
                    font-weight: 600;
                    line-height: 24px;
                }
                
                &__likeIcon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }

                &__likeIcon.active {
                    & svg {
                        fill: #00A7B5;

                        & g {
                            opacity: 1;
                        }

                        & path {
                            stroke: #00A7B5;
                        }
                    }
                }

                &__category {
                    margin-top: rem(8);
                    width: fit-content;
                    border-radius: rem(100);
                    padding: rem(4) rem(16);
                    background: #00A7B50D;
                    font-size: rem(12);
                    font-weight: 600;
                    line-height: 24px;
                    color: #00A7B5;
                }

                &__text {
                    margin-top: rem(8);
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 22px;
                }

                &__bottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: rem(25);

                    &__right {
                        display: flex;
                        align-items: center;
                        gap: rem(20);
                    }

                    &__block {
                        display: flex;
                        align-items: center;
                        gap: rem(8);
                        opacity: .5;

                        & svg {
                            width: rem(20);
                            height: rem(20);
                        }

                        & p {
                            font-size: rem(14);
                            font-weight: 500;
                            line-height: 22px;
                        }
                    }
                    
                    &__likeContent {
                        & svg {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }


    &-drafts {
        &-content {
            padding: rem(30) rem(60);
            display: grid;
            grid-template-columns: repeat(4, minmax(rem(315), 1fr));
            grid-gap: rem(20);
        }

        &-card {
            background: #fff;
            border-radius: rem(16);
            max-height: rem(372);
            height: rem(372);

            &__image {
                width: 100%;
                max-height: rem(172);
                border-top-right-radius: rem(16);
                border-top-left-radius: rem(16);
            }

            &__content {
                display: flex;
                flex-direction: column;
                padding: rem(20);
                gap: rem(8);
            }

            &__title {
                font-size: rem(18);
                font-weight: 600;
                line-height: 24px;
            }

            &__category {
                width: fit-content;
                padding: rem(4) rem(16);
                background: #00A7B50D;
                font-size: rem(12);
                font-weight: 600;
                line-height: 24px;
                color: #00A7B5;
                border-radius: rem(100);
            }

            &__text {
                font-size: rem(14);
                font-weight: 500;
                line-height: 22px;
            }
        }
    }

    &-show {
        &-content {
            position: relative;
            display: flex;
            justify-content: space-between;
            gap: rem(30);
            padding: rem(30) rem(60);

            &__left {
                width: 100%;
            }
            &__right {
                display: flex;
                flex-direction: column;
                gap: rem(20);
                width: rem(315);
            }
        }

        &-info {
            position: relative;
            border-radius: rem(16);
            background: #fff;
            &__image {
                border-radius: rem(16);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: rem(512);
            }

            &__content {
                padding: rem(45) rem(30) rem(30) rem(30);
            }

            &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__practice {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #00A7B5;
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: 22px;
                    color: #fff;
                    padding: rem(4) rem(12);
                    border-radius: rem(100);
                }

                &__types {
                    display: flex;
                    align-items: center;
                    gap: rem(8);

                    &__item {
                        padding: rem(4) rem(12);
                        background: #00A7B50D;
                        font-size: rem(14);
                        font-weight: 600;
                        line-height: 22px;
                        color: #00A7B5;
                        border-radius: rem(100);
                    }
                }
            }

            &__editor {
                display: flex;
                flex-direction: column;
                gap: rem(30);
                margin-top: rem(20);

                & h5 {
                    font-size: rem(16);
                    font-weight: 500;
                    line-height: 28px;
                }

                & p {
                    font-size: rem(16);
                    font-weight: 400;
                    line-height: 28px;
                }
            }

            &__date {
                display: flex;
                align-items: center;
                gap: rem(10);
                margin-top: rem(30);
                opacity: .5;

                & p {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 22px;
                }

                & svg {
                    width: rem(20);
                }
            }

            &__bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: rem(30);
            }

            &__buttons {
                display: flex;
                align-items: center;
                gap: rem(20);

                & button {
                    display: flex;
                    align-items: center;
                    gap: rem(15);
                    padding: rem(13) rem(30);
                    border-radius: rem(8);
                    outline: none;

                    & p {
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: 24px;
                    }
                }

                &__likeButton {
                    background: #0000000D;
                    color: #00A7B5;
                    border: none;

                    & svg {

                    }
                }

                &__shareButton {
                    background: #fff;
                    color: #00A7B5;
                    border: 2px solid #0000001A
                }
            }

            &__stats {
                display: flex;
                align-items: center;
                gap: rem(20);

                &__block {
                    display: flex;
                    align-items: center;
                    gap: rem(8);
                    opacity: .5;

                    & p {
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: 22px;
                    }
                }
            }
        }

        &-card {
            position: relative;
            width: rem(315);
            border-radius: rem(16);
            background: #FFFFFF;

            &__image {
                width: 100%;
                border-top-right-radius: rem(16);
                border-top-left-radius: rem(16);
                max-height: rem(172);
            }

            &__content {
                padding: rem(20);
            }

            &__title {
                font-size: rem(16);
                font-weight: 600;
                line-height: 24px;
            }

            &__category {
                border-radius: rem(100);
                width: fit-content;
                padding: rem(4) rem(12);
                background: #00A7B51A;
                font-size: rem(12);
                font-weight: 600;
                line-height: 18px;
                margin-top: rem(8);
            }

            &__bottom {
                margin-top: rem(10);
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &__date {
                display: flex;
                align-items: center;
                gap: rem(6);

                & svg {
                    width: rem(20);
                    height: rem(20);
                    opacity: 0.2;
                }

                & p {
                    font-size: rem(12);
                    font-weight: 500;
                    line-height: 20px;
                    opacity: .5;
                }
            }

            &__stats {
                display: flex;
                align-items: center;
                gap: rem(15);

                &__block {
                    display: flex;
                    align-items: center;
                    gap: rem(6);

                    & svg {
                        opacity: .2;
                    }

                    & p {
                        font-size: rem(12);
                        font-weight: 500;
                        line-height: 20px;
                        opacity: .5;
                    }
                }
            }
        }
    }

    &-create {
        &-content {
            padding: rem(30) 0;

            &__buttons {
                margin-top: rem(35);
            }
        }

        &__form {
            display: flex;
            flex-direction: column;
            gap: rem(20);

            &-date {
                display: flex;
                gap: rem(20);
            }

            &-toggle {
                justify-content: left;
                gap: rem(15);
            }
        }

        &__preview {
            display: flex;
            flex-direction: column;
            gap: rem(15);
            margin-left: rem(100);

            &-items {
                border-radius: rem(16);
                background: rgba(0, 0, 0, 0.03);
            }

            &-item {
                padding: rem(20);
                gap: rem(20);

                &__header {
                    h3 {
                        font-size: rem(24);
                        font-weight: 700;
                        line-height: rem(29);
                        margin-bottom: rem(10);
                    }
                }

                &__content {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: rem(24);
                }
            }

            &__bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: rem(26);

                &__block {
                    display: flex;
                    align-items: center;
                    gap: rem(8);

                    & svg {

                    }
                }

                &__stats {
                    display: flex;
                    align-items: center;
                    gap: rem(20);
                }
            }

            &__image {
                border-top-left-radius: rem(16);
                border-top-right-radius: rem(16);
                width:100%;
                height: rem(230);
                background-size: cover;
                background-position: center;

                & img {border-top-left-radius: rem(16);
                    border-top-right-radius: rem(16);
                    width:100%;
                    max-height: rem(230);
                }
            }

            &__category {
                width: fit-content;
                padding: rem(4) rem(16);
                background: #00A7B50D;
                font-size: rem(12);
                font-weight: 600;
                line-height: 24px;
                color: #00A7B5;
                border-radius: rem(100);
                margin-top: rem(8);
            }

            &__text {
                font-size: rem(14);
                font-weight: 500;
                line-height: 22px;
                margin-top: rem(8);
            }
        }
    }
}