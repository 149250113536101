.structure {
    &__content {
        padding-top: rem(40);
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        row-gap: rem(60);

        @media screen and (max-width: 768px) {
            justify-content: center;
            row-gap: rem(30);
        }

        &__grid {
            display: flex;
            flex: 1 0 auto;
            gap: rem(20);
            overflow: auto;
            padding: 0 rem(30) rem(60);
            margin: 0 rem(-30) rem(-2);

            @media screen and (max-width: 1330px) {
                flex-direction: column;
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;
            }

            @media screen and (max-width: 400px) {
                align-items: inherit;
            }
        }

        &__column {
            position: sticky;
            //justify-content: center;
            top: rem(95);
            bottom: rem(15);

            &-block {
                position: relative;
                display: flex;
                flex-direction: column;
                row-gap: rem(10);
            }

            @media screen and (max-width: 1330px) {
                position: initial;
                align-items: center;
                width: 100%;
            }
        }

        &__drag {
            display: flex;
            align-items: center;
            justify-content: center;

            &__hidden {
                position: absolute;
                visibility: hidden;
            }
        }

        &__more {
            font-weight: 600;
            cursor: pointer;
            transition: all 0.2s linear;
            margin: 0 auto;

            &:hover {
                color: $lightBlue;
            }

            &.hide {
                opacity: 0;
                visibility: hidden;
            }
        }

        &__empty {
            font-size: rem(20);
            font-weight: 700;
        }

        &__item {
            position: relative;
            display: flex;
            width: 100%;
            font-weight: 500;
            border-radius: rem(16);
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            transition: all 0.2s linear;
            gap: rem(20);

            &:hover {
                box-shadow: none;
            }

            @media screen and (max-width: 1330px) {
                flex-direction: column;
            }

            &.active {

            }

            .subdivision {
                border-radius: 50%;
                background: #00A7B5;
                max-width: rem(40);
                max-height: rem(40);
                width: 100%;
                min-height: rem(40);
                min-width: rem(40);
                padding: 0;

                @media screen and (max-width: 1330px) {
                    transform: rotate(90deg);
                }
            }

            &-body {
                position: relative;
                display: flex;
                gap: rem(20);
                background-color: $white;
                border-radius: rem(20);
                transition: all 0.2s linear;
                padding: rem(10);
                max-width: rem(360);
                min-width: rem(360);
                width: 100%;

                &.open {
                    background: #00A7B540;
                }

                &-info {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    flex-grow: 2;
                    row-gap: rem(4);
                    letter-spacing: -0.02em;
                }

                &-name {
                    font-size: rem(18);
                    font-weight: 600;
                    line-height: rem(26);
                    transition: all 0.2s linear;
                    color: $black;
                }

                &-position {
                    display: flex;
                    flex-direction: column;
                    row-gap: rem(2);
                    transition: all 0.2s linear;
                }

                &-contantcs {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: rem(10) rem(20);
                    margin-top: rem(5);
                    z-index: 1;
                }

                &-icon-btn {
                    width: rem(32);
                    height: rem(32);
                    transition: all 0.2s linear;
                    margin: rem(-4);
                }

                &-account {
                    svg {
                        width: rem(18);
                    }
                }

                &-message {
                    svg {
                        width: rem(20);
                    }
                }

                &-favotite {

                }
            }

            &-avatar {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                text-transform: uppercase;
                transition: all 0.2s linear;
                max-width: rem(60);
                max-height: rem(60);
                min-height: rem(60);
                width: 100%;
                height: 100%;
                border-radius: rem(10);
                background-color: #f7f7f7;

                &:before {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: "";
                    opacity: 0;
                    z-index: 1;
                    position: absolute;
                    border-radius: 16px;
                    background-color: transparent;
                }

                img {
                    object-fit: cover;
                    border-radius: rem(10);
                }

                svg {
                    width: 35%;
                    height: 35%;
                }
            }
        }
    }
}