.analytics {
    position: relative;
    width: 100%;
    padding: rem(40) rem(70);

    &-body {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    &-content {
        width: 50%;

        &__items {
            position: relative;
            width: 100%;
            margin-top: rem(30);
        }
    }

    &-block {
        background: #FFFFFF;
        border-radius: rem(8);
        padding: rem(20);
        width: 100%;

        &__header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: rem(20);

            &__left {
                display: flex;
                align-items: center;
                gap: rem(20);
            }

            &__type {
                padding: 5px 12px;
                border-radius: 100px;
                background-color: #007BFF0D;
                color: rgb(0, 123, 255);
                font-weight: 700;
                font-size: rem(12);
            }

            &__operation {
                display: flex;
                align-items: center;
                gap: rem(20);

                &__balance, &__points {
                    display: flex;
                    gap: rem(5);
                    align-items: center;

                    p {
                        color: rgba(0, 49, 102, 0.533);
                        font-size: rem(12);
                    }
                }

                &__description {
                    font-size: rem(12);
                    color: rgba(0, 49, 102, 0.533);
                }
            }

            &__date {
                color: rgba(0, 49, 102, 0.533);
                font-size: rem(12);
            }
        }

        &__content {
            display: flex;
            align-items: center;
            gap: rem(30);
            padding: rem(20) 0;

            svg {
                opacity: .5;
                margin-left: rem(80);
            }
        }

        &__userInfo, &__adminInfo {
            display: flex;
            align-items: center;
            gap: rem(20);

            &__avatar {
                width: rem(45);
                height: rem(45);
            }

            &__desc {
                display: flex;
                flex-direction: column;

                h1 {
                    font-size: rem(14);
                    font-weight: 600;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    height: auto;
                    word-break: normal;
                    color: rgb(0, 49, 102);
                }

                p {
                    margin-top: rem(2);
                    color: rgba(0, 49, 102, 0.53);
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            &__receiver {
                color: rgb(0, 49, 102);
                font-size: 16px;
                font-weight: 600;
                margin-bottom: rem(5);
            }
        }
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &__left {
            display: flex;
            align-items: center;
            gap: rem(15);
        }

        &__right {
            display: flex;
            align-items: center;
            gap: rem(20);
            opacity: .6;

            svg {
                cursor: pointer;
            }
        }

        &__title {
            font-size: rem(24);
            line-height: rem(32);
            font-weight: 700;
        }
    }

    &-button {
        display: flex;
        align-items: center;
        position: relative;
        border: none;
        outline: none;
        padding: 13px 20px;
        background: white;
        color: black;
        font-size: 14px;
        font-weight: 600;
        text-transform: none;
        height: 50px;
        border-radius: rem(10);
        gap: rem(20);
    }
}