.ideas {
    position: relative;
    padding: 0;
    padding-bottom: rem(30);
    &-header {
        width: 100%;
        position: relative;
        padding: rem(30) rem(30) 0 rem(30);
        display: flex;
        flex-direction: column;
        gap: rem(32);
        border-bottom: rem(1) solid rgba(0, 49, 102, 0.075);

        &__top {
            display: flex;
            align-items: center;
            gap: rem(30);
        }

        &__right {
            display: flex;
            align-items: center;
            gap: rem(10);
            svg {
                cursor: pointer;
            }
        }
        &__title {
            font-size: rem(22);
            font-weight: 700;
        }

        &__selects {
            display: flex;
            gap: rem(30);

            .dropdown__block {
                width: 100%;
                min-width: rem(200);

                .react-select-prefix__control {
                    background: none;
                }

                .react-select-prefix__indicators {
                    display: none;
                }

                .react-select-prefix__menu-list {
                    width: 100% !important;
                }

                .react-select-prefix__value-container {
                    padding-left: 0;
                }

                .react-select-prefix__single-value {
                    text-wrap: wrap;
                }
            }

            &__second {
                width: 100%;

                .react-select-prefix__menu-list {
                    width: 100%;
                }
            }
        }
    }

    .breadcrumbs {
        padding: rem(20) rem(30);
    }

    &-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        gap: rem(20);

        &__button {
            width: 100%;
            background: rgb(23, 90, 253);
            margin-bottom: rem(20);
            height: rem(60);
            max-width: rem(600);
            border: none;
            border-radius: rem(8);
            padding: 0 16px;
            box-sizing: border-box;
            font-weight: 700;
            color: #FFFFFF;
            line-height: rem(43);
            font-size: rem(15);
            transition: all .2s ease-in-out;
        }

        &__items {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: rem(20);
        }
    }

    &-block {
        padding: rem(35);
        border-radius: rem(8);
        color: rgb(0, 49, 102);
        background: #FFFFFF;

        &__image {
            height: rem(300);
            width: rem(800);
            background: lightblue;
            max-width: 800px;
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: rem(20);
            padding: rem(20) rem(10);
        }

        &__title {
            color: rgb(0, 49, 102);
            font-size: 1.1rem;
            font-weight: 700;
        }

        &__type {
            width: fit-content;
            border-radius: rem(100);
            font-size: .6rem;
            font-weight: 700;
            padding: rem(5) rem(10);
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            background: rgba(23, 90, 253, 0.1);
            color: rgb(23, 90, 253);
        }

        &__bottom {
            display: flex;
            align-items: center;
        }

        &__time {
            display: flex;
            align-items: center;
            min-width: rem(200);
            gap: rem(10);

            .icon-clock {
            }
        }

        &__stats {
            display: flex;
            align-items: center;
            gap: rem(20);

            &__item {
                display: flex;
                align-items: center;
                gap: rem(10);
                opacity: .3;
            }
        }
    }

    &-show {
        position: relative;
        padding: 0;

        .ideas-body__button {
            margin-top: rem(20);
        }

        .breadcrumbs {
            padding: rem(20) rem(30);
        }

        &__flexBody {
            padding-right: rem(20);
            width: 100%;
            display: flex;
            gap: rem(20);
        }

        &__resultButton {
            margin-top: rem(-40);
            display: flex;
            align-items: center;
            border-radius: 27px;
            padding: rem(20);
            height: fit-content;
            background-color: rgba(0, 49, 102, 0.06);
            gap: rem(20);
            text-wrap: nowrap;

            h1 {
                font-size: rem(15);
                font-weight: 600;
                color: rgba(0, 49, 102, 0.62);
            }

            p {
                font-size: rem(11);
                font-weight: 500;
                opacity: .4;
            }
        }

        &__body {
            margin: 0 rem(30) rem(20) rem(30);
            width: 100%;
            position: relative;
            padding: rem(35);
            background: #FFFFFF;
            border-radius: rem(20);
        }

        &__top {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: rem(20);
            margin-top: rem(20);

        }
    }

    &-create{
        position: relative;
        padding: rem(10);
        padding-bottom: rem(30);

        &__header {
            width: 100%;
            display: flex;
            align-items: center;
            gap: rem(30);
            border-bottom: 1px solid rgba(0, 49, 102, 0.075);
            padding: rem(20) rem(30);
        }

        .breadcrumbs {
            padding: rem(20) rem(30);
        }

        &__body {
            width: 100%;
            padding: rem(35);
            background: #FFFFFF;
            border-radius: rem(20);
        }

        &__image {
            width: 100%;
            display: flex;
            justify-content: center;

            .form-file {
                height: 100%;
                max-width: rem(800);
                min-height: rem(287);
            }
        }

        &__select {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__left {
                width: 100%;
                .react-select-prefix__dropdown-indicator {
                    display: none;
                }

                .react-select-prefix__menu-list {
                    width: 100%;
                }

                .react-select-prefix__value-container {
                    padding: 0;
                }
            }

            &__right {
                opacity: .5;
            }
        }

        .toggle__text {
            font-weight: 700;
        }

        &__button {
            border: none;
            color: rgb(255, 255, 255);
            background: rgb(0, 122, 255);
            width: 100%;
            border-radius: 8px;
            line-height: 60px;
            padding: 0 16px;
            box-sizing: border-box;
            font-weight: bold;
            transition: all 0.2s ease-in-out;
            font-size: 16px;
            --space-between-buttons: 10px;
        }

        &__saveButton {
            color: rgb(255, 255, 255);
            background: rgb(0, 122, 255);
        }

        &__cancelButton {
            color: rgb(0, 122, 255);
            background: #FFFFFF;
            margin-top: rem(20);
        }
    }
}