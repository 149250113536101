.birthdays {
    display: flex;
    flex-direction: column;
    gap: rem(30);
    padding: rem(10) rem(60);

    @media (max-width: 1020px) {
       padding: rem(83) rem(15) rem(50) rem(15);
        gap: rem(20);
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(5, minmax(rem(245), 1fr));
        grid-auto-rows: 1fr;
        grid-gap: rem(20);

        @media (max-width: 1440px) {
            grid-template-columns: repeat(4, minmax(rem(210), 1fr));
        }

        @media (max-width: 1020px) {
            grid-template-columns: repeat(3, minmax(rem(180), 1fr));
        }

        @media (max-width: 760px) {
            grid-template-columns: repeat(2, minmax(rem(167), 1fr));
            grid-gap: rem(10);
        }

        @media (max-width: 370px) {
            grid-template-columns: repeat(1, minmax(rem(167), 1fr));
            grid-gap: rem(10);
        }
    }

    &-calendar {
        display: flex;
        align-items: center;

        @media (max-width: 1020px) {
            justify-content: center;
        }

        @media (max-width: 570px) {
            justify-content: space-between;
        }

        &__block {
            cursor: pointer;
            font-size: rem(16);
            font-weight: 500;
            line-height: 24px;
            text-align: right;
            padding: rem(6) rem(12);
        }

        &__block.active {
            color: #fff;
            background: #00A7B5;
            border-radius: rem(100);
        }
    }

    &-block {
        display: flex;
        flex-direction: column;
        gap: rem(20);
        background: #fff;
        padding: rem(20);
        border-radius: rem(16);

        &__image {
            width: rem(60);
            height: rem(60);
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: rem(6);
        }

        &__title {
            width: 70%;
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(20);

            @media (max-width: 1020px) {
                width: 100%;
                font-size: rem(14);
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &__text, &__position {
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(18);
        }

        &__button {
            height: rem(50);
            text-align: center;
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(26);
            border: none;
            border-radius: rem(8);
            background: #00A7B51A;
            color: #00A7B5;
        }
    }
}