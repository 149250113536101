.container-vsk {
	margin-bottom: 10px;
	color: $blueColor;
	font-size: 15px;
	font-weight: 500;

	h2 {
		margin-bottom: 10px;
		font-size: 24px;
		font-weight: 700;
		overflow: hidden;
		overflow-wrap: anywhere;
		text-overflow: ellipsis;
	}

	h3 {
		font-size: 20px;
		font-weight: 700;
		line-height: 28px;
	}


	h4 {
		font-size: 18px;
		font-weight: 600;
	}


	* {
		word-break: break-word;
	}

	// [breakpoints.up(1750)]{
	//     display: grid;
	//     gridTemplateColumns: 378px 1fr 378px;
	//     gridGap: 30;
	// };
	// [breakpoints.down(1280)]{
	//     // zoom: 0.75;
	// };
	// [breakpoints.down(sm)]{
	//     zoom: 1;
	//     & h2{
	//         margin-bottom: 15px;
	//         font-size: 19px;
	//     };
	//     & h3{
	//         font-size: 15px;
	//         line-height: 20px;
	//     };
	// };
}

.block-vsk {
	position: relative;
	display: block;
	height: fit-content;
	padding: 20px;
	background: $white;
	border-radius: 10px;

	// [breakpoints.up(1750)] {
	//     height: auto;
	// };

	// [breakpoints.down(sm)] {
	//     padding: 0;
	//     background: none;
	// };
}

.mobile-block-vsk {
	// [breakpoints.down(sm)] {
	//     padding: 0;
	// };
}

.flexSpace {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.flexRow {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}

.separator {
	height: 1px;
	width: 100%;
	margin: 10px 0;
	background-color: blueColor(14);
}

.fullWidth {
	width: calc(100% + 40px);
	margin: 10px -20px;
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 8px 26px;
	margin-top: 20px;
	font-weight: 700;
	line-height: 26px;
	color: #000000;
	background-color: #007AFF0d;
	cursor: pointer;
	border-radius: 10px;

	svg {
		min-width: 24px;
		min-height: 24px;
		color: #007BFF;
	}

	// [breakpoints.down(sm)] {
	//     padding: 12px 16px;
	//     line-height: 1;
	//     font-size: 14px;
	//     margin: 0;
	// };
}

.bigButton {
	width: 100%;
	padding: 15px 0;
}

.grayButton {
	color: $blueColor;
	background-color: blueColor(14);
}

.linkStyle {
	text-align: center;
	color: lightBlue;
	cursor: pointer;
	user-select: none;
}

.showAllLink {
	max-width: fit-content;
	margin: 0 auto;
}

.avatar {
	width: 56px;
	min-width: 56px;
	height: 56px;
	margin-right: 16px;
	border-radius: 50%;
	object-fit: cover;
}

.circle {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.blueBg {
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	background-color: lightBlue(16);
}

.dateBlock {
	display: inline-block;
	padding: 4px 10px;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	border-radius: 100px;
	background-color: lightBlue(10);
}

.counter {
	position: absolute;
	right: -2px;
	width: 18px;
	height: 18px;
	background-color: #FA1919;
	font-size: 12px;
	font-weight: 600;
	color: $white;
}

.modal-list {
	display: flex;
	flex-flow: row wrap;
	min-height: 200px;
	max-height: calc(100vh - 300px);
	margin: 0 -30px 0 0;
	overflow-y: auto;
	overflow-x: hidden;

	& > div {
		margin: 0 30px 0 0;
	}
}

.disabled-button {
	opacity: 0.5;
	cursor: not-allowed;
}

.blur-bg-vsk {
	user-select: none;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: blueColor(50);
		border-radius: 10px;
		backdrop-filter: blur(5px);
		// filter: blur(10px);
		z-index: 1;
	}

	&:after {
		content: "появится в ближайшее время";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		max-width: 304px;
		transform: translate(-50%, -50%);
		text-align: center;
		z-index: 2;
		font-size: 24px;
		font-weight: 700;
		color: $white;
		text-transform: uppercase;
	}
}

.blur-access-vsk {
	user-select: none;
	cursor: pointer;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: blueColor(50);
		border-radius: 10px;
		backdrop-filter: blur(5px);
		z-index: 1;
	}

	&:after {
		content: "доступ запрещен";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		max-width: 304px;
		transform: translate(-50%, -50%);
		text-align: center;
		z-index: 2;
		font-size: 24px;
		font-weight: 700;
		color: $white;
		text-transform: uppercase;
	}
}

.toggleContainer {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	cursor: pointer;
	font-size: 14px;

	// [breakpoints.down(sm)] {
	//     margin-bottom: 30px;
	//     font-weight: 500;
	// }
}

.toggleButton {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	min-width: 20px;
	height: 20px;
	margin-right: 10px;
	border: blueColor(16);
	border-radius: 4px;

	&:after {
		content: "";
		position: absolute;
		height: 16px;
		width: 16px;
		background-color: lightBlue;
		border-radius: 4px;
		opacity: 0;
		transition: 0.2s;
	}

	// [breakpoints.down(sm)] {
	//     width: 30px;
	//     min-width: 30px;
	//     height: 30px;

	//     &:after {
	//         height: 20px;
	//         width: 20px;
	//     };
	// };
}

.activeToggle {
	&:after {
		opacity: 1;
	}
}

.radioToggle {
	border-radius: 50%;

	&:after {
		border-radius: 50%;
	}
}

.settings-vsk {
	// [breakpoints.down(sm)] {
	//     & #main-page-settings {
	//         opacity: 1;
	//     };
	// };
	&:hover #main-page-settings {
		opacity: 1;
	}
}

.annotation {
	margin-bottom: 5px;
	font-size: 12px;
	line-height: 20px;
	font-weight: 500;
	opacity: 0.5;
}

.hiddenBg {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: blueColor(50);
		border-radius: 10px;
		backdrop-filter: blur(15px);
		z-index: 1;
	}
}

.hashtagsBlock {
	.MuiInputBase-formControl .MuiAutocomplete-input {
		width: 100%;
	}
}
