.sidebar {
    &__links {
        &__modal {
            &__container {
                height: 100%
            }

            &__button {
                width: rem(235);
                height: rem(61);
                margin-bottom: rem(18);
                font-weight: 600;
            }

            &-items{
                overflow: auto;
                height: calc(100% - 100px);
                padding: 0 10px 0 0;
                //height: calc(100vh - 360px);
            }

            &__blue-circle{
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(32);
                min-width: rem(20);
                height: rem(32);
                margin-right: 0;
                border-radius: 50%;
                background-color: #1a59fb;
                cursor: pointer;
            }
        }

        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__separator {
            width: 100%;
            height: rem(1);
            margin: rem(16) 0;
            background-color: rgba(0, 0, 255, 0.14);
        }

        &__input {
            width: 100%;
            border: 0;
            outline: 0;
            background-color: transparent;
            font-size: rem(15);
            font-weight: 700;
            color: blue;
            margin-bottom: 0;
            letter-spacing: rem(0.2);

            &::placeholder{
                color: blue;
                opacity: 0.62;
            }
        }

        &__controls {
            display: flex;
            align-items: center;
        }

        &__line {
            cursor: grab;

            &-block{
                width: rem(16);
                height: rem(3);
                background-color: rgba(0, 0, 255, 0.3);
                margin-bottom: rem(4);
            }
        }

        &__shared {
            max-width: rem(58);
            min-width: rem(58);
            height: rem(58);
            margin-right: rem(18);
            border-radius: 50%;
            cursor: pointer;
            object-fit: cover;
        }

        &__placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(90, 90, 236, 0.1);
            color: #7b7be5;
            font-size: rem(20);
            font-weight: 700;
        }
    }
}

@media screen and (min-width: 760px) {
    .sidebar {
        &__links {
            &__modal {
                &__blue-circle {
                    width: rem(30);
                    height: rem(30);
                    min-width: rem(30);
                }
            }
        }
    }
}