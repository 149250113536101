.select {
    svg {
        color: var(--lightBlue);
    }

    &:hover {
        border-bottom-color: var(--lightBlue);
    }
}

.close {
    color: var(--lightBlue);
}

.item {
    &:hover {
        background-color: var(--lightBlue)0d;
    }
}