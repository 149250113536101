.news {
    position: relative;

    &-search {
        position: relative;
        width: rem(315);
        border-radius: rem(100);
        background: #fff;
        z-index: 999;

        & .field {
            border: none !important;
            outline: none;
        }

        & .form-control {
            background: transparent ;
            outline: none !important;
            border: 3px solid #fff;

            &:focus {
                outline: none !important;
                border: 3px solid #fff;
            }
            & input {
                border: none;
                outline: none;
            }
        }
    }

    &-slider {
        position: relative;
        &__leftIcon {
            position: absolute;
            height: 100%;
            z-index: 9;
            background: linear-gradient(270deg, rgba(247, 247, 247, 0) 0%, #F7F7F7 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(200);

            & svg {
                transform: rotate(180deg);
                cursor: pointer;
            }
        }

        &__rightIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(200);
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9;
            transform: rotate(180deg);
            background: linear-gradient(270deg, rgba(247, 247, 247, 0) 0%, #F7F7F7 100%);

            & svg {
                cursor: pointer;
                transform: rotate(180deg);
            }
        }

        &__content {
            position: relative;
            width: 100%;
            padding: 1.875rem 0;
            border-top: 1px solid rgba(255, 255, 255, .1);
            cursor: pointer;

            &__desc {
                width: calc(100% - 2.5rem);
                display: flex;
                flex-direction: column;
                gap: rem(10);
                position: absolute;
                bottom: rem(20);
                left: rem(20);

                &__title {
                    color: #fff;
                    font-size: rem(20);
                    font-weight: 600;
                    line-height: 24px;
                    max-width: rem(488);
                }

                &__category {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    border-radius: rem(100);
                    color: #fff;
                    background: #FFFFFF1A;
                    padding: rem(4) rem(12);
                    font-size: rem(10);
                    font-weight: 600;
                    line-height: 18px;
                }

                &__bottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                &__dateBox {
                    color: #fff;
                    display: flex;
                    align-items: center;
                    gap: rem(6);

                    & div {
                        font-size: rem(12);
                        font-weight: 500;
                        line-height: 20px;
                    }
                }

                &__stats {
                    display: flex;
                    align-items: center;
                    gap: rem(10);

                    &__block {
                        display: flex;
                        align-items: center;
                        gap: rem(6);

                        & h1 {
                            color: #fff;
                            font-size: rem(12);
                            font-weight: 600;
                            line-height: 20px;
                        }
                    }
                }
            }

            &__linear {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
                border-radius: rem(16);
            }

            .swiper {
                width: 100%;
                height: 100%;

                &-wrapper {
                    display: flex;
                    align-items: center;
                    gap: rem(20);
                }
            }

            .swiper-slide {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(746);
                height: rem(324);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border-radius: rem(16);

                &-active {
                    height: rem(360);
                }

                img {
                    width: rem(746);
                    border-radius: 1rem;
                    height: rem(324);
                    transition: .3s all ease;
                }
            }
        }
    }

    &-content {
        padding: rem(0) rem(60) rem(30) rem(60);

        &__header {
            width: rem(315);

            & .workspace-select {
                width: 100%;
            }
        }


        &__items {
            margin-top: rem(30);
            display: grid;
            grid-template-columns: repeat(4, minmax(rem(315), 1fr));
            grid-auto-rows: 1fr;
            grid-gap: 1.25rem;
        }
    }

    &-card {
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
        border-radius: 1rem;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        transition: 0.2s all ease;

        @media (max-width: 1020px) {
            //flex-direction: row;
            //justify-content: center;
            //align-items: center;
            //height: fit-content;
            //gap: 0.625rem;
            //padding: 0.313rem 0.938rem 0.313rem 0.313rem;

        }

        &:hover {
            box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.1);

            @media (max-width: 1020px) {
                box-shadow: unset;
            }
        }

        img {
            border-top-right-radius: rem(16);
            border-top-left-radius: rem(16);
            width: 100%;
            object-fit: cover;
            max-height: rem(172);

            //@media (max-width: 1020px) {
            //    width: 7.5rem;
            //    height: 7.5rem;
            //    border-radius: 0.75rem;
            //}
        }

        .item__status-block {
            bottom: 0.625rem !important;
            right: 0.625rem !important;
        }

        &__infoBox {
            position: relative;
            padding: 1.25rem;
            color: #003166;
            font-size: 1.125rem;
            line-height: 1.625rem;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            gap: rem(10);
            height: 100%;

            &__top {
                display: flex;
                gap: rem(20);
                justify-content: space-between;
            }

            //@media (max-width: 1020px) {
            //    padding: 0.875rem 0;
            //    gap: 0;
            //}

            h5 {
                font-size: 1rem;
                font-weight: 600;
                line-height: rem(24);
                text-align: left;
                min-height: 3.25rem;
                margin-bottom: auto;
                color: #000;

                @media (max-width: 1020px) {
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                }
            }
        }

        &__catTitleStyle {
            max-width: fit-content;
            padding: 0.25rem 1rem;
            font-size: rem(10);
            font-weight: 600;
            line-height: rem(18);
            text-align: left;

            color: #000;
            background: #00A7B51A;
            border-radius: 6.25rem;

            @media (max-width: 1020px) {
                padding: 0.25rem 0.75rem;
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
        }

        &__placeholder {
            height: calc(100% - 9.5rem);
            width: 100%;
            background-color: $blueColor + '15'; // Assuming blueColor is defined as a SCSS variable
            border-radius: 1.25rem 1.25rem 0 0;
        }

        &__dateBox {
            display: flex;
            text-wrap: nowrap;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1.25rem;
            color: #003166;
            padding-top: 0.25rem;
            opacity: 0.5;
            width: 100%;

            svg {
                width: 1.25rem;
                height: 1.25rem;
                opacity: 0.3;

                @media (max-width: 1020px) {
                    width: 1rem;
                    height: 1rem;
                }
            }

            @media (max-width: 1020px) {
                font-size: 0.625rem;
                justify-content: center;
            }
        }

        &__likeDateBox {
            display: flex;
            text-wrap: nowrap;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1.25rem;
            color: #003166;
            padding-top: 0.25rem;
            width: 100%;

            svg {
                width: 1.25rem;
                height: 1.25rem;

                @media (max-width: 1020px) {
                    width: 1rem;
                    height: 1rem;
                }
            }

            @media (max-width: 1020px) {
                font-size: 0.625rem;
                justify-content: center;
            }
        }

        &__eyeBlock {
            display: flex;
            align-items: center;
            margin-left: auto;
        }

        &__likeContent {
            svg {
                width: 1.25rem;
                height: 1.25rem;

                @media (max-width: 1020px) {
                    width: 1rem;
                    height: 1rem;
                }
            }
        }

        &__cardBackground {
            min-height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }

        &__content {
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: space-between;
        }

        &__stats {
            display: flex;
            align-items: center;
            gap: 1rem;

            &__block {
                display: flex;
                align-items: center;
                gap: rem(6);

                & h1 {
                    font-size: rem(12);
                    font-weight: 600;
                    line-height: rem(20);
                }

            }
        }

        &__statsLeft {
            display: flex;
            align-items: center;
            gap: 1.563rem;
        }

        &__imageBox {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.5rem;
            width: 2.5rem;
            color: blue;
            font-size: 0.875rem;
            font-weight: 600;
        }

        &__title {
            max-width: 100%;
            text-align: center;
            font-size: 0.875rem;
            line-height: 1.375rem;
            font-weight: 600;
        }
    }


    &-show {
        &-content {
            position: relative;
            display: flex;
            justify-content: space-between;
            gap: rem(30);
            padding: rem(30) rem(60);

            &__left {
                width: 100%;
            }
            &__right {
                display: flex;
                flex-direction: column;
                gap: rem(20);
                width: rem(315);
            }
        }

        &-info {
            position: relative;
            border-radius: rem(16);
            background: #fff;
            &__image {
                border-radius: rem(16);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: rem(512);
            }

            &__content {
                padding: rem(45) rem(30) rem(30) rem(30);
            }

            &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__practice {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #00A7B5;
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: 22px;
                    color: #fff;
                    padding: rem(4) rem(12);
                    border-radius: rem(100);
                }

                &__types {
                    display: flex;
                    align-items: center;
                    gap: rem(8);

                    &__item {
                        padding: rem(4) rem(12);
                        background: #00A7B50D;
                        font-size: rem(14);
                        font-weight: 600;
                        line-height: 22px;
                        color: #00A7B5;
                        border-radius: rem(100);
                    }
                }
            }

            &__editor {
                display: flex;
                flex-direction: column;
                gap: rem(30);
                margin-top: rem(20);

                & h5 {
                    font-size: rem(16);
                    font-weight: 500;
                    line-height: 28px;
                }

                & p {
                    font-size: rem(16);
                    font-weight: 400;
                    line-height: 28px;
                }
            }

            &__date {
                display: flex;
                align-items: center;
                gap: rem(10);
                margin-top: rem(30);
                opacity: .5;

                & p {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 22px;
                }

                & svg {
                    width: rem(20);
                }
            }

            &__bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: rem(30);
            }

            &__buttons {
                display: flex;
                align-items: center;
                gap: rem(20);

                & button {
                    display: flex;
                    align-items: center;
                    gap: rem(15);
                    padding: rem(13) rem(30);
                    border-radius: rem(8);
                    outline: none;

                    & p {
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: 24px;
                    }
                }

                &__likeButton {
                    background: #00A7B5;
                    color: #fff;
                    border: none;
                }

                &__shareButton {
                    background: #fff;
                    color: #00A7B5;
                    border: 2px solid #0000001A
                }
            }

            &__stats {
                display: flex;
                align-items: center;
                gap: rem(20);

                &__block {
                    display: flex;
                    align-items: center;
                    gap: rem(8);
                    opacity: .5;

                    & p {
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: 22px;
                    }
                }
            }
        }
    }

    &-messenger {
        width: 100%;
        background: #fff;
        padding: rem(30);
        border-radius: rem(16);
        margin-top: rem(20);

        &__top {
            display: flex;
            align-items: center;
            gap: rem(30);

            &__avatar {
                width: rem(50);
                height: rem(50);
                border-radius: 50%;
            }

            &__textarea {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: rem(30);
                position: relative;
                width: 100%;
                background: #00000008;
                padding: rem(13) rem(20);
                border-radius: rem(8);
                
                &__buttons {
                    display: flex;
                    align-items: center;
                    gap: rem(20);

                    &__enterButton {
                        color: #00A7B5;
                        outline: none;
                        background: none;
                        border: none;
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: 24px;
                        cursor: pointer;
                    }

                    & svg {
                        cursor: pointer;
                    }
                }
            }

            &__input {
                width: 100%;
                outline: none;
                border: none;
                background: transparent;

                &::placeholder {
                    font-size: rem(16);
                    font-weight: 400;
                    line-height: 24px;
                    opacity: .5;
                    color: #000;
                }
            }
        }
    }


    &-chat {
        display: flex;
        flex-direction: column;
        gap: rem(30);
        margin-top: rem(30);

        &__block {
            display: flex;
            flex-direction: column;
            gap: rem(15);
        }

        &__info {
            display: flex;
            align-items: center;
            gap: rem(15);

            &__avatar {
                min-width: rem(50);
                min-height: rem(50);
                width: rem(50);
                height: rem(50);
                border-radius: 50%;
            }

            &__name {
                font-size: rem(14);
                font-weight: 600;
                line-height: 22px;
            }

            &__date {
                font-size: rem(12);
                font-weight: 500;
                line-height: 20px;
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            gap: rem(20);

            &__answerButton {
                font-size: rem(16);
                font-weight: 600;
                line-height: 24px;
                cursor: pointer;
                color: #00A7B5;
            }

            &__likeButton {
                display: flex;
                align-items: center;
                gap: rem(8);

                & svg {
                    opacity: .3;
                    cursor: pointer;
                }

                & p {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 22px;
                }
            }
        }

        &-child {
            &__block {
                display: flex;
                gap: rem(15);
                margin-top: rem(15);
            }

            &__right {
                display: flex;
                flex-direction: column;
                gap: rem(15);
            }

            &__likeButton {
                display: flex;
                align-items: center;
                gap: rem(8);

                & svg {
                    cursor: pointer;
                    & path {
                        fill: #00A7B5;
                        stroke-width: 2;
                    }
                }
            }

            &__attachment {
                & img {
                    max-width: rem(250);
                    object-fit: cover;
                    border-radius: rem(16);
                    cursor: pointer;
                    margin-bottom: rem(15);
                }
            }

            &__leftRow {
                width: rem(20);
                height: rem(20);
                margin-top: rem(5);
                position: relative;
                opacity: .3;

                &:after {
                    width: rem(20);
                    height: rem(1);
                    bottom: 0;
                    background: #000;
                    position: absolute;
                    content: '';
                    opacity: .5;
                }

                &:before {
                    top: 0;
                    left: 0;
                    width: rem(1);
                    height: rem(20);
                    background: #000;
                    position: absolute;
                    content: '';
                    opacity: .5;
                }
            }
        }
    }
}