.calendar {
    &__wrapper {
        width: 100%;
        border: rem(1) solid rgba(0, 0, 0, 0.1);
        border-radius: rem(8);

        @media (max-width: 768px) {
            border: none;
        }

        .fc {
            @media (max-width: 768px) {
                height: rem(450);
            }

            .fc-weekend {
                .fc-daygrid-day-frame {
                    background-color: rgba(0, 0, 0, 0.05);
                }

                .fc-daygrid-day-number {
                    color: rgba(0, 0, 0, 0.3);
                }

                .fc-col-header-cell-cushion {
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            .fc-holiday {
                .fc-daygrid-day-frame {
                    background-color: ($lightBlue) 0d;
                }

                .fc-daygrid-day-number {
                    color: $lightBlue;
                }
            }

            .fc-weekend .fc-holiday {
                .fc-daygrid-day-frame {
                    background-color: ($lightBlue) 0d;
                }

                .fc-daygrid-day-number {
                    color: ($lightBlue) 4d;
                }
            }

            .fc-col-header-cell-cushion {
                font-size: 10px;
                font-weight: 600;
                line-height: 18px;
                text-transform: capitalize;
                padding: 11px;
            }

            .fc-daygrid-day .fc-day-today {
                background-color: transparent;

                .fc-daygrid-day-frame {
                    background-color: ($lightBlue) 1a;
                }
            }

            .fc-daygrid-day-frame {
                background-color: rgba(0, 0, 0, 0.02);
                border-radius: 8px;
                transition: background-color 0.2s linear;
                margin: 4px;

                .fc-highlight {
                    border-radius: 8px;
                }
            }

            .fc-daygrid-day-number {
                font-size: 10px;
                font-weight: 600;
                line-height: 18px;
                text-transform: capitalize;
                padding: 4px 8px;
            }

            .fc-daygrid-day-bottom {
                font-size: 12px;
                font-weight: 600;
                margin: 0 6px;

                .fc-daygrid-more-link {
                    width: 100%;
                    line-height: 20px;
                    transition: background-color 0.2s linear;
                    padding: 1px 8px;
                }
            }

            .fc-more-popover {
                max-height: 300px;
                overflow-y: auto;
                overflow-x: hidden;
            }

            .fc-popover {
                display: flex;
                flex-direction: column;
                gap: 4px;
                border: none;
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                padding: 8px;
                z-index: 1000;

                .fc-popover-header {
                    background-color: transparent;
                    padding: 0;
                }

                .fc-popover-title {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 22px;
                    margin: 0;
                }

                .fc-popover-close {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    font-size: 18px;
                    opacity: 1;
                    transition: color 0.2s linear;

                    &:hover {
                        color: lightBlue;
                    }
                }

                .fc-popover-body {
                    padding: 0;
                    margin: 0 -6px;
                }
            }
        }

        .fc-theme-standard {
            .fc-scrollgrid, th, td {
                border: none;
            }

            tr {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }

        .fc-event {
            cursor: pointer;
            background-color: transparent;
            border: none;
            border-radius: 4px;
            box-shadow: none;
            outline: none;
            margin: 1px 6px !important;

            &:before, &:after {
                display: none;
            }
        }

        .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-end) .fc-event-main {
            div {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .fc-direction-ltr .fc-daygrid-block-event:not(.fc-event-start) .fc-event-main {
            div {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &__item {
        &__wrapper {
            display: flex;
            border-radius: rem(4);
            opacity: 0;
            transition: background-color 0.2s linear, opacity 0.2s linear;
            padding: rem(5) rem(10);

            @media (max-width: 768px) {
                padding: 0 rem(8);
            }
        }

        &__name {
            font-family: $montserrat;
            font-size: rem(12);
            font-weight: 500;
            line-height: 22px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &__popup {
        &__head {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: rem(10);
        }

        &__title {
            font-size: rem(20);
            font-weight: 600;
            line-height: 30px;
        }

        &__close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: rem(13);
            right: rem(16);
            width: rem(24);
            height: rem(24);
            cursor: pointer;
            transition: color 0.2s linear;

            svg {
                width: 100%;
                height: 100%;
            }

            &:hover {
                color: $lightBlue;
            }
        }

        &__body {
            height: 100%;
            background-color: #F7F7F7;
            padding: rem(15);

            .fc {
                text-align: center;

                .fc-holiday {
                    .fc-daygrid-day-frame {
                        background-color: rgba(0, 0, 0, 0.05);
                    }

                    .fc-daygrid-day-number {
                        color: rgba(0, 0, 0, 0.3);
                    }

                    .fc-col-header-cell-cushion {
                        color: rgba(0, 0, 0, 0.3);
                    }
                }

                .fc-multimonth {
                    border: none;
                }

                .fc-multimonth-header {
                    background-color: #F7F7F7;
                }

                .fc-multimonth-title {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 28px;
                    text-transform: capitalize;
                    text-align: left;
                    padding: 7px 0;
                }

                .fc-multimonth-daygrid {
                    background-color: transparent;
                }

                .fc-col-header-cell-cushion {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    text-transform: capitalize;
                    padding: 5px 0;
                }

                .fc-daygrid-day {
                    padding: 2px;

                    .fc-day-today {
                        background-color: transparent;
                    }
                }

                .fc-day-disabled {
                    background-color: transparent;

                    .fc-daygrid-day-frame {
                        background-color: transparent;
                    }
                }

                .fc-daygrid-day-frame {
                    background-color: $white;
                    border-radius: 4px;
                    transition: background-color 0.2s linear;

                    .fc-highlight {
                        border-radius: 4px;
                    }
                }

                .fc-daygrid-day-top {
                    position: absolute;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }

                .fc-daygrid-day-number {
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 18px;
                    padding: 10px;
                }
            }

            .fc-theme-standard {
                .fc-scrollgrid, & th, & td {
                    border: none;
                    vertical-align: middle;
                }
            }

            .fc-event {
                .fc-bg-event {
                    opacity: 0.5;
                }

                .fc-event-start {
                    border-top-left-radius: rem(4);
                    border-bottom-left-radius: rem(4);
                }

                .fc-event-end {
                    border-top-right-radius: rem(4);
                    border-bottom-right-radius: rem(4);
                }
            }
        }
    }

    &__booking-popup {
        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: rem(30);

            @media (max-width: 768px) {
                gap: rem(20);
                width: 100%;
            }
        }

        &__indicator {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: rem(10);
            border-radius: rem(6) rem(6) 0 0;
            transition: background-color 0.2s linear;

            @media (max-width: 768px) {
                height: rem(5);
                border-radius: 0;
            }
        }

        &__head {
            display: flex;
            flex-direction: column;

            @media (max-width: 768px) {
                gap: rem(20);

                span {
                    font-size: rem(20);
                    font-weight: 700;
                    line-height: rem(28);
                    margin-bottom: rem(-15);
                }
            }
        }

        &__title {
            display: flex;
            flex-direction: column;
            font-size: rem(18);
            font-weight: 700;
            line-height: rem(28);
        }

        &__address {
            font-size: rem(12);
            font-weight: 500;
            line-height: rem(20);
            color: rgba(0, 0, 0, 0.5);
        }

        &__close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: rem(30);
            right: rem(30);
            width: rem(24);
            height: rem(24);
            cursor: pointer;
            transition: color 0.2s linear;
            margin-left: auto;

            &:hover {
                color: $lightBlue;
            }

            svg {
                width: 100%;
                height: 100%;
            }

            @media (max-width: 768px) {
                top: rem(20);
                right: rem(20);
            }
        }

        &__form {
            display: flex;
            flex-direction: column;
            gap: rem(20);
        }

        &__block {
            display: flex;
            flex-direction: column;
            gap: rem(10);
        }

        &__label {
            display: flex;
            flex-direction: column;
            gap: rem(4);
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(24);

            span {
                font-size: rem(12);
                font-weight: 500;
                line-height: rem(18);
                color: rgba(0, 0, 0, 0.5);
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            gap: rem(16) rem(14);

            @media (max-width: 768px) {
                flex-direction: column;
            }
        }

        &__status {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: rem(8);
            width: 100%;
            height: rem(50);
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(24);
            padding: rem(6) rem(8);
        }

        &__stop {
            color: rgba(0, 0, 0, 0.5);
        }

        &__refuse {
            svg {
                color: #FF0000;
            }
        }

        &__button {
            width: 100%;
            height: rem(50);
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(24);
            text-transform: none;
            border-radius: rem(4);

            @media (max-width: 768px) {
                border-radius: rem(8);
            }
        }

        &__remove {
            color: #FF0000;
            background-color: #FF00000d;

            &:hover {
                background-color: #FF00001a;
            }
        }

        &__cancel {
            background-color: rgba(0, 0, 0, 0.03);

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        &__disabled {
            cursor: default;
            pointer-events: none;
        }

        &__process {
            color: $lightBlue;
        }

        &__accept {
            svg {
                color: #5DD334;
            }

            @media (max-width: 768px) {
                order: -1;
            }
        }

        &__action {
            color: $white;
            background-color: $lightBlue;
            transition: filter 0.2s linear;

            &:hover {
                background-color: $lightBlue;
                filter: brightness(0.9);
            }

            @media (max-width: 768px) {
                order: -1;
            }
        }

        &__conciliator__wrapper {
            display: flex;
            flex-direction: column;
        }
    }

    &__date-range__block {
        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: rem(15);
        }

        &__block {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: rem(4) rem(14);

            @media (max-width: 768px) {
                gap: rem(4) rem(15);
            }
        }

        &__field {
            display: flex;
            flex-direction: column;
            width: calc(50% - 7px);
            gap: rem(4);

            @media (max-width: 768px) {
                width: calc(50% - 7.5px);
            }
        }

        &__label {
            font-size: rem(12);
            font-weight: 500;
            line-height: rem(20);
            color: rgba(0, 0, 0, 0.5);
        }

        &__error {
            font-size: rem(12);
            font-weight: 500;
            line-height: rem(20);
            color: #FF0000;
        }

        &__note {
            display: flex;
            flex-direction: column;
            gap: rem(2);
            width: calc(50% - 7px);

            @media (max-width: 768px) {
                width: calc(50% - 7.5px);
            }
        }

        &__noteFull {
            width: 100%;
        }

        &__noteDay {
            font-size: rem(20);
            font-weight: 600;
            line-height: rem(26);
        }

        &__noteText {
            font-size: rem(12);
            font-weight: 500;
            line-height: rem(18);
            color: rgba(0, 0, 0, 0.5);
        }

        &__holiday {
            font-size: rem(12);
            font-weight: 600;
            line-height: rem(18);
        }
    }

    &__member {
        &__wrapper {
            display: flex;
            align-items: center;
            gap: rem(15);
            width: 100%;
            cursor: pointer;
            user-select: none;
            transition: background-color 0.2s linear, padding 0.2s linear;
            padding: rem(10);

            &:hover {
                padding: rem(10);

            }

            @media (max-width: 768px) {
                background-color: transparent;
            }
        }

        &__avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            height: rem(50);
            width: rem(50);
            font-size: rem(16);
            font-weight: 600;
            line-height: normal;
            background-color: $lightBlue;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            gap: rem(2);
        }

        &__name {
            font-size: rem(14);
            font-weight: 600;
            line-height: rem(22);
        }

        &__position {
            font-size: rem(12);
            font-weight: 400;
            line-height: rem(20);
            text-transform: capitalize;
        }

        &__icon {
            width: rem(32);
            height: rem(32);
            border-radius: 50%;
            transition: background-color 0.2s linear;
            padding: 0;
            margin-left: auto;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &s {
            &__block {
                &__wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: rem(15);
                }

                &__head {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                }

                &__title {
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: rem(24);
                }

                &__body {
                    display: flex;
                    flex-wrap: wrap;
                    gap: rem(15) rem(20);
                }
            }

            &__add {
                &__wrapper {
                    display: flex;
                    width: 100%;
                }

                &__add {
                    justify-content: flex-start;
                    width: 100%;
                    height: rem(70);
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: rem(22);
                    text-transform: none;
                    background-color: $lightBlue;
                    border-radius: 8px;
                    transition: all 0.2s linear;
                    padding: rem(20);

                    svg {
                        width: rem(32);
                        height: rem(32);
                        color: $lightBlue;
                        margin: rem(-8);
                    }

                    span {
                        gap: rem(15);
                    }

                    &:hover {
                        background-color: #000fff1a;
                    }
                }

                &__icon {
                    width: rem(50);
                    height: rem(50);
                    color: $lightBlue;
                    background-color: #000fff0d;
                    border-radius: 50%;
                    padding: rem(13);

                    svg {
                        width: rem(24);
                        height: rem(24);
                    }

                    &:hover {
                        background-color: #000fff0d;
                    }
                }

                &__root {
                    @media (max-width: 768px) {
                        background-color: rgba(0, 0, 0, 0.3);
                    }
                }

                &__paper {
                    width: 100%;
                    max-width: rem(360);
                    border-radius: rem(8);
                    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);

                    @media (max-width: 768px) {
                        bottom: 0;
                        max-width: 100%;
                        height: rem(504);
                        border-radius: rem(16) rem(16) 0 0;
                    }
                }

                &__head {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: rem(24);
                    padding: rem(18);
                }

                &__close {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: rem(18);
                    right: rem(20);
                    width: rem(24);
                    height: rem(24);
                    color: $lightBlue;
                    cursor: pointer;
                    transition: all 0.2s linear;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                &__body {
                    display: flex;
                    flex-direction: column;
                    min-height: rem(120);
                    max-height: rem(281);
                    overflow: auto;
                    border-top: rem(1) solid rgba(0, 0, 0, 0.1);

                    @media (max-width: 768px) {
                        min-height: rem(281);
                        padding: 0 rem(20);
                    }
                }

                &__empty {
                    font-size: rem(16);
                    font-weight: 500;
                    margin: auto;
                }
            }

            &__search {
                &__wrapper {
                    padding: rem(9);

                    @media (max-width: 768px) {
                        padding: rem(15) rem(20);
                    }
                }

                &__field {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: rem(15);
                }

                &__icon {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: rem(9);
                    left: rem(12);
                    width: rem(24);
                    height: rem(24);
                    color: $lightBlue;

                    svg {
                        width: rem(20);
                        height: rem(20);
                    }
                }

                &__input {
                    width: 100%;
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: rem(22);
                    height: rem(22);
                    background-color: rgba(0, 0, 0, 0.05);
                    border-radius: 100px;
                    padding: rem(10) rem(15);
                    padding-left: rem(51);

                    &::placeholder {
                        opacity: 1;
                        color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }

        &__user {
            &__wrapper {
                display: flex;
                gap: rem(14);
                transition: background-color 0.2s linear;
                padding: rem(10) rem(15);

                &.short {
                    align-items: center;
                }

                &.search {
                    cursor: pointer;
                }

                &.for {
                    padding: 0 rem(12);
                }

                &.conciliate {
                    padding: rem(6) rem(25) rem(6) 0;
                }

                @media (max-width: 768px) {
                    &.for {
                        padding: 0;
                    }

                    &.conciliate {
                        padding: rem(6) rem(25) rem(6) 0;
                    }
                }
            }

            &__avatar {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                height: rem(40);
                width: rem(40);
                font-size: rem(14);
                font-weight: 600;
                line-height: normal;
                background-color: #0000FF1A;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }

                &.for {
                    height: rem(50);
                    width: rem(50);
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                gap: rem(4);
            }

            &__name {
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(22);
            }

            &__position {
                font-size: rem(12);
                font-weight: 400;
                line-height: rem(22);
                text-transform: capitalize;
                margin-top: rem(-4);
            }

            &__status {
                max-width: fit-content;
                font-size: rem(10);
                font-weight: 500;
                line-height: rem(18);
                color: $white;
                background-color: $lightBlue;
                border-radius: rem(15);
                padding: 0 rem(10);
            }

            &__departament {
                font-size: rem(12);
                font-weight: 400;
                line-height: rem(20);
                text-transform: capitalize;
            }

            &__vacation {
                display: flex;
                flex-direction: column;
                gap: rem(4);
            }

            &__item {
                display: flex;
                gap: rem(8);
                line-height: rem(20);
            }

            &__label {
                font-size: rem(10);
                font-weight: 400;
                flex-shrink: 0;
            }

            &__value {
                font-size: rem(12);
                font-weight: 700;
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                gap: rem(4);
            }

            &__date {
                font-size: rem(12);
                font-weight: 500;
                text-transform: capitalize;
                color: $white;
                background-color: #0000FF4D;
                border-radius: rem(10);
                transition: all 0.2s linear;
                padding: 0 rem(7);
            }

            &__accept {
                font-weight: 700;
                background-color: $lightBlue;
            }

            &__conciliate {
                flex-shrink: 0;
                width: rem(24);
                height: rem(24);
                margin-left: auto;
            }
        }
    }

    &__reason {
        &__wrapper {
            display: flex;
            align-items: center;
            gap: rem(20);
        }

        &__select {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rem(8);
            width: 100%;
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(22);
            color: #000;
            border-bottom: rem(1) solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
            transition: all 0.2s linear;
            padding: rem(4) 0 rem(10);

            &:hover {
                border-bottom-color: $lightBlue;
            }

            span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            &.open {
                svg {
                    transform: rotate(-180deg);
                }
            }

            svg {
                flex-shrink: 0;
                width: rem(24);
                height: rem(24);
                color: $lightBlue;
                transform: rotate(0deg);
                transition: transform 0.2s linear;
            }
        }

        &__disabled {
            color: rgba(0, 0, 0, 0.5);
            cursor: default;
            user-select: none;
            pointer-events: none;
        }

        &__root {
            @media (max-width: 768px) {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }

        &__paper {
            width: 100%;
            max-width: rem(360);
            border-radius: rem(4);
            box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);

            @media (max-width: 768px) {
                max-width: 100%;
                border-radius: rem(16) rem(16) 0 0;
            }
        }

        &__head {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(24);
            border-bottom: rem(1) solid rgba(0, 0, 0, 0.1);
            padding: rem(18);
        }

        &__close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: rem(18);
            right: rem(20);
            width: rem(24);
            height: rem(24);
            color: $lightBlue;
            cursor: pointer;
            transition: color 0.2s linear;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        &__body {
            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                gap: rem(4);
                padding: rem(20);
            }
        }

        &__item {
            position: relative;
            display: flex;
            font-size: rem(14);
            font-weight: 600;
            line-height: rem(22);
            cursor: pointer;
            transition: background-color 0.2s linear;
            padding: rem(9) rem(16);

            &:hover {
                background-color: rgba(0, 0, 255, 0.05);
            }

            @media (max-width: 768px) {
                justify-content: center;
                font-size: rem(20);
                font-weight: 600;
                line-height: rem(28);
                text-align: center;
                background-color: rgba(0, 0, 0, 0.03);
                border-radius: rem(4);
                padding: rem(14);
            }
        }

        &__files {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            gap: rem(14);
        }
    }

    &__days {
        &__items {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: rem(4);

            &.active {
                .calendar__days__item {
                    &-number {
                        background-color: #00A7B5;
                        color: $white;
                    }

                    &-text {
                        opacity: 1;
                    }
                }
            }

            &-number {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                padding: rem(10);
                background-color: #00A7B50D;
                color: $black;
                min-height: rem(44);
                max-width: rem(44);
                min-width: rem(44);
                width: 100%;
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(24);
            }

            &-text {
                font-size: rem(12);
                font-weight: 600;
                line-height: rem(20);
                opacity: 0.5;
                text-transform: capitalize;
            }
        }
    }
}