.polls {
    &-content {
        padding: rem(30) rem(60);
        display: grid;
        grid-template-columns: repeat(4, minmax(rem(315), 1fr));
        grid-auto-rows: 1fr;
        grid-gap: rem(20);
    }


    &-modal {
        &__image {
            position: absolute;
            top: 0;
            left: 0;
            min-height: rem(240);
        }

        &__header {
            position: relative;

            & svg {
                & path {
                    stroke: #FFFFFF;
                }
            }
        }

        &__content {
            margin-top: rem(220);
        }

        &__circles {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-item {
                display: flex;
                align-items: center;
                width: 100%;
                position: relative;

                &-circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    min-width: rem(10);
                    min-height: rem(10);
                    width: rem(10);
                    height: rem(10);
                    border-radius: 50%;
                    background: #D9D9D9;
                }

                &-line {
                    width: 100%;
                    height: rem(2);
                    background: #D9D9D9;
                }
            }

            &-item.last {
                width: unset;

                & .polls-modal__circles-item-circle {
                    background: #00A7B5;
                }

                &-line {
                    display: none;
                }
            }

            &-item.active {
                & .polls-modal__circles-item-circle {
                    background: #00A7B5;
                }

                & .polls-modal__circles-item-line {
                    background: #00A7B5;
                }
            }
        }

        &-question {
            margin-top: rem(20);
            &__title {
                font-size: rem(16);
                font-weight: 700;
                line-height: 24px;
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: rem(15);
                margin-top: rem(20);
            }

            &__block {
                display: flex;
                align-items: center;
                gap: rem(15);
                font-size: rem(14);
                font-weight: 500;
                line-height: 24px;

                & input[type="checkbox"]:checked {
                    border-radius: 50%;
                    min-width: rem(24);
                    min-height: rem(24);
                    width: rem(24);
                    height: rem(24);
                    display: flex;
                    border: 2px solid #00A7B533;
                    cursor: pointer;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;

                    &::after {
                        content: '';
                        position: absolute;
                        width: rem(12);
                        height: rem(2);
                        background: #00A7B5;
                        transform: rotate(-45deg);
                        margin-top: rem(1);
                        margin-left: rem(5);
                        border-radius: rem(8);
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        margin-top: rem(5);
                        margin-left: rem(-7);
                        width: rem(7);
                        height: rem(2);
                        border-radius: rem(8);
                        background: #00A7B5;
                        transform: rotate(45deg);
                    }
                }

                & input[type="checkbox"] {
                    border-radius: 50%;
                    min-width: rem(24);
                    min-height: rem(24);
                    width: rem(24);
                    height: rem(24);
                    -webkit-appearance: none;
                    display: flex;
                    opacity: .2;
                    border: 2px solid #A1A1A1;
                    cursor: pointer;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: column;
                gap: rem(10);
                margin-top: rem(20);
            }

            &__button {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: rem(16);
                font-weight: 600;
                line-height: 26px;
                width: 100%;
                padding: rem(12) rem(30);
                background: #00A7B5;
                border-radius: rem(8);
                color: #fff;
                cursor: pointer;

                &__edit {
                    display: flex;
                    align-items: center;
                    gap: rem(10);
                    background: #0000000D;
                    color: #000;
                }
            }

            &__comment {
                display: flex;
                flex-direction: column;
                gap: rem(8);
                margin-top: rem(20);

                &__label {
                    font-size: rem(12);
                    font-weight: 600;
                    line-height: 20px;
                    opacity: .6;
                }

                &__input {
                    border: none;
                    border-radius: rem(8);
                    padding: rem(12) rem(30) rem(12) rem(16);
                    background: #00000008;
                    font-size: rem(14);
                    font-weight: 400;
                    line-height: 24px;
                    outline: none;
                }
            }
        }
    }

    &-create {
        &-content {
            padding: rem(30) 0;

            &__buttons {
                margin-top: rem(35);
            }
        }

        &__form {
            display: flex;
            flex-direction: column;
            gap: rem(20);

            &__deleteButton {
                background: #FF00001A !important;
                color: #FF0000 !important;
            }

            &-date {
                display: flex;
                gap: rem(20);
            }

            &-toggle {
                justify-content: left;
                gap: rem(15);
            }

            &__line {
                width: 100%;
                height: rem(1);
                background: #0000000D;
                margin: 0;
            }

            &__textWithToggle {
                display: flex;
                flex-direction: column;
                gap: rem(8);


                &-toggle {
                    width: fit-content;
                    justify-content: left;
                    gap: rem(15);

                   & .toggle__item {

                       & p {
                           font-size: rem(12);
                           opacity: .6;
                           font-weight: 600;
                           line-height: 20px;
                       }
                   }
                }
            }
        }

        &__preview {
            display: flex;
            flex-direction: column;
            gap: rem(15);
            margin-left: rem(100);

            &-items {
                border-radius: rem(16);
                background: rgba(0, 0, 0, 0.03);
            }

            &-item {
                padding: rem(20);
                gap: rem(20);

                &__header {
                    h3 {
                        font-size: rem(24);
                        font-weight: 700;
                        line-height: rem(29);
                        margin-bottom: rem(10);
                    }
                }

                &__content {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: rem(24);
                }
            }

            &__bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: rem(26);

                &__block {
                    display: flex;
                    align-items: center;
                    gap: rem(8);

                    & svg {

                    }
                }

                &__stats {
                    display: flex;
                    align-items: center;
                    gap: rem(20);
                }
            }

            &__image {
                border-top-left-radius: rem(16);
                border-top-right-radius: rem(16);
                width:100%;
                height: rem(230);
                background-size: cover;
                background-position: center;

                & img {border-top-left-radius: rem(16);
                    border-top-right-radius: rem(16);
                    width:100%;
                    max-height: rem(230);
                }
            }

            &__category {
                width: fit-content;
                padding: rem(4) rem(16);
                background: #00A7B50D;
                font-size: rem(12);
                font-weight: 600;
                line-height: 24px;
                color: #00A7B5;
                border-radius: rem(100);
                margin-top: rem(8);
            }

            &__text {
                font-size: rem(14);
                font-weight: 500;
                line-height: 22px;
                margin-top: rem(8);
            }
        }

        &-answers {
            &__label {
                font-size: rem(12);
                font-weight: 600;
                line-height: 20px;
                opacity: .6;
            }

            &__button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: rem(8) rem(15);
                background: #007BFF0D;
                margin-top: rem(8);
                cursor: pointer;
                border-radius: rem(8);
                width: 100%;

                & svg {

                }
            }

            &__block {
                display: flex;
                align-items: center;
                gap: rem(5);
                margin-top: rem(8);

                &__left {
                    display: flex;
                    align-items: center;
                    gap: rem(5);

                    & p {
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: 20px;
                        width: rem(15);
                        height: rem(20);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    & svg {
                        cursor: pointer;
                    }
                }

                &__input {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    border-radius: rem(8);
                    padding: rem(12) rem(12) rem(12) rem(16);
                    background: #00000008;

                    & p {
                        cursor: pointer;
                    }

                    & input {
                        background: none;
                        border: none;
                        outline: none;
                        width: 100%;
                        height: 100%;
                        font-size: rem(14);
                        font-weight: 400;
                        line-height: 24px;
                    }

                    &__delete {
                        position: relative;
                        z-index: 10;
                        cursor: pointer;
                    }
                }
            }
        }

        &-preview {
            display: flex;
            flex-direction: column;
            gap: rem(20);
            margin-left: rem(100);

            &__content {
                border-radius: rem(16);
                background: rgba(0, 0, 0, 0.03);
                overflow: hidden;
            }


            &__img {
                width: 100%;
                img {
                    width: 100%;
                    max-height: rem(250);
                }
            }

            &__desc {
                padding: rem(20);
                gap: rem(20);
            }

            &__title {
                font-size: rem(16);
                font-weight: 700;
                line-height: 24px;
            }

            &__answers {
                display: flex;
                flex-direction: column;
                gap: rem(15);
                margin-top: rem(20);
            }

            &__point {
                display: flex;
                align-items: center;
                gap: rem(10);
                margin-top: rem(20);

                &__title {
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: 24px;
                }
                &__stats {
                    display: flex;
                    align-items: center;
                    gap: rem(8);

                    & p {
                        font-size: rem(16);
                        font-weight: 700;
                        line-height: 19.5px;
                    }
                }
            }

            &__button {
                margin-top: rem(20);
                border-radius: rem(8);
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                width: 100%;
                padding: rem(12) rem(30);
                background: #00A7B5;
                font-size: rem(16);
                font-weight: 600;
                line-height: 26px;
            }
        }
    }
}