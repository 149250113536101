.news {
    height: 100%;
    background-color: #F2F6FB;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: rem(10) 0 rem(30);

        &__left {
            display: flex;
            align-items: center;

            h2 {
                font-weight: 700;
                font-size: 32px;
                line-height: 42px;
                margin-right: rem(30);
            }
        }

        &__dropdown {
            width: rem(315);
        }

        &__extras {
            margin-left: rem(40);
            display: flex;
            align-items: center;
            gap: rem(10);

            a {
                display: flex;
                align-items: center;
            }

            svg {
                cursor: pointer;
            }
        }

        &__chips {
            display: flex;
            align-items: center;

            .btn {
                min-height: rem(32);
                max-height: rem(32);
                padding: rem(6) rem(14);
                border-radius: rem(100);
                white-space: nowrap;
                font-size: rem(12);
                font-weight: 600;
                line-height: rem(20);
                transition: 0.3s ease-in-out;

                &-primary {
                    background: #007BFF;

                    &:hover, &:focus {
                        background: #006ee3;
                    }
                }
            }
        }
    }

    &__content {
        .col-xl-8 {
            .news__content-title {
                p {
                    a {
                        color: #FFFFFF;
                    }
                }
            }
        }

        &__block {
            padding-bottom: rem(30);
            height: 100%;
        }

        &__items {
            width: 100%;
        }

        &__main {
            display: grid;
            grid-gap: 20px;
            grid-auto-rows: 1fr;
            grid-template-columns: repeat(3, minmax(170px, 1fr));

            &-block {
                cursor: pointer;
                display: flex;
                overflow: hidden;
                position: relative;
                border-radius: 8px;
                margin-bottom: 0;
                flex-direction: column;
                background-color: #FFF;

                .news__content__img__image {
                    min-height: rem(150);
                    max-height: rem(150);
                }

                .item__status-block {
                    right: rem(23);
                    bottom: rem(23) !important;
                    top: auto !important;
                }
            }
        }

        &__item {
            background-color: $white;
            border-radius: rem(8);
            overflow: hidden;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__img {
            position: relative;
            height: 100%;
            min-height: rem(172);
            max-height: rem(172);

            &__image {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center 0px;
                min-height: rem(227);
                height: 100%;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                position: relative;
            }
        }

        &__info {
            padding: rem(15);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-title {
            p {
                font-size: rem(18);
                font-weight: 600;
                line-height: rem(26);
                margin-bottom: rem(10);
            }
        }

        &-btn {
            margin-top: auto;

            .btn-dummy {
                display: flex;
                align-items: center;
                cursor: auto;
                font-size: rem(12);
                font-weight: 600;
                padding: rem(4) rem(12);
                border-radius: rem(100);
                min-height: rem(28);
                max-height: rem(28);
                height: 100%;
                background-color: rgba(25, 88, 250, 0.05);
                max-width: fit-content;
                width: auto;
                color: #1958FA;
                white-space: nowrap;
            }
        }

        &-more {
            padding-top: rem(15);
            display: flex;
            align-items: center;

            &_item {
                display: flex;
                align-items: center;
                font-size: rem(12);
                font-weight: 500;
                line-height: rem(20);
                color: $black;
                opacity: 0.5;

                .icon {
                    margin-right: rem(8);
                    font-size: rem(20);
                    display: flex;
                    color: $black;
                }

                .text {
                    font-size: rem(12);
                    font-weight: 500;
                    line-height: rem(20);
                }

                & + div {
                    margin-left: rem(15);
                }
            }
        }

        &-more {
            &_item {
                &-ml {
                    margin-left: auto !important;
                }
            }
        }
    }

    &__block {
        width: 100%;

        .news {
            &__content {
                .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6,
                .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11,
                .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7,
                .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12,
                .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8,
                .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12,
                .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9,
                .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2,
                .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9,
                .col-xl-auto {
                    padding-left: rem(10);
                    padding-right: rem(10);
                }

                .row {
                    margin-left: rem(-10);
                    margin-right: rem(-10);
                }

                &__img {
                    max-height: none;
                    min-height: auto;

                    img {
                        object-fit: initial;
                    }
                }

                &__info {
                    padding: rem(20);
                }

                &__block {
                    padding-bottom: rem(20);
                }

                &-more_item .text {
                    white-space: nowrap;
                }

                .col-xl-8 {
                    .news {
                        &__content {
                            &__item {
                                position: relative;
                            }

                            &__img {
                                min-height: rem(417);
                                //max-height: rem(417);
                            }

                            &__info {
                                padding: rem(20);
                                position: absolute;
                                height: auto;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                flex-direction: row;
                                flex-wrap: wrap;
                                align-items: center;
                                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
                                border-radius: 0 0 rem(8) rem(8);
                            }

                            &-title, &-btn {
                                width: 45%;
                            }

                            &-title {
                                p {
                                    font-weight: 600;
                                    font-size: rem(24);
                                    line-height: rem(32);
                                    color: $white;
                                }
                            }

                            &-btn {
                                display: flex;
                                justify-content: flex-end;
                                margin-top: 0;

                                .btn-dummy {
                                    background-color: #007BFF;
                                    color: $white;
                                    display: flex;
                                }
                            }

                            &-more {
                                width: 100%;

                                &_item {
                                    opacity: 1;

                                    .text, .icon {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__employ {
        margin-left: rem(10);
        margin-right: rem(10);
        border-radius: rem(8);
        background-color: $white;
        width: calc(100vw - 90px);
        height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        p {
            font-size: rem(35);
            font-weight: 600;
        }
    }

    &__preview {
        &__body {
            margin-top: rem(50) !important;
            width: calc(100% + 32px);
            margin: -16px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            &.nonActive {
                max-width: calc( 75% - 359px );
                margin-right: auto;
                margin-left: auto;
            }

            &.active {
                max-width: initial;
                margin-right: rem(-16);
                margin-left: rem(-16);
            }
        }

        &__toolbar {
            display: flex;
            align-items: center;
            // padding: '8px 0';
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            background-color: #ffffff;

            & p {
                padding: 10px;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.5);
            }
        }

        &__editorToolbarView {
            gap: 4px;
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            display: flex;
            padding: 9px 16px;
            position: relative;
            font-size: 12px;
            transition: color 0.2s linear;
            align-items: center;
            font-weight: 500;
            line-height: 20px;
        }

        &__content {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-radius: 20px;
            margin-bottom: 40px;
        }

        &__adaptiveToolbarView {
            display: flex;
            margin-left: auto;
            margin-right: 6px;

            & div {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
                border-radius: 4px;
                margin: 0 2px;
                cursor: pointer;

                &.active {
                    background-color: #175afd10; // Adjust based on the actual color variable and desired lightness
                }
            }
        }
    }

    &__manage {
        padding: rem(30) rem(60);
        display: flex;
        flex-direction: column;
        background: #FAFAFA;
        height: 100%;

        &-body {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }

        &-header {
            margin-top: rem(15);
            width: 100%;

            &-title {
                font-size: rem(24);
                font-weight: 700;
                line-height: rem(32);
            }
        }

        &-content {
            padding: 0 rem(80);
            width: 100%;
            display: flex;
            justify-content: center;
            gap: rem(8);
            margin-top: rem(24);
            height: 100%;
        }

        &-forms {
            width: rem(420);
            padding: rem(30);
            background: #ffffff;
            border-radius: rem(8);
            height: 100%;

            &-title {
                font-size: rem(24);
                line-height: rem(32);
                font-weight: 700;
            }

            .form-file {
                padding: 0;
                min-height: rem(191);
                border-radius: rem(4);
                background: #007AFF;
                margin-top: rem(30);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                svg {
                    width: rem(24);
                    height: rem(24);
                }

                .form-field-desc {
                    color: #FFFFFF;
                    margin-top: rem(10);
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: rem(5);

                    h3 {
                        font-size: rem(12);
                        font-weight: 500;
                        line-height: rem(20);
                    }

                    p {
                        width: rem(114);
                        font-weight: 400;
                        font-size: rem(10);
                        opacity: .5;
                    }
                }
            }

            &-items {
                display: flex;
                flex-direction: column;
                gap: rem(10);
                margin-top: rem(10);

                &-color {
                    margin-top: rem(10);
                    margin-bottom: rem(20);
                }

                .form-block {
                    height: rem(60);

                    .label {
                        color: rgba(0, 0, 0, 0.50);
                        font-size: rem(12);
                        font-weight: 500;
                        line-height: rem(20);
                    }

                    .textField {
                        height: rem(40) !important;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                        input {
                            width: 100%;
                            padding-left: 0 !important;
                            padding-bottom: rem(20) !important;

                            &::placeholder {
                                color: #000000;
                                opacity: .3;
                                margin-left: rem(5);
                                font-size: rem(16);
                                font-weight: 500;

                            }
                        }
                    }

                    .field__holder {
                        display: none;
                    }
                }

                .toggle {
                    height: rem(40);
                }

                &-buttons {
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    gap: rem(10);
                    margin-top: rem(30);

                    button {
                        width: 50%;
                        display: flex;
                        height: rem(50);
                        justify-content: center;
                        align-items: center;
                        border-radius: rem(4);
                        border: none;
                        font-size: rem(16);
                        line-height: rem(24);
                        font-weight: 600;
                    }

                    &-delete {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
                    }

                    &-publish {
                        color: #ffffff;
                        background: #007BFF;
                    }
                }
            }
        }

        .react-select-prefix {
            &__control {
                border-radius: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                //border: none;

                &:hover {
                    //border: none;
                }

                &--menu-is-open {
                    //border-bottom: 1px solid #007AFF;
                }

                &--is-focused {
                    box-shadow: unset;
                }
            }

            &__value-container {
                padding: 0;
            }

            &__placeholder {
                color: #000;
                opacity: .3;
            }

            &__indicator {
                padding: 0;
            }
        }


        &-editor {
            width: calc(100% - 23rem);
            height: 100%;
            min-height: 20rem;
            background-color: #ffffff;
            border-radius: 0.5rem;

            &-header {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                padding: 0.5rem 0.625rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.75rem;
                line-height: 1.25rem;
                color: rgba(0, 0, 0, 0.5);
            }

            .container {
                position: relative;

                .codex-editor {
                    path, rect, line, circle {
                        stroke: blue;
                        color: blue
                    }

                    .colorPlugin {

                        svg {
                            fill: blue;
                        }
                    }
                }

                .ce-editorjsColumns_col {
                    border: 1px solid #c6d2e1;
                    border-radius: 8px;

                    svg {
                        fill: blue;
                    }
                }

                .ce-toolbar__plus svg, .ce-toolbar__settings-btn svg {
                    width: auto;
                    height: auto;
                }

                .ce-toolbar {
                    display: block;
                    z-index: 1;
                }

                .ce-block {
                    word-wrap: break-word;
                    padding-top: rem(35);
                }

                .ce-toolbar__actions {
                    margin-left: rem(10);
                    left: 0;
                }

                .ce-editorjsColumns_col .ce-toolbar__actions {
                    right: rem(-5);
                }

                .cdx-search-field__icon svg {
                    width: rem(14);
                    height: rem(14);
                }

                .image-tool__tune {
                    display: none;
                }

                .cdx-attaches--with-file .cdx-attaches__file-icon-background {
                    width: rem(42);
                }

                .cdx-attaches--with-file .cdx-attaches__file-icon-label {
                    top: rem(10);
                }

                .cdxcarousel-wrapper {
                    margin-bottom: rem(70);
                }

                .image-tool {
                    max-width: rem(400);

                    &.image-tool--stretched {
                        max-width: 100%;
                    }
                }

                .cdxcarousel-block {
                    min-width: calc(100% / 3);
                }

                .cdxcarousel-list {
                    background: rgba(0, 0, 0, 0.03);
                    padding: rem(20);
                    position: relative;

                    .column__setting__wrapper {
                        top: 100%;
                        left: 0;
                        width: 100%;
                        min-height: rem(54);
                        background: rgba(0, 0, 0, 0.1);
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        padding: rem(8);

                        .column__setting {
                            position: relative;
                            top: auto;
                            bottom: auto;
                            left: auto;
                            right: auto;
                        }

                        .cdxcarousel-rightBtn {
                            margin-left: rem(10);
                        }
                    }
                }

                .ce-toolbar__settings-btn {
                    path {
                        stroke: black;
                    }
                }

                .ce-popover__item .ce-popover__item-icon {
                    svg {
                        fill: transparent;
                    }
                }

                .ce-popover {
                    width: rem(270);
                }

                .editor-columns-blocks {
                    border-left: 1px solid rgba(0, 0, 0, 0.1);
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    display: flex;
                    justify-content: space-around;
                    padding: 0;
                }

                .editor-columns-block {
                    border: 0;
                    border-left: 1px solid rgba(0, 0, 0, 0.1);
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    width: 100%;
                    height: 100%;
                    min-height: rem(100);
                    transition: all 0.3s;
                    padding: rem(11);

                    &:hover {
                        background: rgba(0, 0, 0, 0.03);
                    }

                    & > div {
                        height: 100%;
                        min-height: rem(100);
                    }

                    &.w-50 {
                        max-width: 50%;
                    }

                    &.w-33 {
                        max-width: 33.3333%;
                    }

                    &.w-25 {
                        max-width: 25%;
                    }

                    .ce-block__content {
                        & > div {
                            word-break: break-word;
                        }
                    }
                }

                .codex-editor__redactor {
                    margin-right: 0;
                }

                .tc-wrap {
                    overflow: auto;
                }

                .tc-table {
                    max-width: rem(700);
                }

                .tc-add-row, .tc-add-column {
                    z-index: 1;

                    svg {
                        background: white;
                    }
                }

                .ck-reset_all, .ck-widget__selection-handle {
                    display: none;
                }

                .ck-editor__editable.ck-focused {
                    border: none;
                    box-shadow: none;
                }

                .table.ck-widget.ck-widget_with-selection-handle.ck-widget_selected {
                    margin: 0;
                }

                .table.ck-widget {
                    overflow: auto;
                }

                [data-item-name=withBorder], [data-item-name=withBackground] {
                    display: none;
                }

                .ck-content .table table td, .ck-content .table table th {
                    min-width: rem(200);
                }

                .cdx-collapse-toggle::before {
                    border-left: 6px solid blue;
                }

                .cdx-collapse-title {
                    width: 100%;
                    text-overflow: ellipsis;
                }

                .cdx-collapse-content {
                    overflow-y: auto;
                }

                .custom-section {
                    position: relative;
                    inset: auto;
                    height: auto;
                }
            }
        }
    }

    &-show {
        .breadcrumbs {
            padding: 0 rem(60);
        }

        &__title {
            padding: rem(15) rem(172);

            h1 {
                font-size: rem(32);
                font-weight: 700;
                line-height: rem(42);
            }
        }

        &__content {
            padding: rem(15) rem(172);
            width: 100%;
            display: flex;

            &__left {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: rem(30);
                padding: 0 !important;
            }

            &__right {
                display: flex;
                flex-direction: column;
                gap: rem(20);
            }
        }

        &__info {
            position: relative;
            width: 100%;
            background: #fff;
            border-radius: rem(8);

            &__image {
                min-height: 520px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center 0;
                background-color: rgb(255, 255, 255);
                border-radius: rem(8) rem(8) 0 0;
                width: 100%;
            }

            &__desc {
                padding: rem(30);
            }

            &__top {
                display: flex;
                justify-content: space-between;
            }

            &__status {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: rem(4) rem(12);
                border-radius: rem(100);
                background: #0071DA;

                p {
                    color: #fff;
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: rem(22);;
                }
            }

            &__tages {
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    color: #0071DA;
                    font-size: rem(14);
                    font-style: normal;
                    font-weight: 500;
                    line-height: rem(22);
                    padding: rem(4) rem(12);
                    border-radius: 100px;
                    background: rgba(0, 113, 218, 0.05);
                }
            }

            &__json {
                word-wrap: break-word;
                padding: rem(20) 0;
            }

            &__time {
                display: flex;
                align-items: center;

                p {
                    opacity: .5;
                    font-size: rem(14);
                    font-style: normal;
                    font-weight: 500;
                    line-height: rem(22);
                }
            }

            &__bottom {
                margin-top: rem(30);
                display: flex;
                justify-content: space-between;
            }

            &__buttons {
                display: flex;
                align-items: center;
                gap: rem(20);

                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-wrap: nowrap;
                    width: 203px;
                    padding: rem(13) 0;
                    border-radius: 8px;
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: rem(24);
                }
            }

            &__likeButton {
                border: none;
                color: #fff;
                background: #0071DA;
            }

            &__shareButton {
                color: #0071DA;
                border: 2px solid rgba(0, 0, 0, 0.10);
                background: #FFF;
            }

            &__stats {
                display: flex;
                align-items: center;
                gap: rem(20);

                p {
                    display: flex;
                    align-items: center;
                    opacity: .5;
                    gap: rem(5);
                    color: #000;
                    font-size: rem(14);
                    font-style: normal;
                    font-weight: 500;
                    line-height: rem(22);
                }
            }
        }
    }

    &-suggested {
        &__first {
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            aspect-ratio: 1 / 1;
            border-radius: 8px;
            flex-direction: column;
            color: #fff;

            &:after {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                z-index: 0;
                position: absolute;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
                border-radius: 8px;
            }

            &__content {
                width: 100%;
                z-index: 100;
                position: absolute;
                bottom: 0;
                padding: rem(15);

                &__title {
                    font-size: rem(20);
                    font-style: normal;
                    font-weight: 600;
                    line-height: rem(28);
                    z-index: 1;
                    color: rgb(255, 255, 255);
                }

                &__stats {
                    display: flex;
                    align-items: center;
                    gap: rem(20);
                    margin-top: rem(20);

                    p {
                        display: flex;
                        align-items: center;
                        gap: rem(5);
                    }
                }

                &__time {
                    color: #FFFFFF;
                    font-size: rem(10);
                    font-weight: 500;
                    line-height: rem(18);
                    opacity: .5;
                    margin-top: rem(20);
                }
            }
        }

        &__item {
            border-radius: rem(8);
            background: #FFFFFF;

            &__image {
                min-height: 148px;
                background-size: cover;
                background-repeat: no-repeat;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: rem(20);
                padding: rem(15);
                &__title {
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: rem(22);
                }
            }

            &__stats {
                opacity: .5;
                display: flex;
                align-items: center;
                gap: rem(20);
                p {
                    display: flex;
                    gap: rem(5);
                }
            }

            &__time {
                opacity: .5;
                color: #000;
                font-size: rem(10);
                line-height: rem(18);
                font-weight: 500;
            }
        }
    }
}


@media (max-width: 1349.95px) {
    .news__content__main {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
}