
.auth {
	&__steps {
		display: flex;
		align-self: flex-start;
		align-items: center;
		padding: rem(10);
		border-radius: rem(6);
		background-color: $white;
		z-index: 1;

		&-icon {
			display: flex;
			align-items: center;

			img {
				width: rem(24);
				height: rem(24);
			}
		}

		&-text {
			font-size: rem(18);
			font-weight: 700;
			margin-left: rem(10);
			color: #003166;
		}
	}

	&__steps, &__start {
		display: flex;
	}

	&__start {
		flex-direction: column;
		align-items: center;
		margin-bottom: auto;

		.btn {
			width: 100%;

			& + .btn {
				margin-left: 0;
				margin-top: rem(15);
			}
		}
	}

	&__login {
		height: 100%;

		&__form {
			height: 100%;
			display: flex;
			flex-direction: column;
		}

		.btn {
			width: 100%;

			&-link {
				font-size: rem(15);
				font-weight: 600;
				line-height: rem(18);
				height: auto;
				min-height: auto;
				max-height: none;
				cursor: pointer;

				&:hover {
					text-decoration: none;
				}

				img {
					width: rem(24);
				}
			}
		}
	}

	&__content {
		display: flex;
		width: 100%;
		height: 100vh;

		&_left, &_right {
			padding-left: rem(15);
			padding-right: rem(15);
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		&_left {
			max-width: 44%;
			flex-direction: column;
			padding-top: rem(20);
			padding-bottom: rem(60);

			&_block {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				max-width: rem(366);
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				padding: rem(36) 0 0;

				&.start {
					.auth__content_left_block {
						&_header {
							//margin-top: 0;
							margin-bottom: rem(56);
							text-align: center;
							align-self: center;
						}
					}
				}

				&.activate {}

				&_header {
					margin-bottom: auto;
					align-self: flex-start;
					//margin-top: auto;

					&.register {
						margin-bottom: rem(40);
					}

					h2 {
						font-size: rem(50);
						font-weight: 900;
						text-transform: capitalize;
						color: #007AFF;
					}

					img {
						width: rem(153);
						height: rem(37);
					}
				}

				&_mobile-swiper {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				&_content {
					width: 100%;
				}

				&_footer {
					margin-top: auto;

					&.register {
						margin-top: rem(91);
					}

					p {
						font-size: rem(14);
						line-height: rem(24);
					}
				}
			}

			&_item {
				width: 100%;
				height: calc(100% - 55px);
				display: flex;
				flex-direction: column;
			}
		}

		&_right {
			max-width: 56%;
			background-color: #E9F0F9;
			-moz-border-radius-topleft: rem(25);
			-moz-border-radius-bottomleft: rem(25);
			overflow: hidden;

			.swiper {
				position: relative;
				width: 100%;
				height: 100%;

				&-slide {
					display: flex;
					align-items: flex-end;
				}

				.swiper-pagination {
					left: rem(97 - 7.5);
					bottom: rem(60);
					text-align: left;
					width: auto;

					.swiper-pagination-bullet {
						margin: 0 rem(7.5);
						background-color: transparent;
						border: rem(1) solid rgba(0, 49, 102, 1);
						width: rem(8);
						height: rem(8);

						&-active {
							background-color: #007AFF;
							border: none;
						}
					}
				}
			}

			&_block {
				padding-left: rem(97);
				//padding-bottom: rem(98);
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
			}

			&_item {
				width: 100%;

				&-text {
					padding-top: rem(60);
					color: #003166;
					max-width: rem(550);

					h3 {
						font-size: rem(35);
						margin-bottom: rem(15);
					}

					p {
						font-size: rem(17);
					}
				}

				&-banner {
					width: 100%;

					img {
						width: 100%;
						height: 100%;
						max-width: 30vw;
						max-height: 50vh;
					}
				}
			}
		}

		&_step {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: rem(10);

			.btn {
				min-height: auto;
				max-height: none;

				&.show {
					display: block;
				}
			}
		}

		&_help {
			text-align: center;

			p {
				color: #003166;
				font-size: rem(14);
				font-weight: 500;

				a {
					color: #003166;
				}
			}
		}
	}

	&__register {
		.btn {
			width: 100%;
		}

		&_left_content {
			padding: 0;//rem(60) 0 rem(90);
		}
	}

	&__activate {
		height: 100%;

		&__content {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
}

.registration {
	&__click {
		.btn {
			margin-top: rem(15);
		}
	}
}

.main {
	position: relative;
	min-height: calc( 100vh - 5.5rem);

	&-scrolled {
		margin-top: rem(40);
	}

	&__home {
		height: 100%;
		min-height: inherit;
		overflow: hidden;
		background: #fff;

		@media (max-width: 1020px) {
			background: unset;
		}
	}

	&__banner {
		&__setting {
			.form-file {
				min-height: rem(400);
			}
		}
	}

	&__inner {
		padding-top: rem(70);
	}
}

.home {
	& > div {
		margin-bottom: rem(30);
	}

	&__col {
		max-width: calc( 100% - 47.125rem );
		flex: auto;
		padding-left: rem(15);
		padding-right: rem(15);
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: rem(15);

		&-title {
			p {
				font-size: rem(24);
				font-weight: 700;
				line-height: rem(34);
			}
		}

		&-url {
			display: flex;
			justify-content: flex-end;

			.btn {
				font-size: rem(16);
				font-weight: 500;
				line-height: rem(26);
				color: #1958FA;
				min-height: auto;
				max-height: none;

				.icon {
					display: flex;
					margin-left: rem(4);

					img {
						min-width: rem(24);
						max-width: rem(24);
					}
				}
			}
		}
	}

	&__groups {
		&__content {
			&-items {
				overflow: hidden;
				.swiper-slide {
					//min-width: rem(150);
				}
			}

			&-block {
				display: flex;
				width: 100%;
				height: 100%;
			}

			&-item {
				width: 100%;
				position: relative;
				z-index: 0;
				border-radius: rem(12);
				overflow: hidden;
				display: block;

				&__img {
					img {
						height: 100%;
						width: 100%;
					}
				}

				&__block {
					position: absolute;
					top: 0;
					left: 0;
					padding: rem(15);
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					height: calc(100% - 1.875rem);
					width: calc(100% - 1.875rem);
					background-color: rgba(0, 0, 0, 0.3);

					&:before {
						content: '';
						z-index: -1;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0.3)
					}
				}

				&-icon {
					background-color: $white;
					border-radius: 50%;
					max-width: rem(30);
					max-height: rem(30);
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						max-width: rem(18);
						min-width: rem(18);
						max-height: rem(18);
						min-height: rem(18);
					}
				}

				&-text {
					p {
						color: $white;
						font-size: rem(14);
						font-weight: 600;
					}
				}
			}
		}
	}

	&__news {
		.home__header {
			padding-bottom: rem(20);
		}
	}

	&__swiper {
		&-banner {
			display: block;
			width: 100%;

			img {
				width: 100%
			}
		}
	}
}
