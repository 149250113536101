.close {
    &:hover {
        color: var(--lightBlue);
    }
}

.action {
    color: var(--whiteColor);
    background-color: var(--lightBlue);

    &:hover {
        background-color: var(--lightBlue);
    }
}

.process {
    color: var(--lightBlue);
}