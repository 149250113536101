.header {
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9;
	height: rem(68);
	background: #fff;

	&__exitIcon {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-center {
		&__title {
			font-size: rem(20);
			font-weight: 700;
			line-height: rem(2);
		}
	}

	&__items {
		width: 100%;
		padding: rem(20) 0;
		@media (max-width: 1020px) {
			width: 100%;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 1020px) {
			width: 100%;
		}
	}

	&__left, &__right {
		display: flex;
		align-items: center;
	}

	&__search, &__notification, &__menu {
		& svg {
			width: rem(24);
			height: rem(24);
		}
	}

	&__menu {
		background-color: transparent;
		border: 0;
		display: flex;
		align-items: center;
	}

	&__logo {
		margin: 0 rem(20);

		@media (max-width: 1020px) {
			display: flex;
		}

		& img {
			width: rem(161);
			height: rem(26);

			@media (max-width: 1020px) {
				height: rem(20);
				width: rem(125);
			}
		}
	}

	&__title {
		padding-right: rem(10);

		p {
			color: $white;
			font-size: rem(20);
			font-weight: 600;
		}
	}

	&__profileIcon {
		width: rem(40);
		height: rem(40);
		border-radius: 50%;

	}

	&__other {
		display: flex;
		align-items: center;
		gap: rem(40);

		.btn {
			min-height: auto;
			max-height: none;

			div {
				display: flex;
				align-items: center;
			}
		}

		.btn + .btn {
			margin-left: 0;
		}
	}

	&__notification {
		margin-left: rem(40);

		@media (max-width: 1020px) {
			margin-left: 0;
		}
	}

	&__profile {
		padding-left: 0 !important;

		&__btn {
			display: flex;
			align-items: center;
			background-color: transparent;
			border: 0;
		}

		&-icon {
			border-radius: 50%;
			background-color: #CCD9E0;
			overflow: hidden;
			max-height: rem(50);
			max-width: rem(50);
			min-width: rem(50);
			display: block;
			margin-right: rem(5);

			img {
				max-height: rem(50);
				max-width: rem(50);
				min-width: rem(50);
			}
		}

		&-name {
			padding-left: rem(15);

			p {
				color: $black;
				font-size: rem(20);
				font-weight: 700;
				line-height: rem(28);
			}
		}

		&-star {
			margin-left: rem(10);
			border-radius: rem(100);
			display: flex;
			align-items: center;

			&-icon {
				display: flex;
			}

			.text {
				margin-left: rem(8);
				color: $black;
				font-size: rem(12);
				font-weight: 700;
			}
		}
	}

	&__services {
		padding: 0 rem(20);

		.btn {
			padding: rem(12) rem(25) rem(12) rem(15);
			display: flex;
			align-items: center;
			min-height: rem(50);
			border: 2px solid rgba(0, 0, 0, 0.1);
			background-color: rgba(255, 255, 255, 0.1);

			span {
				display: flex;
				align-items: center;
			}

			.icon {
				display: flex;

				img {
					max-width: rem(24);
					min-width: rem(24);
				}
			}

			.text {
				margin-left: rem(10);
				font-size: rem(16);
				font-weight: 600;
			}
		}
	}

	&__spaces {
		position: relative;
		align-items: center;
		padding: rem(15);
		margin: rem(20) rem(30) rem(10);
		border-radius: rem(8);
		background-color: rgba(0, 122, 255, 0.075);

		&__name {
			display: flex;
			align-items: center;
			padding-bottom: rem(20);

			img {
				width: rem(50);
				height: rem(50);
				min-width: rem(50);
				min-height: rem(50);
				object-fit: contain;
				border-radius: 50%;
			}

			p {
				padding-left: rem(15);
				font-size: rem(18);
				font-weight: 700;
				line-height: 26px;
			}
		}

		&__groups {
			cursor: pointer;
			padding: rem(15) rem(25) rem(15) rem(15);
			position: relative;
			text-align: center;
			border-radius: rem(8);
			margin-bottom: rem(15);
			background-color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
		}

		.btn {
			width: 100%;
		}
	}
}
