.sport {
    gap: rem(20);
    display: flex;
    flex-direction: column;

    &__head {
        &-title {
            font-size: rem(32);
            font-weight: 700;
            line-height: rem(38);
        }

        &__days {
            display: flex;
            justify-content: flex-end;

            & > div {
                max-width: rem(643);
            }
        }
    }

    &__content {
        &-card {
            margin-top: rem(20);

            &-user {
                display: flex;
                align-items: center;
                gap: rem(12);
                background-color: #00000008;
                padding: rem(10);
                border-radius: rem(8);

                &__image {
                    min-height: rem(46);
                    min-width: rem(46);
                    width: rem(46);
                    height: rem(46);
                    border-radius: rem(7);
                }

                &__content {
                    display: flex;
                    flex-direction: column;
                    gap: rem(4);

                    &__name {
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: 20px;
                    }

                    &__position {
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: 18px;
                    }
                }
            }

            &-block {
                position: relative;
                border-radius: rem(16);
                overflow: hidden;

                &__image {
                    width: 100%;
                    min-height: rem(212);
                    background-position: center;
                    background-size: cover;
                }

                &-content {
                    position: relative;
                    background: #fff;
                    padding: rem(20);

                    &__top {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    &__title {
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: 24px;
                    }

                    &__subtitle {
                        font-size: rem(12);
                        font-weight: 600;
                        line-height: rem(24);
                        opacity: 0.5;
                    }

                    &__text {
                        margin-top: rem(5);
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: 24px;
                        display: -webkit-box;
                        overflow: hidden;
                        white-space: break-spaces;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }

                    &__bottom {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: rem(20) 0;
                        gap: rem(8);

                        &__block {
                            display: flex;
                            align-items: center;
                            padding: rem(10);
                            background-color: #00A7B50D;
                            border-radius: rem(8);

                            & p {
                                font-size: rem(12);
                                font-weight: 500;
                                line-height: 14px;
                                color: #00A7B5;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }

    &__view {
        display: flex;
        align-items: center;
        justify-content: center;

        &__content {
            max-width: rem(975);
            background-color: $white;

            .sport__content-card-block {
                &-content {
                    &__top {
                        margin-bottom: rem(10);
                    }

                    &__title {
                        font-size: rem(32);
                        font-weight: 700;
                        line-height: rem(38);
                    }

                    &__text {
                        display: flex;
                    }

                    &__bottom {
                        justify-content: flex-start;

                        &__block {
                            border-radius: rem(38);
                        }
                    }
                }
            }
        }
    }
}