@import "./variables";

//Methods

$browser-context: 16;

$blueColor: #000000;
$lightBlue: #0000ff;

@function blueColor($opacity: number, $context: $blueColor) {
	$opacity: $opacity / 100;
	@return rgba($context, $opacity)
}

@function lightBlue($opacity: number, $context: $lightBlue) {
	$opacity: $opacity / 100;
	@return rgba($context, $opacity)
}

@function rem($pixels: number, $context: $browser-context) {
	@return #{$pixels / $context}rem;
}

//Mixin

@mixin montserrat($rem){
    font: rem($rem) $montserrat;
    //ответ
    //@include montserrat(35);
}

