@import "./vars/variables";
@import "./vars/functions";

@import "~swiper/swiper-bundle.css";

@import "bootstrap-grid.min.css";

@import "reset";
@import "fonts";

@import "vars/form/filter-select";
@import "vars/form/toggle-block";
@import "vars/form/field";
@import "vars/form/carousel";
@import "vars/form/search";
@import "vars/form/calendar";
@import "vars/modal/modal";
@import "vars/accordion/accordion";

@import 'content/main/feed/feed';
@import 'content/main/profile/profile';
@import 'content/main/workspace/workspace';
@import 'content/main/vacation/vacation';
@import 'content/main/reference/reference';
@import 'content/main/service/service';
@import 'content/main/contacts/contacts';
@import 'content/main/birthdays/birthdays';
@import 'content/main/employees/employees';
@import 'content/main/favorites/favorites';
@import 'content/news/news';
@import 'content/polls/polls';
@import 'content/blogs/blogs';
@import 'content/services/services';
@import 'content/contests/contests';
@import "content/service/structure/structure";
@import "content/sport/main";
@import "content/breadcrumbs/breadcrumbs";
@import "content/navigation";

@import "header";
@import 'content/layout';
@import "content/slider";
@import "content/content-main";
@import "content/main/sidebar/right/links";
@import "content/news";
@import "content/ideas";
@import "content/analytics";
@import "aside/profile";
@import "aside/aside-home";
@import "content/calendar/vacation";
@import "content/calendar/calendar";

@import "footer";
@import "responsive";

body {
	font-size: #{$browser-context}px;
	font-family: $montserrat;
	color: $black;
	background: #f7f7f7;
	overflow-y: auto;
	overscroll-behavior: none;
	overflow-x: hidden;
	height: 100vh;

	&.modal-show,
	&.menu-show {
		overflow: hidden;
	}
}

.btn {
	overflow: hidden;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: rem(50);
	max-height: rem(50);
	height: 100%;
	border-radius: rem(8);
	transition: all 0.3s ease-in-out;
	font-size: rem(16);
	line-height: rem(26);
	font-weight: 600;
	background-color: $blue;
	border: none;

	&:hover,
	&:focus {
		color: $white;
	}

	&:disabled,
	&.disabled {
		background: $primary;
		border-color: $primary;
	}

	&.left {
		text-align: left;
	}

	& + & {
		margin-left: rem(10);
	}

	&-primary {
		background: $primary;
		color: $white;

		&:hover, &:focus {
			background: #019ca8;
		}
	}

	&-gray {
		background-color: rgba(0, 49, 102, 0.14);
		color: #003166;

		&:hover, &:focus {
			background-color: rgba(0, 49, 102, 0.3);
		}
	}

	&-secondary {
		background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
		linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03));

		color: $black;

		&:hover, &:focus {
			background-color: rgb(218, 218, 218);
		}
	}

	&-link {
		background-color: transparent;
		color: #1684F2;

		&:hover, &:focus {
			background-color: transparent;
			color: #1276dc;
		}

		span {
			text-decoration: underline;
			margin-left: rem(5);
		}
	}

	&-light {
		background: #0000000D;
		color: $black;

		&:hover, &:focus {
			background-color: rgba(0, 0, 0, 0.2);
			color: $black;
		}
	}

	&-text {
		display: contents;
	}

	&-white {
		background-color: $white;
		color: $black;

		&:hover, &:focus {
			background-color: #d5d5d5;
			color: $black;
		}
	}

	&-red {
		background: #FF00001A;
		color: #FF0000;

		&:hover, &:focus {
			background-color: rgba(255, 0, 0, 0.3);
			color: #FF0000;
		}

		&:disabled,
		&.disabled {
			background: #FF00001A;
			border-color: #FF00001A;
		}
	}

	&-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		border: none;

		&:hover, &:focus {
			background-color: transparent;
		}
	}
}

button, a {
	font-family: $montserrat;
	cursor: pointer;
	border: 0;

	&:focus {
		outline: none;
	}
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.pull-left {
	float: left !important;
}

.pull-right {
	float: right !important;
}

.full-height {
	height: 100%;
}

.no-padding {
	padding: 0 !important;
}

.w-100 {
	width: 100% !important;
}

.h-100 {
	height: 100% !important;
}

* {
	margin: 0;
	padding: 0;
}

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.overflow-hidden {
	overflow: hidden !important;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
	padding-left: rem(15);
	padding-right: rem(15);
}

.row {
	margin-left: rem(-15);
	margin-right: rem(-15);

	&.no-gutters {
		margin-left: 0;
		margin-right: 0;

		& > .col,
		& > [class*="col-"] {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&.no-parent {
		& > .col,
		& > [class*="col-"] {
			position: static;
		}
	}

	&.no-wrap {
		flex-wrap: nowrap;
	}
}

.container {
	max-width: none;
	padding-left: rem(60);
	padding-right: rem(60);
}

.row,
.d-flex {
	.pull-left {
		margin-right: auto;
	}

	.pull-right {
		margin-left: auto;
	}
}

img {
	max-width: 100%;
	height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-weight: 400;
	margin: 0;
}

a {
	color: $black;
	text-decoration: none;
	transition: all 0.3s ease;

	&:hover,
	&:focus {
		color: $black;
		text-decoration: none;
		transition: all 0.3s ease;
	}
}

.main-employ {
	display: flex;
	flex-direction: column;
	padding: rem(20);

	a {
		margin-top: rem(5);
	}

	& > div {
		display: flex;
		flex-direction: column;
		padding-top: rem(20);
	}
}

.mb-2 {
	margin-bottom: 1rem;
}

.notification {
	&-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: rem(24);
		min-height: rem(24);
		width: rem(24);
		height: rem(24);
		border-radius: 50%;
		font-size: rem(14);
		font-weight: 500;
		line-height: 22px;
		
		&__red {
			color: #fff;
			background: #FF0000;
		}

	}
}

@media screen and (max-width: 1600px) {
	.col,
	.col-1,
	.col-10,
	.col-11,
	.col-12,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-auto,
	.col-lg,
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-auto,
	.col-md,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-auto,
	.col-sm,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-auto,
	.col-xl,
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-auto {
		padding-left: rem(10);
		padding-right: rem(10);
	}

	.row {
		margin-left: rem(-10);
		margin-right: rem(-10);
	}

	.container {
		padding-left: rem(60);
		padding-right: rem(60);
	}
}

@media screen and (max-width: 991px) {
	.col,
	.col-1,
	.col-10,
	.col-11,
	.col-12,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-auto,
	.col-lg,
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-auto,
	.col-md,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-auto,
	.col-sm,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-auto,
	.col-xl,
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-auto {
		padding-left: rem(8);
		padding-right: rem(8);
	}

	.row {
		margin-left: rem(-8);
		margin-right: rem(-8);
	}

	.container {
		padding-left: rem(8);
		padding-right: rem(8);
	}
}
