.footer {
	bottom: 0;
	position: relative;
	width: 100%;
	background-color: $white;
	padding: rem(21) rem(30 - 15);

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__logo {
		display: block;

		img {
			max-width: rem(280);
			min-width: rem(280);
		}
	}

	&__social, &__mobile {
		display: flex;
		align-items: center;

		&-block {
			display: flex;
			padding: 0 rem(8);

			//img {
			//	max-width: rem(35);
			//	min-width: rem(35);
			//}
		}
	}

	&__mobile {
		&-block {
			img {
				width: rem(38);
				height: rem(38);
			}
		}
	}
}

.menu {
	&__footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: rem(10) rem(20);
		background-color: $white;
		z-index: 3;


		&__items {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		&__item.active {
			& svg {
				& path {
					color: #00A7B5;
				}
			}
		}

		&__item {
			width: rem(50);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			& button  {
				background: none;
				border: none;
			}
		}
	}
}
