@for $i from 1 through 60 {
    @media(min-width: 1920px + ($i * 100)) {
        html {
            font-size: 16px + $i !important;
        }
    };
}

@media (min-width: 960px) {
    .auth__register {
        .auth__login__form {
            .form-block {
                max-height: rem(360);
                height: 100%;
                overflow-y: auto;
            }
        }
    }
}


@media (max-width: 1020px) {
    body {
        background: #F7f7f7;
    }

    .main {
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        background: #f7f7f7 !important;
    }
}


@media screen and (min-width: 1800px) {
    .auth__content {
        &_right {
            &_item-banner {
                img {
                    max-width: 37vw;
                    max-height: 60vh;
                }
            }
        }
    }
}

@media screen and (min-width: 1600px) {
    .auth__content_left_block {
        max-width: rem(488);
    }
}

@media screen and (min-width: 2350px) {
}

@media screen and (min-width: 2500px) {
}

@media screen and (min-width: 2700px) {
}

@media screen and (max-width: 1600px) {
    .home {
        &__col {
            max-width: calc(100% - 39.375rem);
            padding-left: rem(10);
            padding-right: rem(10);
        }
    }

    .aside {
        &__left, &__right {
            &__col {
                padding-left: rem(10);
                padding-right: rem(10);
                max-width: rem(315);
            }
        }
    }

    .news__block {
        .news__content {
            &__img {
                max-height: rem(228);
                min-height: rem(228);
            }

            .col-xl-8 {
                .news__content__img {
                    min-height: rem(417);
                    max-height: rem(417);
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1340px) {

}

@media screen and (max-width: 1170px) {
}

@media screen and (max-width: 1215px) {
    .header {
        &__other {
            div {
                padding: 0;
            }
        }

        &__profile {
            &-name {
                padding-left: rem(10);
            }

            &-star {
                margin-left: rem(10);
            }
        }
    }


    .main {
        &__home {
            .row {
                flex-wrap: nowrap;
            }

            .home__col {
                max-width: calc(100% - 600px);
                flex: auto;
            }

            .aside__left__col, .aside__right__col {
                max-width: rem(300);
                flex: 0 0 rem(300);
            }
        }
    }
}

@media screen and (max-width: 1120px) {
    .home__swiper {
        .swiper-pagination-fraction,
        .swiper-pagination-custom,
        .swiper-container-horizontal > .swiper-pagination-bullets {
            padding: rem(15) rem(20 - 5);
        }
    }
}

@media screen and (max-width: 1090px) {
}

@media screen and (max-width: 1056px) {
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 991px) {
    .aside {
        &__left {
            display: none;
        }

        &__happy {
            &__content {
                &__icon {
                    max-height: rem(78);
                }

                &__items {
                    padding: 0;
                }

                &__item {
                    max-width: rem(120);
                }

                &__text {
                    text-align: center;

                    p {
                        font-size: rem(12);
                        line-height: rem(18);
                    }
                }
            }
        }

        &__gratitude {
            margin-bottom: rem(30);

            &__content {
                display: flex;

                &-block {
                    display: flex;
                    max-width: rem(120);
                    width: 100%;
                    flex-direction: column;
                    box-shadow: none;
                    position: relative;
                    justify-content: flex-start;
                }

                &-text {
                    padding: rem(8) 0 0;
                    text-align: center;
                }

                &-icon {
                    position: absolute;
                    top: rem(15 - 4);
                    left: calc(50% + 1.625rem);
                    transform: translateX(-50%);
                    max-width: rem(32);
                    min-width: rem(32);
                    max-height: rem(32);
                    min-height: rem(32);
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        max-width: rem(20);
                        min-width: rem(20);
                        max-height: rem(20);
                        min-height: rem(20);
                    }
                }

                &-profile {
                    max-height: rem(78);
                }
            }
        }

        &__urls {
            &__header {
                padding: rem(8) 0;

                p {
                    font-size: rem(20);
                }
            }
        }

        &__questions {
            margin-bottom: rem(30);
            border-radius: rem(8);
        }
    }

    .main {
        //margin-top: rem(60);

        min-height: calc(100vh - 60px - 63px);

        //&__home {
        //	padding: rem(20) rem(20 - 8) rem(48);
        //}
    }

    .header {
        &__logo {
            margin: 0;
        }

        &__items {
            padding: rem(10) 0;
        }

        &__item {
            padding: 0 rem(12);
        }

        &__title, &__menu, &__profile-name, &__services {
            display: none;
        }

        &__right {
            & > div {
                padding: 0;
                border-right: none;
            }

            .header__other {
                padding: 0 rem(10);
            }
        }

        &__profile {
            &-icon {
                max-height: rem(40);
                max-width: rem(40);
                min-width: rem(40);

                img {
                    max-height: rem(40);
                    max-width: rem(40);
                    min-width: rem(40);
                }
            }

            &-star {
                margin-left: rem(4);
                padding: rem(4) rem(8);

                &-icon {
                    max-width: rem(16);
                    max-height: rem(16);

                    img {
                        max-width: rem(16);
                        max-height: rem(16);
                    }
                }

                p {
                    margin-left: rem(4);
                    font-size: rem(12);
                    line-height: rem(18);
                }
            }
        }
    }

    .home {
        &__swiper {
            .swiper-pagination-fraction,
            .swiper-pagination-custom,
            .swiper-container-horizontal > .swiper-pagination-bullets {
                display: none;
            }
        }

        &__header {
            padding: rem(4) 0;

            &-url {
                .btn {
                    background-color: $white;
                    border-radius: rem(40);
                    padding: rem(6) rem(15);
                    max-height: rem(36);
                    min-height: rem(36);

                    &-with-icon {
                        padding: rem(6) rem(5) rem(6) rem(15);
                    }
                }
            }
        }

        &__groups {
            &__content {
                padding-top: rem(15);

                &-items {
                    .swiper-slide {
                        //max-width: rem(120);
                    }
                }
            }
        }
    }

    .news {
        &__content {
            &__img {
                min-height: rem(200);
                max-height: rem(200);

                img {
                    min-height: rem(200);
                    max-height: rem(200);
                }
            }

            &__item {
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                    z-index: 1;
                }
            }

            &__info {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                height: auto;
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;
                z-index: 2;
            }

            &-title {
                color: $white;

                p {
                    margin-bottom: 0;
                }
            }

            &-btn {
                .btn-dummy {
                    background-color: rgba(255, 255, 255, 0.5);
                    backdrop-filter: blur(5px);
                    color: $black;
                    max-height: rem(28);
                    min-height: rem(28);
                    max-width: rem(73);
                    font-size: rem(10);
                    font-weight: 700;
                    line-height: rem(20);
                    display: flex;
                    justify-content: center;
                    padding: 0;
                }
            }

            &-more {
                display: none;
            }
        }
    }

    .menu {
        &__footer {
            &__item {
                &__text {
                    font-size: rem(12);
                }
            }
        }
    }

    .auth__content {
        &_left {
            max-width: 100%;

            &_block {
                justify-content: center;

                &.activate {
                    .auth__content_left_block {
                        &_header {
                            display: none;
                        }

                        &_content {
                            .field__number {
                                padding-bottom: rem(40);

                                &__content, &__fields {
                                    text-align: center;

                                    &-right {
                                        margin: auto;
                                    }
                                }
                            }
                        }
                    }
                }

                &_header {
                    align-self: center;
                    margin: rem(40) 0 rem(20);

                    &.register {
                        text-align: center;
                        margin-bottom: rem(20);
                    }
                }

                &_footer {
                    margin: rem(40) 0 0;
                }
            }
        }

        &_right {
            display: none;
        }
    }

    .auth__login {
        &__form {
            .btn {
                margin-top: rem(5);
            }
        }
    }

    .auth__steps {
        width: 100%;
        left: 0;
        top: rem(32 - 17);
        padding: rem(17);
        text-align: center;
        justify-content: center;

        &-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: rem(17);
        }

        &-text {
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 935px) {
}

@media screen and (max-width: 895px) {
}

@media screen and (max-width: 875px) {
}

@media screen and (max-width: 865px) {
}

@media screen and (max-width: 835px) {
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 620px) {
    .vacation {
        &__content {
            &__left {
                &__calendar {
                    position: relative;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    border: none;

                    &-border {
                        border: none;
                    }

                    &-head {
                        background-color: rgba(0, 0, 0, 0.03);
                        padding: rem(6) rem(10);
                    }

                    &-count {
                        color: $black;
                        background-color: $white;
                    }
                }
            }
        }
    }

    .accordion {
        &__info {
            position: relative;
            width: 100%;
            padding-right: rem(90);
        }

        &__status {
            position: absolute;
            top: 0;
            right: 0;
            background-color: transparent;
            padding: 0;
            margin: 0;
        }

        &__content {
            gap: rem(10);
            padding: 0;
        }

        &__departament {
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(22);

        }

        &__item {
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(22);

            span {
                font-size: rem(14);
            }
        }

        &__buttons {
            gap: rem(16);
        }

        &__button {
            height: rem(50);
            font-size: rem(16);
        }
    }
}

@media screen and (max-width: 600px) {
    .home {
        &__header-url {
            .btn {
                font-size: rem(12);
            }
        }
    }
}

@media screen and (max-width: 575px) {
}

@media screen and (max-width: 540px) {
}

@media screen and (max-width: 515px) {
}

@media screen and (max-width: 435px) {
    .home {
        &__swiper {
            img {
                //object-fit: contain;
            }
        }

        &__groups {
            &__content {
                &-items {
                    .swiper-slide {
                        //min-width: rem(118);
                    }
                }

                &-item {
                    &__block {
                        height: calc(100% - 20px);
                        width: calc(100% - 20px);
                        padding: rem(10);
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 390px) {
    .news {
        &__content__items {
            & > div {
                flex: 0 0 60%;
                max-width: 60%;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .header {
        &__right {
            .header {
                &__profile {
                    padding-right: rem(10);

                    &-star {
                        padding: 0;
                    }
                }

                &__other {
                    padding-right: 0;
                }
            }
        }
    }

    .aside {
        &__gratitude__content-block {
            padding: rem(15);
        }
    }
}

@media screen and (max-width: 340px) {
    .news {
        &__content__items {
            & > div {
                flex: 0 0 70%;
                max-width: 70%;
            }
        }
    }
}
