.accordion {
    &__container {
        max-width: 650px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__wrapper {
        transition: background-color 0.2s linear;
        padding: rem(10) rem(15);
        position: relative;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
        }

        &-after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 8px;
        }
    }

    &__question-container {
        min-height: auto;
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &__title {
        display: flex;
        gap: rem(14);
    }

    &__arrow {
        display: flex;
        align-items: center;
        margin-left: auto;
        opacity: 0.3;

        svg {
            transform: rotate(90deg);
        }

        &.active {
            svg {
                transform: rotate(-90deg);
            }
        }
    }

    &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: rem(40);
        width: rem(40);
        font-size: rem(14);
        font-weight: 600;
        line-height: normal;
        background-color: $primary;
        border-radius: 50%;

        & img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
    }

    &__name {
        font-size: rem(14);
        font-weight: 600;
        line-height: 22px;
    }

    &__position {
        font-size: rem(12);
        font-weight: 400;
        line-height: rem(22);
        text-transform: capitalize;
    }

    &__status {
        max-width: fit-content;
        font-size: rem(10);
        font-weight: 500;
        line-height: 18px;
        color: $white;
        background-color: $light_blue;
        border-radius: rem(15);
        padding: rem(3) rem(12);
        margin-top: rem(2);
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: rem(4);
        width: 100%;
        padding: rem(4) rem(28) 0 rem(54);
    }

    &__departament {
        font-size: rem(12);
        font-weight: 400;
        line-height: 20px;
        text-transform: capitalize;
    }

    &__vacation {
        display: flex;
        flex-direction: column;
        gap: rem(4);
    }

    &__item {
        display: flex;
        align-items: center;
        gap: rem(8);
        font-size: rem(10);
        font-weight: 400;
        line-height: rem(20);

        span {
            font-size: rem(12);
            font-weight: 700;
        }
    }

    &__date {
        text-transform: capitalize;
        padding: 0 rem(7);
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: rem(8);
        margin-top: rem(4);
    }

    &__button {
        width: 100%;
        height: rem(42);
        font-weight: 600;
        line-height: rem(24);
        text-transform: none;
        border-radius: rem(8);

    }

    &__action {
        color: $white;
        background-color: $light_blue;
        transition: filter 0.2s linear;

        &:hover {
            background-color: $light_blue;
            filter: brightness(0.9);
        }
    }

    &__cancel {
        background-color: #f2f2f2;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.answer {
    &-container {
        visibility: hidden;
        transition: all 0.2s ease-in-out;
        overflow: hidden;

        &.active {
            visibility: visible;
        }
    }
}