.navigation {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    padding: 0 rem(60);
    z-index: 3;
    background: #fff;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    &__router {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        gap: rem(6);
        padding: rem(10) 0;
        height: 100%;
        cursor: pointer;

        & p {
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(22);
            color: $black;
        }

        & svg {
            width: rem(24);
            height: rem(24);

            & path {
                color: #000;
                opacity: 0.5;
            }
        }
    }

    &__router.active {
        border-radius: 1rem 1rem 0 0;
        background: #f7f7f7;;

        & p {
            font-weight: 600;
            color: #000;
            text-align: center;
            opacity: 1;
        }

        & svg {
            & path {
                color: #00A7B5;
                opacity: 1;
            }
        }
    }
}

.navigation.scrolled {
    position: fixed;
    margin-top: -27.7rem;
    width: 100%;
    height: 3.125rem;

    & .slider-routers__router {
        flex-direction: row;
    }
}