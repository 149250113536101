.contacts {
    position: relative;
    width: 100%;
    height: 100%;

    @media (max-width: 1500px) {
        padding-right: 1.25rem;
    }

    @media (max-width: 768px) {
        height: 100%;
        padding-right: 0;
    }

    &-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        #cities_popup {
            background: #fff;
            color: #000;
            border-radius: 0.5rem;
            width: 12.375rem;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.375rem;
            z-index: 1;

            @media (max-width: 768px) {
                background: none;
                font-size: 0.875rem;
            }

            div {
                opacity: unset;
            }

            svg {
                fill: #5900FF;
            }
        }

        h1 {
            font-weight: 700;
            font-size: 2rem;

            @media (max-width: 768px) {
                font-size: 1.125rem;
            }
        }
    }

    &-content {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, minmax(19.688rem, 1fr));
        width: 100%;
        gap: 1.25rem;
        margin-top: 1rem;

        @media (max-width: 1500px) {
            grid-template-columns: repeat(3, minmax(10.688rem, 1fr));
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(2, minmax(10.688rem, 1fr));
            gap: 0.625rem;
            padding-bottom: 12rem;
        }
    }

    &-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        padding: 1.25rem;
        border-radius: 1rem;
        width: 100%;
        cursor: pointer;

        h3 {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5rem;
            text-align: center;
            margin-top: 1.25rem;

            @media (max-width: 768px) {
                font-size: 0.875rem;
                text-align: center;
            }
        }
    }

    &-buttons {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        height: 2.5rem;
    }

    &-addButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        padding: 0.5rem 1.625rem;
        bottom: 1rem;
        height: 100%;
        border-radius: 0.5rem;
        gap: 0.5rem;
        cursor: pointer;

        @media (max-width: 768px) {
            position: absolute;
            height: 3.125rem;
            width: 100%;
        }

        p {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.375rem;
        }

        svg {
            width: 1.5rem;
            height: 1.5rem;

            path {
                stroke: #5900FF;
            }
        }
    }

    &-departmentBody {
        margin-top: 1.25rem;

        h1 {
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
    }

    &-departmentContent {
        display: grid;
        grid-template-columns: repeat(5, minmax(11.875rem, 1fr));
        grid-auto-rows: 1fr;
        grid-gap: 0.625rem;
        margin-top: 1.25rem;
    }

    &-searchButton {
        background: #fff;
        padding: 0.5rem 1.25rem;
        width: 16rem;
        height: 2.5rem;
        border: none;
        border-radius: 0.5rem;
    }
}