.calendar-block {
    width: 100%;
    height: 100%;

    &__icon {
        position: absolute;
        bottom: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(60);
        z-index: 2;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }

    &__date-picker {
        position: relative;
    }

    .fc {
        position: relative;

        &:before, &:after {
            content: '';
            position: absolute;
            top: 84px;
            bottom: 0;
            background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            max-width: 69px;
            width: 100%;
            z-index: 5;
        }

        &:before {
            left: 0;
            /*display: none;*/
        }

        &:after {
            right: 0;
            transform: rotate(180deg);
        }

        .fc-timegrid-col-events:hover {
            z-index: 5;
        }

        .fc-timegrid-event-harness:hover {
            z-index: 5 !important;
        }

        .fc-timegrid-slot-label {
            border-color: white;
        }
    }

    .fc-col-header-cell-cushion {
        padding: 0;
    }

    .fc-timegrid-slot-label {
        span {
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            opacity: 0;
        }
    }

    .fc-view-harness {
        overflow: auto;
        scrollbar-color: #3583FF #F4F4F4;
    }

    .fc-timegrid {
        width: 4440px;
        overflow: hidden;
    }

    .react-datepicker__navigation-icon {
        top: 0;
    }

    .fc-header-toolbar {
        margin: 26px 20px;

        .fc-prev-button, .fc-next-button {
            background-color: white;
            color: $primary;
            border: 0;
            padding: 6px;
            max-height: 36px;
            display: flex;
            align-items: center;
        }

        .fc-toolbar-title {
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            color: $primary;
            text-transform: capitalize;
            margin: 0 20px;
        }
    }

    .fc-toolbar-chunk {
        div {
            display: flex;
            align-items: center;
        }
    }

    .fc-timegrid-axis-cushion, .fc-timegrid-slot-label-cushion {
        /*padding: 8px;*/
        text-align: start;
    }

    .fc-col-header-cell-cushion {
        font-size: 14px;
        font-weight: 500;
        width: 100%;
    }

    .fc-timelineDay-view {
        padding-left: 15px;
    }

    .fc-v-event .fc-event-main-frame {
        display: none;
    }

    .fc-event {
        border-radius: 8px;
        cursor: pointer;
        padding: 0;
        border: none;
        /*height: 135px;*/
    }

    .fc-timegrid-event-harness {
        background-color: white;
        margin: 1.5px 3px;
        border-radius: 8px;
    }

    .fc-direction-ltr .fc-timegrid-slot-label-frame {
        height: 98px;
    }

    .fc-v-event .fc-event-main {
        color: black;
        /*background-color: white;*/
    }

    .fc-timeline-event {
        margin: 5px !important;

        .fc-event-main {
            height: 135px;
        }
    }

    .fc-direction-ltr .fc-timegrid-col-events {
        margin: 0;
    }

    .fc-timegrid-col-frame {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 0;
    }

    .fc-day-mon .fc-timegrid-col-frame {
        border-left: 0;
    }

    .fc-day-sat .fc-timegrid-col-frame {
        border-radius: 0;
    }

    .fc-day-sun .fc-timegrid-col-frame {
        background-color: rgba(0, 0, 0, 0.03);
        border-left: 0;
        border-radius: 0;
    }

    .fc-timeline-header {
        margin-bottom: 10px;
    }

    .fc-timeline-slot {
        border: 0 !important;

        & + th div {
            margin-left: -17px;
        }
    }

    .fc-timeline-slot-cushion {
        color: #000;
        font-size: 12px;
        font-weight: 600;
        padding: 10px !important;
        padding-left: 0 !important;
    }

    & table {
        border: 0 !important;
    }

    .fc-event-past {
        opacity: 0.5;
    }

    & td, & th {
        border-top-color: transparent;
        border-bottom-color: transparent;

        .fc-scrollgrid-sync-inner {
            text-align: left;
        }
    }

    .fc-timegrid-col-frame {
        border: 0;
    }

    .fc-timeline-slot-label {
        position: relative;

        .customTimeLine {
            & span {
                display: none;
            }
        }

        &:last-child {
            .customTimeLine {
                & span {
                    position: absolute;
                    display: block;
                    top: 0;
                    right: 4px;
                    padding: 10px 0;
                }
            }
        }
    }

    .fc-timeline-slots .fc-slot-future {
        position: relative;
        border-radius: 8px;
        cursor: pointer;

        &:after {
            transition: all .3s ease;
        }

        &:after {
            content: "+";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: rgba(0, 123, 255, 1);
            font-size: 35px;
            opacity: 0;
        }

        &:hover {
            background: rgba(0, 123, 255, 0.05);

            &:after {
                opacity: 1;
            }
        }
    }

    .fc-timeGridWeek-view {
        .fc-scrollgrid-section-body .fc-timegrid-axis {
            opacity: 0;
            visibility: hidden;
        }

        .fc-col-header {
            margin-bottom: 15px;

            .fc-day-today {
                color: #0071DA;
            }

            .fc-col-header-cell-cushion {
                text-transform: capitalize;
            }
        }

        .fc-event-time {
            font-size: 12px;
            font-weight: 400;
            opacity: .5;
        }

        .fc-event-title {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
        }

        .fc-event {
            height: auto;

            .fc-event-main {
                padding: 0;
            }
        }

        .fc-timegrid-body {
            .fc-timegrid-slot {
                font-size: 10px;
                font-weight: 500;
                height: 30px;
                margin-bottom: 30px;
            }

            & tr:nth-last-child(2) {
                .fc-timegrid-slot {
                    .customTimeLine {
                        & span {
                            position: absolute;
                            display: block;
                            bottom: -40px;
                            right: 0;
                            padding: 0 4px;
                        }
                    }
                }
            }
        }

        .fc-timegrid-col.fc-day-today {
            background: transparent;
        }
    }

    .fc-highlight {
        background-color: transparent;
    }

    .fc-timegrid-slot {
        position: relative;
        border-radius: 8px;
        cursor: pointer;

        &:after {
            transition: all .3s ease;
        }

        /*&:after {*/
        /*    content: "+";*/
        /*    position: absolute;*/
        /*    left: 50%;*/
        /*    top: 50%;*/
        /*    transform: translate(-50%, -50%);*/
        /*    color: rgba(0, 123, 255, 1);*/
        /*    font-size: 32px;*/
        /*    opacity: 0;*/
        /*}*/

        /*&:hover {*/
        /*    background: rgba(0, 123, 255, 0.05);*/

        /*    &:after {*/
        /*        opacity: 1;*/
        /*    }*/
        /*}*/

        .customTimeLine {
            & span {
                display: none;
            }
        }
    }

    .calendar-block__date-picker {
        .react-datepicker__input-container {
            position: inherit;
        }

        .react-datepicker-popper {
            right: 0;
            z-index: 10;
        }

        .react-datepicker__triangle {
            display: none;
        }

        .react-datepicker__header {
            background-color: white;
            padding: 0;
            width: 100%;
        }

        .react-datepicker__day-names {
            border-top: 1px solid #D7DCE4;
            margin: 0;
            width: 100%;
            display: flex;
            justify-content: space-around;
        }

        .react-datepicker__day, .react-datepicker__day-name {
            width: 100%;
            border-radius: 4px;
            padding: 4px 0;
            text-transform: capitalize;
        }

        .react-datepicker__day--keyboard-selected {
            background-color: $primary;
            color: $white;
        }

        .react-datepicker__month-container, .react-datepicker__week {
            width: 100%;
        }

        .react-datepicker__week {
            display: flex;
            justify-content: space-around;
        }

        .react-datepicker,
        .react-datepicker__header,
        .react-datepicker__month {
            border-color: #D7DCE4;
            width: 100%;
            margin: 0;
        }

        .react-datepicker__month {
            padding: 0.4rem;
        }

        .react-datepicker__year-read-view,
        .react-datepicker__month-read-view,
        .react-datepicker__month-year-read-view {
            visibility: visible !important;
        }

        .react-datepicker__day--today {
            background-color: #3583FF;
            color: white;
        }

        .react-datepicker__current-month,
        .react-datepicker__month-dropdown-container,
        .react-datepicker__year-dropdown-container {
            text-transform: capitalize;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            color: $primary;
        }

        .react-datepicker__current-month {
            padding: 11px;
        }

        .react-datepicker__month-dropdown-container,
        .react-datepicker__year-dropdown-container {
            padding: 11px 0;
            margin: 0 2px;
        }

        .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
            top: 6px;
        }

        .react-datepicker__navigation--previous {
            left: 50px;
        }

        .react-datepicker__navigation--next {
            right: 50px;
        }

        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__year-read-view--down-arrow {
            visibility: hidden;
        }

        .react-datepicker__navigation-icon::before {
            border-color: $primary;
            border-width: 2px 2px 0 0;
        }

        .react-datepicker__day--weekend {
            background-color: #EF3B240D;
            color: #EF3B24;
        }

        .react-datepicker__day-name:last-of-type, .react-datepicker__day-name:nth-child(6) {
            color: #EF3B24;
        }
    }

    .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
        display: none;
    }
}

@media (max-width: 767px) {
    .calendar-block {
        .fc-header-toolbar {
            margin: 20px 43px;

            .fc-toolbar-title {
                margin: 0 auto;
            }
        }

        .fc-toolbar-chunk:first-of-type {
            width: 100%;
        }

        .calendar-block__date-picker {
            .react-datepicker__navigation--previous {
                left: 83px;
            }

            .react-datepicker__navigation--next {
                right: 83px;
            }

            .react-datepicker, .react-datepicker__month-container,
            .react-datepicker__day, .react-datepicker__day-name  {
                width: 100%;
            }

            .react-datepicker__day-names, .react-datepicker__week {
                display: flex;
                justify-content: space-evenly;
            }
        }
    }
}