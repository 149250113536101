main {
    min-height: 100vh;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
}


.slider {
    margin-top: rem(68);
    height: 100%;
    color: #fff;

    &-bannersContent {
        padding: 1.25rem 3.75rem;

        @media (max-width: 1020px) {
            padding: rem(10) rem(15) rem(0) rem(15);
        }
    }

    &-banners {
        position: relative;
        height: 25.125rem;
        user-select: none;
        border-radius: 0.5rem;
        cursor: pointer;
        color: #fff;

        @media (max-width: 1020px) {
            height: rem(150);
        }


        &__linear {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%);
            z-index: 1;
        }

        &__content {
            position: absolute;
            bottom: rem(40);
            left: rem(50);
            z-index: 2;
            color: #fff;

            @media (max-width: 1020px) {
                width: 100%;
                text-align: center;
                bottom: unset;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }


            & h1 {
                font-size: rem(40);
                font-weight: 700;
                opacity: 1;
                color: #fff;
                z-index: 99;


                @media (max-width: 1020px) {
                    font-size: rem(16);
                }
            }

            & p {
                font-weight: 600;
                font-size: rem(20);
                color: #fff;

                @media (max-width: 1020px) {
                    font-size: rem(12);
                }
            }
        }

        .fade-enter {
            opacity: 0;
        }

        .fade-enter-active {
            opacity: 1;
            transition: opacity 0.5s;
        }

        .fade-exit {
            opacity: 1;
        }

        .fade-exit-active {
            opacity: 0;
            transition: opacity 0.5s;
        }

        .removeBackFace {
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            transform: translate3d(0, 0, 0);
            object-fit: cover;
        }

        &__typeContent {
            display: flex;
            align-items: center;
            gap: 0.375rem;
            justify-content: center;
            position: absolute;
            font-weight: 700;
            font-size: 1.125rem;
            left: 1rem;
            top: 1rem;
            z-index: 1;

            & p {
                color: #FFFFFF;
                font-weight: 700;
                font-size: 0.75rem;
                line-height: 1.25rem;
            }
        }

        &__title {
            position: absolute;
            font-weight: 700;
            font-size: 1.125rem;
            left: 1rem;
            bottom: 2rem;
            color: #FFFFFF;
            z-index: 1;
        }

        &__dotContainer {
            position: absolute;
            left: 1rem;
            /* top: 140px; */
            bottom: 1rem;
            display: flex;
            z-index: 5;

            @media (max-width: 1020px) {
                width: fit-content;
                left: 50% !important;
                transform: translateX(-50%);
            }
        }

        &__dot {
            width: 0.5rem;
            height: 0.5rem;
            margin-right: 0.5rem;
            border-radius: 50%;
            cursor: pointer;
            opacity: 0.5;
        }

        &__backgroundContent {
            width: 100%;
            position: absolute;
            height: 100%;
            z-index: 2;
            border-radius: .5rem;
            background: linear-gradient(360deg, rgba(0, 62, 128, 0.8) 0%, rgba(0, 62, 128, 0.2) 100%);

        }

        &__container {
            & img {
                max-width: 100%;
                width: 100%;
                border-radius: 0.5rem;
                height: 100%;
                object-fit: cover;
                transition: .5s all ease !important
            }
        }

        &__innerContainer {
            width: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            transform: translate3d(0, 0, 0);

            & div {
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
        }
    }

    &-routers {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 0;
        height: 5.375rem;
        padding: 0 0 0 3.75rem;
        z-index: 3;
        background: #fff;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        &__router {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 0.375rem;
            padding: 0 0.625rem;
            width: 11.75rem;
            height: 100%;
            cursor: pointer;

            & p {
                font-weight: 500;
                font-size: rem(14);
                opacity: .8;
                color: #000;
                text-wrap: normal;
            }

            & svg {
                width: rem(24);
                height: rem(24);

                & path {
                    color: #000;
                    opacity: 0.5;
                }
            }
        }

        &__router.active {
            border-radius: 1rem 1rem 0 0;
            background: #f7f7f7;;

            & p {
                font-weight: 600;
                color: #000;
                text-align: center;
                opacity: 1;
            }

            & svg {
                & path {
                    color: #00A7B5;
                    opacity: 1;
                }
            }
        }
    }

    &-routers.scrolled {
        position: fixed;
        margin-top: -27.7rem;
        width: 100%;
        height: 3.125rem;

        & .slider-routers__router {
            flex-direction: row;
        }
    }

}