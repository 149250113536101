.toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: rem(1) solid rgba(255, 255, 255, 0.01);
    background: rgba(255, 255, 255, 0.05);
    border-radius: rem(4);

    &-second {
        &-right {
            display: flex;
            align-items: center;
            gap: rem(10);

            &__item {
                display: flex;
                align-items: center;
                gap: rem(8);
            }
        }
    }

    &__block {
        p {
            font-size: rem(16);
            font-weight: 700;
        }
    }

    &__text {
        color: $black;
        font-size: rem(16);
        font-weight: 600;
    }

    &__btn {
        position: relative;
        width: rem(36);
        height: rem(20);
        border-radius: rem(100);
        transition: all 0.2s ease-in-out;
        background: #00000026;
        cursor: pointer;

        &.active {
            background-color: $primary;

            span {
                left: calc(100% - 2.5px - 15px);
            }
        }

        span {
            border-radius: 100%;
            background-color: $white;
            position: absolute;
            top: rem(2.5);
            left: rem(2.5);
            transition: all 0.2s ease-in-out;
            height: rem(15);
            width: rem(15);
        }
    }
}