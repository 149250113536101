.aside {
	&__left, &__right {
		//& > div {
		//	margin-bottom: rem(20);
		//	background-color: $white;
		//}

		&__col {
			padding-left: rem(15);
			padding-right: rem(15);
			max-width: rem(377);
			width: 100%;
		}
	}

	&__left {
		& > div {
			border-radius: rem(8);
			overflow: hidden;

			.aside__banner {
				display: block;
				width: 100%;

				img {
					width: 100%;
				}
			}
		}

		&__col {
			padding-left: rem(15);
			padding-right: rem(15);
			max-width: rem(377);
		}
	}

	&__right {
		& > div {
			border-radius: rem(8);
			//overflow: hidden;
		}
	}

	&__happy {
		position: relative;

		&__header {
			background: linear-gradient(90.07deg, #1A59FB 0.2%, #3381E0 54.38%);
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: rem(8) rem(20);
			position: relative;
			border-radius: rem(8) rem(8) 0 0;

			&-left {
				h4, p {
					color: $white;
				}

				h4 {
					font-size: rem(24);
					font-weight: 700;
					line-height: rem(34);
					margin-bottom: rem(2);
				}

				p {
					font-size: rem(14);
					font-weight: 600;
					line-height: rem(24);
				}
			}

			&-right {
				position: absolute;
				right: 0;
				bottom: 0;

				img {
					min-width: rem(68);
					max-width: rem(68);
				}
			}
		}

		&__content {
			&__items {
				padding: rem(10) 0;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
			}

			&__swiper {
				position: relative;
				//margin: rem(10) rem(9.5);
			}

			&__item {
				padding: rem(10) rem(9.5);
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			&__block {
				display: flex;
				flex-wrap: wrap;
				margin-left: 0;
				margin-right: 0;
			}

			&__item {
				display: flex;
				flex-wrap: wrap;
				//flex: 0 0 25%;
				//max-width: 25%;
				//padding-left: 0;
				//padding-right: 0;
				cursor: pointer;
			}

			&__icon {
				background-color: #CCD9E0;
				border-radius: 50%;
				overflow: hidden;
				max-height: rem(54);
				max-width: rem(54);
				min-width: rem(54);

				img {
					max-height: rem(54);
					max-width: rem(54);
					min-width: rem(54);
				}
			}

			&__text {
				padding-top: rem(8);

				p {
					font-size: rem(12);
					font-weight: 500;
				}
			}
		}
	}

	&__happy, &__gratitude, &__urls {
		position: relative;
		background-color: $white;
		margin-bottom: rem(20);
		border-radius: rem(8);
	}

	&__gratitude, &__urls {
		&__header {
			padding: rem(20);

			p {
				font-size: rem(20);
				font-weight: 700;
			}
		}
	}

	&__gratitude {
		&__header, &__content-block {
			box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__content-block {
			cursor: pointer;
			padding: rem(15) rem(20);
			width: 100%;
		}

		&__content {
			&-item {
				display: flex;
				align-items: center;
				padding: 0;
			}

			&-profile {
				//background-color: #CCD9E0;
				border-radius: 50%;
				overflow: hidden;
				display: block;
				min-width: rem(60);
				max-width: rem(60);

				img {
					min-width: rem(60);
					max-width: rem(60);
					max-height: rem(60);
				}
			}

			&-text {
				padding: 0 rem(15);

				span {
					font-size: rem(16);
					font-weight: 500;
					line-height: rem(24);
				}
			}

			&-icon {
				padding: rem(6);
				border-radius: 50%;
				overflow: hidden;
				background-color: #FAFAFA;
				min-width: rem(42);
				max-width: rem(42);
				max-height: rem(42);
				min-height: rem(42);
				display: block;

				img {
					min-width: rem(30);
					max-width: rem(30);
					max-height: rem(30);
					border-radius: 50%;
					overflow: hidden;
				}
			}

			&-btn {
				padding: rem(15) rem(20);

				.btn {
					font-size: rem(16);
					font-weight: 600;
					background-color: rgba(0, 0, 0, 0.05);
					max-width: rem(180);
					background-color: #F4F7FF;
				}
			}
		}
	}

	&__urls {
		&__content {
			display: flex;
			flex-wrap: wrap;
			padding: 0 rem(5);

			&-item {
				padding-bottom: rem(10);
				flex: 0 0 33.333333%;
				max-width: 33.333333%;
			}

			&-block {
				padding: rem(10);
				width: 100%;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;

				&-item {
					//padding: rem(18);
					background-color: rgba(25, 88, 250, 0.05);
					border-radius: 50%;
					overflow: hidden;
					display: flex;
				}
			}

			&-icon {
				display: flex;

				img {
					min-width: rem(66);
					max-width: rem(66);
					width: 100%;
					min-height: rem(66);
					max-height: rem(66);
				}
			}

			&-text {
				padding-top: rem(10);
				font-size: rem(14);
				font-weight: 600;
			}
		}
	}

	&__questions {
		padding: rem(20);
		background-color: $white;

		&__icon {
			padding-bottom: rem(5);

			img {
				max-height: rem(54);
				max-width: rem(54);
				min-width: rem(54);
			}
		}

		&__title {
			font-size: rem(20);
			font-weight: 700;
			line-height: rem(30);
			margin-bottom: rem(20);
		}

		&__items {
			list-style: none;
		}

		&__item {
			padding-bottom: rem(15);
		}
	}

	&__interview {
		padding: rem(20);

		&__icon {
			margin-bottom: rem(5);
		}

		h3 {
			font-size: rem(20);
			max-width: 100%;
			word-break: break-word;
			font-weight: 700;
			line-height: rem(30);
		}

		&__block {
			color: #003166;
			cursor: pointer;
			height: 100%;
			overflow: auto;
			font-size: 18px;
			font-weight: 500;
			line-height: rem(28);
			padding-top: rem(20);

			& > div {
				display: flex;
				align-items: center;
				user-select: none;
				margin-bottom: rem(15);
			}

			&__radio {
				width: rem(20);
				border: rem(1) solid #00316630;
				height: rem(20);
				position: relative;
				min-width: rem(20);
				margin-right: rem(8);
				border-radius: 50%;

				&:after {
					top: 50%;
					left: 50%;
					width: rem(16);
					height: rem(16);
					content: "";
					opacity: 0;
					position: absolute;
					transform: translate(-50%, -50%);
					transition: 0.2s;
					background-color: #007AFF;
					border-radius: 50%;
				}

				&__selected {
					&:after {
						opacity: 1;
					}
				}
			}

			&__text {
				border: none;
				outline: none;
				position: relative;
				font-size: rem(14);
				font-weight: 600;
				margin-bottom: rem(20);
			}
		}
	}

	&__container__popup {
		position: absolute;
		top: rem(50);
		right: rem(-20);
		color: #000000;
		opacity: 0;
		padding: rem(16);
		z-index: -1;
		font-size: rem(15);
		min-width: fit-content;
		box-shadow: 0 4px 50px rgba(0, 49, 102, 0.16);
		max-height: calc(100vh - 200px);
		overflow-x: hidden;
		overflow-y: auto;
		transition: opacity 0.2s;
		word-break: keep-all;
		font-weight: 600;
		line-height: 1;
		white-space: nowrap;
		border-radius: 15px;
		background-color: #ffffff;

		&.active {
			opacity: 1;
			z-index: 99;
		}
	}
}

.advert {
	&__first {
		display: block;
		background-color: $white;
		overflow: hidden;
		border-radius: rem(8);
		margin-bottom: rem(20);
		position: relative;

		&__img {
			display: block;
			position: relative;

			&__bottom {
				position: absolute;
				top: 80%;
				left: 0;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 rem(16);

				&-logo {
					border-radius: 50%;
					background-color: $white;
					box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
					padding: rem(7);
				}

				&-btn {
					.btn {
						font-size: rem(16);
						padding: rem(6) rem(12);
						border-radius: rem(100);
						min-height: rem(38);
						max-height: rem(38);
					}
				}
			}
		}

		&__info {
			display: block;
			padding: rem(35) rem(20) rem(20);

			&-title {
				p {
					font-size: rem(18);
					font-weight: 700;
					line-height: rem(26);
					margin-bottom: rem(5);
				}
			}

			&-text {
				p {
					font-size: rem(16);
					font-weight: 500;
					line-height: rem(24);
					margin-bottom: rem(15);
				}
			}

			&-btn {
				.btn {
					font-weight: 600;
					line-height: rem(26);
					color: $black;
					max-width: rem(180);
					background-color: #FAFAFA;
				}
			}
		}
	}

	&__second {
		display: block;
		width: 100%;
		min-height: rem(277);
		position: relative;
		z-index: 0;
		border-radius: rem(8);
		overflow: hidden;

		&.text-black {
			h3 {
				color: $black;
			}
		}

		&.no-fon {
			background-color: #EEF1FA;

			.advert__second__block {
				&:before {
					content: none;
				}
			}
		}

		&-img {
			display: block;

			img {
				width: 100%;
			}
		}

		&__block {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			padding: rem(20);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			width: 100%;
			background-color: rgba(0, 0, 0, 0.2);

			&:before {
				content: '';
				z-index: -1;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.2)
			}
		}

		&-text {
			max-width: 80%;

			h3 {
				color: $white;
				font-size: rem(36);
				font-weight: 700;
			}
		}

		&-btn {
			padding-top: rem(5);

			.btn {
				cursor: pointer;
				font-size: rem(16);
				font-weight: 600;
				max-width: rem(167);
				width: 100%;
			}
		}
	}
}

.sidebar {
	&__container {
		color: #003166;
		width: 100%;
		padding: 0;
		position: relative;
		min-height: rem(102);
		overflow-x: hidden;
		overflow-y: hidden;
		font-weight: 500;
		border-radius: rem(8);
		margin-bottom: rem(20);
		background-color: #FFFFFF;
		cursor: pointer;

		.message {
			top: 50%;
			left: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
			white-space: pre;
			font-size: rem(14);
		}

		img {
			display: block;
			max-width: 100%;
			width: 100%;
			border-radius: rem(8);
		}

		&:hover {
			.setting__icon {
				opacity: 1;
				background-color: $white;
			}
		}
	}

	&__block {
		&__button {
			&.btn {
				left: rem(20);
				color: #FFFFFF;
				width: rem(180);
				bottom: rem(26);
				height: rem(50);
				display: flex;
				padding: rem(12) rem(30);
				position: absolute;
				font-size: rem(16);
				background: #007AFF;
				text-align: center;
				transition: all 0.3s ease;
				align-items: center;
				font-weight: 600;
				border-radius: rem(8);
				justify-content: center;
			}
		}
	}

	&__draggable {
		&__block {
			top: rem(10);
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			cursor: grab;
		}
	}

	&__banner {
		&__link {
			padding-top: rem(10);
			padding-bottom: rem(30);

			p {
				opacity: 0.53;
			}
		}

		&__btn {
			width: 100%;
			margin-bottom: rem(18);
		}
	}

	&__interview {
		.btn {
			&-link {
				height: auto;
				min-height: auto;
				max-height: none;
			}
		}
	}
}

.sidebar-user {
	&-container {
		width: rem(298);
		display: flex;
		text-align: left;
		flex-direction: column;
		justify-content: space-between;
		color: $blueColor;
		//	[breakpoints.down('sm')]: {
		//	width: 100%;
		//}
	}

	&-name {
		font-size: rem(14);
		word-break: break-word;
		font-weight: 600;
		white-space: break-spaces;
		margin-bottom: rem(10);
	}

	&-fields {
		color: $blueColor;
		overflow: hidden;
		font-size: rem(14);
		max-width: 100%;
		font-weight: 500;
		margin-bottom:rem(8);
		overflow-wrap: anywhere;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;

		&-text {
			display: -webkit-box;
			overflow: hidden;
			line-height: 1.3;
			white-space: break-spaces;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
		}
	}
}

































