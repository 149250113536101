.contests {
    &__create {
        background-color: $white;
        padding-bottom: rem(70);

        &__header {
            padding-top: rem(10);

            h5 {
                font-size: rem(18);
                font-weight: 700;
                line-height: rem(21.94);
                color: #E1BD00;
            }
        }

        &__block {
            padding: rem(30) 0;

            &-left {
                width: 100%;
                margin-right: rem(120);
                padding: 0 rem(10);
            }

            &-right {
                max-width: rem(438);
                width: 100%;
                padding: 0 rem(10);
            }
        }

        &__row {
            display: flex;
            flex-wrap: nowrap;
        }

        &__form {
            display: flex;
            flex-direction: column;
            gap: rem(20);

            &-date {
                display: flex;
                gap: rem(20);
            }

            &-toggle {
                justify-content: left;
                gap: rem(15);
            }
        }

        &__preview {
            display: flex;
            flex-direction: column;
            gap: rem(15);

            .contests__create__preview-items {
                padding-bottom: rem(10);
            }

            &-img {
                position: relative;

                img {
                    width: 100%;
                }

                div {
                    bottom: rem(20);
                    right: rem(20);
                }
            }

            &-items {
                border-radius: rem(16);
                background: rgba(0, 0, 0, 0.03);
                overflow: hidden;
            }

            &-item {
                padding: rem(20) rem(20) rem(10);
                gap: rem(20);

                &__header {
                    margin-bottom: rem(20);
                    h3 {
                        font-size: rem(24);
                        font-weight: 700;
                        line-height: rem(29);
                        margin-bottom: 0;
                    }

                    .btn {
                        padding: 0;
                        min-height: auto;
                        min-width: rem(24);
                    }

                    &-category {
                        padding-top: rem(10);
                        display: flex;

                        &-item {
                            padding: rem(4) rem(16) rem(4) rem(16);
                            gap: rem(5);
                            border-radius: rem(100);
                            background: #00A7B50D;
                            color: #00A7B5;
                            font-size: rem(12);
                            font-weight: 600;
                            line-height: rem(24);
                        }
                    }
                }

                &__content {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: rem(24);

                    &__text {
                        margin-bottom: rem(20);
                    }
                }

                &__awards {
                    h4 {
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: rem(24);
                        margin-bottom: rem(15);
                    }

                    .block {
                        display: flex;
                        flex-direction: column;
                        gap: rem(5);
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: rem(28);

                        svg {
                            min-width: rem(24);
                        }

                        .title {
                            font-size: rem(14);
                            font-weight: 500;
                            line-height: rem(24);
                        }

                        p {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: block;
                            font-weight: 700;
                            line-height: rem(28);
                        }
                    }
                }

                &__footer {
                    padding-top: rem(20);
                    display: flex;
                    justify-content: space-between;

                    .date {
                        p {
                            font-weight: 500;
                            opacity: 0.5;
                        }
                    }

                    .date, .view {
                        display: flex;
                        align-items: center;

                        p {
                            font-size: rem(12);
                            line-height: rem(20);
                        }
                    }

                    .date, .view div {
                        gap: rem(6);
                    }

                    .view {
                        gap: rem(15);

                        p {
                            font-weight: 600;
                        }
                    }
                }

                &__btns {
                    margin-top: rem(20);
                    padding-top: rem(20);
                    border-top: rem(1) solid #0000001A;
                }
            }
        }
    }

    &__view {
        &__close {
            position: absolute;
            right: rem(20);
            top: rem(20);
            display: flex;
            align-items: center;
            cursor: pointer;
            z-index: 1;
        }

        .contests__create__preview-items {
            border-radius: 0;
        }

        &__btn {
            &__application {
                padding: rem(20) rem(20) rem(10);
                background-color: rgba(0, 0, 0, 0.03);
                border-top: rem(1) solid #0000001A;
            }

            &__count {
                border-radius: 50%;
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(26);
                color: #FF0000;
                background-color: $white;
                max-width: rem(24);
                min-width: rem(24);
                max-height: rem(24);
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__btns {
            display: flex;
            padding: 0 rem(20) rem(20);
            background-color: rgba(0, 0, 0, 0.03);
        }

        &__block {
            h4 {
                font-size: rem(24);
                font-weight: 700;
                line-height: rem(29.26);
                margin-bottom: rem(20);
            }

            form {
                display: flex;
                flex-direction: column;
                padding-bottom: rem(10);
            }

            .form-group {
                margin-bottom: rem(20);
            }
        }

        &__application {
            &__content {
                padding: 0 rem(20);

                .label {
                    margin-bottom: 0;
                }
            }

            &__btn {
                display: flex;
                flex-direction: column;
                gap: rem(10);
                padding: 0 rem(20) rem(20);

                .btn + .btn {
                    margin-left: 0;
                }
            }
        }
    }

    &__success {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: rem(10);
        text-align: center;

        img {
            max-width: rem(60);
        }

        &__text {
            padding: rem(20) 0;

            h4 {
                font-size: rem(22);
                font-weight: 700;
                line-height: rem(24);
                margin-bottom: rem(8);
            }

            p {
                font-size: rem(14);
                font-weight: 500;
                line-height: rem(17);
            }
        }
    }

    &__winner {
        padding: rem(20);

        &__header {
            display: flex;
            align-items: center;
            gap: rem(8);
            padding-bottom: rem(20);

            .back {
                display: flex;
                cursor: pointer;
            }

            h3 {
                font-size: rem(20);
                font-weight: 600;
                line-height: rem(26);
                margin-right: rem(24 + 20);
            }
        }

        &__search {
            padding-bottom: rem(10);
        }

        &__list {

        }

        &__btns {
            padding-top: rem(20);
            border-top: rem(1) solid #0000001A;

            .btn + .btn {
                margin-left: 0;
                margin-top: rem(10);
            }
        }
    }

    &__win {
        &__list {
            border-radius: rem(20);
            padding: rem(10);
            background: #0000000D;
            gap: rem(10);
            display: flex;
            flex-direction: column;

            .layout-vacation-modal__block {
                background: #FFFFFF;
                padding: rem(10);
                border-radius: rem(16);

                &__right {
                    align-items: flex-start;
                }
            }
        }

        &__price {
            margin: rem(20) 0;
            padding: rem(20);
            display: flex;
            align-items: center;
            justify-content: center;
            background: #00000008;
            gap: rem(20);
            border-radius: rem(8);

            p {
                font-size: rem(20);
                font-weight: 700;
                line-height: rem(28);
            }
        }

        &__btns {
            border-top: 0;
            padding-top: rem(30);
        }
    }

    &__exclude {
        &__message {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: rem(10);
            text-align: center;

            p {
                font-size: rem(14);
                font-weight: 500;
                line-height: rem(17.07);
                opacity: 0.6;
            }
        }

        &__comment {
            padding-top: rem(10);
        }
    }

    &__completed {
        &__content {
            background-color: rgba(0, 0, 0, 0.03);
            padding: rem(10) rem(20) rem(20);

            h4 {
                font-size: rem(18);
                font-weight: 700;
                line-height: rem(24);
                margin-bottom: rem(10);
            }
        }

        &__list {
            .layout-vacation-modal__block {
                padding: rem(10);
            }

            &__win {
                padding: rem(10);
                background: #00A7B51A;
                border-radius: rem(20);
                gap: rem(10);
                display: flex;
                flex-direction: column;

                .layout-vacation {
                    &-modal__block {
                        flex-direction: column;
                        background-color: $white;
                        border-radius: rem(20);

                        &__right {
                            align-items: flex-start;
                        }
                    }
                }
            }
        }

        &__reason {
            background: #00000008;
            border-radius: rem(10);
            display: flex;
            flex-direction: column;
            gap: rem(8);
            padding: rem(10);

            h5 {
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(16);
            }

            p {
                font-size: rem(12);
                font-weight: 500;
                line-height: rem(16);
                opacity: 0.6;
            }
        }
    }

    &__application {
        &__dropdown {
            min-width: rem(313);
        }

        &__search {
            min-width: rem(315);

            .field__holder {
                background: $white;
                border-radius: rem(36);

                &:hover {
                    background: $white;
                }
            }

            .form-control {
                border-radius: rem(36);
            }
        }

        &__card {
            display: flex;
            flex-direction: column;
            padding: rem(20);
            gap: rem(20);
            border-radius: rem(16);
            background-color: $white;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                &-left {
                    display: flex;
                    gap: rem(20);

                    img {
                        max-width: rem(60);
                        width: 100%;
                        max-height: rem(60);
                        min-height: rem(60);
                        height: 100%;
                        border-radius: rem(10);
                        object-fit: cover;
                    }

                    &-text {
                        h4 {
                            font-size: rem(16);
                            font-weight: 600;
                            line-height: rem(20);
                        }

                        p {
                            font-size: rem(14);
                            font-weight: 500;
                            line-height: rem(22);
                            opacity: 0.5;
                        }
                    }
                }

                &-right {
                    padding: rem(3) rem(12);
                    border-radius: rem(15);

                    &.new {
                        background: #F53F3F;
                        color: $white;
                    }

                    &.accept {
                        background: #0000000D;
                    }

                    &.reject {
                        background: #0000000D;
                    }

                    p {
                        font-size: rem(12);
                        font-weight: 500;
                        line-height: rem(18);
                    }
                }
            }

            &__content {
                position: relative;

                &.slice {
                    &:after {
                        content: "";
                        z-index: 1;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #00000000 39.9%, #FFFFFF 100%);
                    }

                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -moz-box;
                        -moz-box-orient: vertical;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        line-clamp: 4;
                        box-orient: vertical;
                    }
                }

                p {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: rem(24);
                }
            }
        }
    }
}