
.search__block {
	position: relative;

	&-field {
		position: relative;

		.form-icon-left {
			img {
				max-width: rem(25);
				max-height: rem(25);
				border-radius: 50%;
			}
		}
	}

	&__clear {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		padding: rem(14);
		z-index: 2;

		.btn {
			cursor: pointer;
		}
	}

	&-textField {
		border-radius: 100px;
		overflow: hidden;

		.form-control {
			min-height: rem(44);
			height: rem(44);
			background-color: #0000000D;
			padding: rem(10);
			border-radius: 100px;
		}
	}

	&-dialog {
		position: absolute;
		top: calc(100%);
		left: 0;
		width: 100%;
		height: rem(400);
		z-index: 1;
		padding-bottom: rem(16);
		overflow: auto;

	}

	&-items {
		padding: 0;
		background-color: $white;
	}

	&-item {
		display: flex;
		align-items: center;
		padding: rem(13) rem(14);
		border-top: 1px solid #dedede;
		cursor: pointer;

		&:hover, &:focus {
			background: #dedede;
		}

		p {
			color: $black;
			font-size: rem(14);
			font-weight: 400;
			line-height: rem(22);
			margin-left: rem(14);
			word-break: break-word;
		}
	}

	&-img {
		display: flex;
		border-radius: 50%;
		max-width: rem(32);
		min-width: rem(32);
		max-height: rem(32);
		min-height: rem(32);
	}
}










































