.breadcrumbs {
    display: flex;
    align-items: center;
    gap: rem(10);
    padding-top: rem(10);
    padding-left: rem(60);

    &__link {
        display: flex;
        align-items: center;
        gap: rem(10);
        font-size: rem(14);
        font-weight: 500;
        line-height: rem(22);
        opacity: 0.5;

        & p {
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(22);
        }
    }

    &__link.active {
        display: flex;
        align-items: center;
        gap: rem(10);
        font-size: rem(14);
        font-weight: 500;
        line-height: rem(22);
        opacity: 1;

        p {
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(22);
        }
    }
}