.form {
	&-label {
		display: block;
		color: $black;
		opacity: 0.6;
		position: relative;
		z-index: 2;
		font-size: rem(12);
		font-weight: 600;
		margin-bottom: rem(8);
	}

	&-group {
		width: 100%;
		border-radius: rem(4);
		position: relative;

		&-area {
			.form-control {
				padding-bottom: rem(40);
				height: auto;
			}
		}

		&.searchable {
			.filter-select {
				margin-bottom: 0;

				.react-select-prefix__control {
					cursor: text;
				}
			}
		}

		.filter-select {
			margin-bottom: 0;

			input {
				color: $white !important;
			}
		}

		.classic {
			.field {
				&__holder {
					border: 0;
					border-bottom: rem(1) solid #C6D2E174;
					padding: 0;
				}

				&__label {
					top: 0;
					transform: translateY(0);
				}
			}

			input {
				padding: rem(34) 0 rem(14);
				padding-left: 0 !important;
			}
		}
	}

	&-image {
		position: relative;
		height: 100%;
		width: 100%;
		margin-bottom: rem(20);

		label {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
			z-index: 3;
		}

		img {
			width: 100%;
			height: 100%;
		}

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}

		p {
			color: $black;
			opacity: 0.6;
			margin-bottom: rem(8);
			font-size: rem(12);
			font-weight: 600;
			line-height: rem(20);
		}

		&-block {
			position: relative;
			background: #0000000D;
			height: 100%;
			width: 100%;
			border-radius: rem(8);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			min-height: rem(172);
			max-width: rem(317);

			&.image {
				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: #00000040;
					z-index: 1;
				}
			}
		}
	}

	&-file {
		position: relative;
		height: 100%;
		width: 100%;
		margin-bottom: rem(20);

		label {
			width: 100%;
			height: 100%;
			background: #0000000D;
			cursor: pointer;
			z-index: 3;
			min-height: rem(64);
			max-width: 100%;
			position: relative;
			border-radius: rem(8);
		}

		.form-file-icon {
			p {
				margin-bottom: 0;
			}
		}

		&-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
			display: flex;
			align-items: center;
			gap: rem(10);
		}

		img {
			width: 100%;
			height: 100%;
		}

		p {
			color: $black;
			opacity: 0.6;
			margin-bottom: rem(8);
			font-size: rem(12);
			font-weight: 600;
			line-height: rem(20);
		}

		&-block {
			position: relative;
			height: 100%;
			width: 100%;
			border-radius: rem(8);
			display: flex;
			flex-direction: column;
			overflow: hidden;
		}

		&-name {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: rem(10);

			p {
				margin-bottom: 0;
				opacity: 1;
				font-size: rem(12);
				font-weight: 600;
			}

			.btn-icon {
				padding: 0;
			}
		}
	}

	&-control {
		width: 100%;
		outline: none;
		background-color: transparent;
		color: $black;
		min-height: rem(48);
		padding: rem(12) rem(16);
		display: block;
		caret-color: #10295A;
		font-size: rem(14);
		z-index: 3;
		position: relative;
		border: rem(1) solid transparent;
		border-radius: rem(8);
		transition: all 0.3s ease-in-out;

		&.label {
			padding: rem(24) rem(16) rem(4);

			&.left {
				padding: rem(24) rem(16) rem(4) rem(28 + 20);
			}

			&.right {
				padding: rem(24) rem(28 + 20) rem(4) rem(16);
			}

			&.left.right {
				padding: rem(16) rem(28 + 20) rem(16) rem(28 + 20);
			}
		}

		&.left {
			padding: rem(16) rem(16) rem(16) rem(28 + 20);
		}

		&.right {
			padding: rem(16) rem(28 + 20) rem(16) rem(16);
		}

		&.left.right {
			padding: rem(16) rem(28 + 20) rem(16) rem(28 + 20);
		}

		&::placeholder {
			font-size: rem(14);
			color: #6D7885;
		}

		&:hover,
		&:focus {
			border-color: $primary;
		}

		&:disabled,
		&:hover,
		&:focus{
			background-color: #00000008;
		}

		&:disabled{
			cursor: no-drop;
		}

		&:-webkit-autofill,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:hover {
			-webkit-text-fill-color: $black;
			-webkit-box-shadow: inset 0 0 0 1000px transparent;
			-webkit-transition: background-color 5000s ease-in-out 0s;
			transition: background-color 5000s ease-in-out 0s;
			caret-color: $black;
		}

		&:focus + .field {
			&__holder {
				z-index: 2;

				.field__label {
					top: 0;
					transform: translateY(0);
				}
			}
		}

		&-label {
			display: block;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			color: #6D7885;
			font-size: rem(14);
			transition: 200ms ease-in-out;
			transition-property: top, transform;

			&-wrapper {
				display: block;
				height: 100%;
			}
		}

		&-left-icon {
			padding-left: rem(54);
		}

		&.error {
			border-color: $error;
		}

		&-icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: rem(20);
			display: none;
			align-items: center;

			&.show {
				display: flex;
			}

			&__left {
				right: auto;
				left: rem(20);
			}

			&-second {
				right: rem(60);
			}
		}
	}

	&-datepicker {
		background-color: #00000008;
		padding: rem(8) rem(16);
		transition: background-color 200ms ease-in-out;
		border-radius: rem(8);
		font-size: rem(14);
		line-height: rem(24);
	}

	&-select {
		background-color: #00000008;
		box-shadow: none;
		border-radius: rem(8);

		.react-select-prefix__control {
			background-color: transparent;

			&:hover, &:focus {
				border-color: $primary;
			}
		}

		.react-select-prefix__placeholder {
			font-size: rem(14);
			font-weight: 400;
			line-height: rem(24);
			opacity: 0.5;
		}
	}
}

.field {
	display: block;
	width: 100%;
	position: relative;
	user-select: none;

	&__label {
		display: block;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		color: rgba(0, 49, 102, 0.53);
		font-size: rem(16);
		font-weight: 500;
		transition: 200ms ease-in-out;
		transition-property: top, transform;

		&-wrapper {
			display: block;
			height: 100%;
		}
	}

	&__holder {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-color: #00000008;
		padding: rem(8) rem(16);
		transition: background-color 200ms ease-in-out;
		border-radius: rem(8);
	}

	&__content {
		display: flex;
		align-items: center;
		height: 100%;
	}

	&__prepend {
		display: flex;
		margin-right: rem(12);
	}

	&__append {
		display: flex;
	}

	&.active {
		.field {
			&__label {
				top: 0;
				transform: translateY(0);
			}
		}
	}
}

.field__number {
	padding-bottom: rem(50);

	&__content {
		padding-bottom: rem(10);

		&-left {
			p {
				font-size: rem(16);
				font-weight: 400;
				line-height: rem(26);
				color: #003166;

				strong {
					font-weight: 700;
				}
			}
		}

		&-right {
			padding-bottom: rem(20);
			margin-left: rem(-20);
		}
	}

	&__fields {
		&-block {
			border: 0;
			outline: none;
			justify-content: flex-start;

			input {
				border: 0;
				outline: none;
				margin: rem(4);
				width: rem(40);
				font-size: rem(18);
				height: rem(26);
				padding-left: 0;
				background-color: transparent;
				color: black;
				border-bottom: rem(2) solid #C6D2E1;
				border-radius: 0;
				text-align: center;
				-moz-appearance: textfield;
				-webkit-appearance: none;

				&:hover, &:focus {
					-moz-appearance: textfield;
					-webkit-appearance: none;
					border-color: #1684F2;
				}

				&:-webkit-autofill {
					background-color: transparent;
				}
			}
		}

		.auth-code_input-box {
			margin-left: rem(6) !important;
			height: auto !important;

			&:first-of-type {
				margin-left: 0 !important;
			}
		}
	}
}
