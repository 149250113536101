.container {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  line-height: 0;
}

.container div {
  background: #007aff;
  border: solid 1px #0060c7;
  display: inline-block;
  margin-right: 4px;
  animation: sk-bounce-delay 1.4s infinite ease-in-out both;
}
.container div:nth-child(1) {
  animation-delay: -0.32s;
}
.container div:nth-child(2) {
  animation-delay: -0.16s;
}
.container div:nth-child(3) {
  margin-right: 0;
}

@keyframes sk-bounce-delay {
  0%,
  80%,
  100% {
    transform: scale(0.2);
  }
  40% {
    transform: scale(1);
  }
}
