.vacation {
    padding-top: rem(10);

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
            gap: rem(20);

            .back {
                display: flex;
                align-items: center;
            }

            h1 {
                font-size: rem(32);
                font-weight: 700;
                line-height: 38px;
            }

            p {
                font-size: rem(24);
                font-weight: 600;
                line-height: 32px;
                opacity: 0.3;
                cursor: pointer;
            }
        }

        &__title, &__btns {
            display: flex;
            align-items: center;
        }

        &__btns {
            gap: rem(20);

            &-calendar {
                display: flex;

                .btn {
                    border-radius: rem(100);
                    padding: rem(6) rem(20);
                    min-height: auto;
                    max-height: none;
                }
            }
        }
    }

    &__content {
        display: flex;
        padding-top: rem(20);
        gap: rem(20);

        &__left {
            position: sticky;
            top: 0;
            width: 100%;
            height: 100%;
            max-width: 351px;
            border: rem(1) solid rgba(0, 0, 0, 0.1);
            border-radius: rem(8);

            &__calendar {
                display: flex;
                flex-direction: column;
                flex-shrink: 0;

                &-header {
                    padding: rem(11) rem(15);
                }

                &-title {
                    font-size: rem(20);
                    font-weight: 700;
                    line-height: 28px;
                    margin-bottom: rem(11);
                }

                &-block {
                    display: flex;
                    flex-direction: column;
                }

                &-border {
                    border-bottom: rem(1) solid rgba(0, 0, 0, 0.1);
                }

                &-head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: rem(7) rem(20);
                }

                &-label {
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: rem(22);
                }

                &-count {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: rem(12);
                    font-weight: 600;
                    line-height: rem(20);
                    color: $white;
                    background-color: $blue;
                    border-radius: rem(100);
                    padding: rem(2) rem(8);
                }

                &-list {
                    display: flex;
                    flex-direction: column;
                    max-height: 45vh;
                    overflow: auto;
                }

                &-pagination {
                    &-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: rem(10) 0;
                        margin-top: rem(6);
                    }
                }
            }
        }

        &__right {
            height: 500px;
            width: 100%;
        }
    }
}