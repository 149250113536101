.service {
    position: relative;
    width: 100%;
    height: 100%;

    @media (max-width: 1500px) {
        padding-right: 1.25rem;
    }

    @media (max-width: 768px) {
        height: 100%;
        padding-right: 0;
    }

    &-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        #cities_popup {
            background: #fff;
            color: #000;
            border-radius: 0.5rem;
            width: 12.375rem;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.375rem;
            z-index: 1;

            @media (max-width: 768px) {
                background: none;
                font-size: 0.875rem;
            }

            div {
                opacity: unset;
            }

            svg {
                fill: #5900FF;
            }
        }

        h1 {
            font-weight: 700;
            font-size: 2rem;

            @media (max-width: 768px) {
                font-size: 1.125rem;
            }
        }
    }

    &-content {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, minmax(14.375rem, 1fr));
        width: 100%;
        gap: 1.25rem;
        margin-top: 1rem;

        @media (max-width: 1500px) {
            grid-template-columns: repeat(4, minmax(10.688rem, 1fr));
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(2, minmax(10.688rem, 1fr));
            gap: 0.625rem;
            padding-bottom: 12rem;
        }
    }
}