.favorites {
    &-content {
        display: flex;
        flex-direction: column;
        gap: rem(27);
        padding: rem(27) rem(60);
    }

    &__news {
        display: grid;
        grid-template-columns: repeat(4, minmax(rem(315), 1fr));
        grid-auto-rows: 1fr;
        grid-gap: rem(20);
    }

    &-news {
        &-content {
            padding: rem(30) rem(60);
            display: grid;
            grid-template-columns: repeat(4, minmax(rem(315), 1fr));
            grid-auto-rows: 1fr;
            grid-gap: rem(20);
        }
    }
    &-employees {
        &-content {
            padding: rem(30) rem(60);
            display: grid;
            grid-template-columns: repeat(5, minmax(rem(248), 1fr));
            grid-auto-rows: 1fr;
            grid-gap: rem(20);
        }
    }
}