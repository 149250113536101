.wrapper {
    border-radius: var(--borderRadius);

    &:hover {
        background-color: var(--backgroundWrapper);
    }
}

.icon {
    color: var(--whiteColor);
    background-color: var(--lightBlue);
}