.profile {
    @media (max-width: 1020px) {
        padding: rem(70) rem(0) rem(0) rem(0);
    }

    &-header {
        padding: rem(16) rem(60) rem(0) rem(60);
    }

    &-title {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.375rem;
    }

    & button {
        outline: none;
    }


    &-departments {
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;


            & div {
                display: flex;
                align-items: center;
                color: #000;
                cursor: pointer;

                & h4 {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }
        }

        &__content {
            display: grid;
            grid-template-columns: repeat(5, minmax(11.875rem, 1fr));
            grid-auto-rows: 1fr;
            grid-gap: 0.625rem;

            @media (max-width: 1020px) {
                overflow: scroll;
                padding: 0 rem(16);

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &__block {
            padding: 1.25rem;
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #ffffff;
        }

        &__image {
            width: 5rem;
            height: 5rem;
            border-radius: 1.25rem;
        }

        &__title {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5rem;
            //width: 6.25rem;
            margin-top: 0.625rem;

            @media (max-width: 600px) {
                font-size: 0.75rem;
                line-height: 1rem;
            }
        }

        &__position {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.375rem;
            opacity: 0.5;
            margin-top: 0.25rem;
            margin-bottom: 0.625rem;

            @media (max-width: 600px) { // Adjust the breakpoint as needed for 'sm'
                font-size: 0.625rem;
                line-height: 1rem;
            }
        }

        &__icons {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-top: auto;
            //margin-top: 0.625rem;

            & svg {
                cursor: pointer;
            }
        }

        &__images {
            position: relative;
            width: 5rem;
            height: 5rem;
        }

        &__icon {
            position: absolute;
            width: 1.875rem;
            height: 1.875rem;
            background: #ffffff;
            padding: 0.313rem;
            border-radius: 50%;
            left: 0.25rem;
            bottom: 0.25rem;
        }
    }


    &-work {
        display: flex;
        flex-direction: column;
        gap: rem(20);
        background: #fff;
        padding: rem(20);
        border-radius: rem(16);

        @media (max-width: 1020px) {
            margin: 0 rem(16);
        }

        &__content {
            display: grid;
            grid-template-columns: repeat(3, minmax(rem(300), 1fr));
            grid-auto-rows: auto;
            grid-gap: rem(20);
            row-gap: rem(10);

            @media (max-width: 1020px) {
                grid-template-columns: repeat(2, minmax(rem(250), 1fr));
            }

            @media (max-width: 570px) {
                grid-template-columns: repeat(1, minmax(rem(200), 1fr));
            }
        }

        &__block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: rem(5);
            height: rem(69);

            &__placeholder {
                font-size: rem(12);
                font-weight: 500;
                line-height: rem(20);
                opacity: .5;
            }

            &__value {
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(24);
                text-align: left;
            }
        }

        &__line {
            height: rem(1);
            width: 100%;
            background: rgba(0, 0, 0, 0.1);
        }

        &__skills {
            &__title {
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(22);
            }

            &__content {
                display: flex;
                flex-wrap: wrap;
                gap: rem(10);
                margin-top: rem(20);
            }

            &__block {
                border-radius: rem(100);
                padding: rem(8) rem(16);
                background: #00A7B50D;
                color: #00A7B5;
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(24);
            }
        }
    }


    &-grades {
        &__content {
            display: grid;
            grid-template-columns: repeat(2, minmax(30.125rem, 1fr));
            grid-auto-rows: auto;
            grid-gap: 1.25rem;
            margin-top: 1.25rem;
        }
    }

    &-adaptation {
        position: relative;
        background: #FFFFFF;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        padding: 1.25rem;

        &__title {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }

        &__subTitle {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-top: 1.25rem;

            & h2 {
                font-weight: 600;
                font-size: 2rem;
                line-height: 2.5rem;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 0.625rem;
            padding-left: 2.125rem;

            & p {
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.75rem;
                opacity: 0.5;
            }

            & h3 {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
    }

    &-education {
        position: relative;
        background: #ffffff;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        padding: 1.25rem;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & h1 {
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.75rem;
            }

            & p {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.375rem;
                opacity: 0.5;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__block {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__blockLeft {
            display: flex;
            align-items: center;
            gap: 1rem;

            & h3 {
                font-weight: 500;
                font-size: 1rem;
                line-height: 2.625rem;
            }
        }

        &__blockRight {
            display: flex;
            align-items: center;
            gap: 0.625rem;

            & p {
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.5rem;
            }

            & svg {
                opacity: 0.5;
            }
        }
    }

    &-aims {
        position: relative;
        background: #ffffff;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        padding: 1.25rem 1.25rem 0 1.25rem;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & h1 {
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.75rem;
            }
        }

        &__dates {
            display: flex;
            align-items: center;
            gap: 0.625rem;

            & p {
                padding: 0.25rem 0.75rem;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.375rem;
                color: #0071DA;
                background: #0071DA0D;
                border-radius: 6.25rem;
            }
        }

        &__subTitle {
            font-weight: 600;
            font-size: 0.875rem;
            opacity: 0.5;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__circleText {
            border-radius: 50%;
            text-align: center;
            padding: 0.25rem 0.625rem;
            background: #FFD600;
        }

        &__block {
            display: flex;
            justify-content: space-between;
            padding: 1rem 0;

            & p {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5rem;
            }

            & h3 {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }
    }


    &-competencies {
        position: relative;
        background: #ffffff;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        padding: 1.25rem;
        grid-column-start: 1;
        grid-column-end: 3;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 600px) {
                flex-direction: column;
                align-items: start;
            }

            h1 {
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.75rem;
            }

            p {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.375rem;
                opacity: 0.5;
            }
        }

        &__dates {
            display: flex;
            align-items: center;
            gap: 0.625rem;

            p {
                padding: 0.25rem 0.75rem;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.375rem;
                color: #0071da;
                background: #0071da0d;
                border-radius: 6.25rem;
            }
        }

        &__subHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1.25rem;

            @media (max-width: 600px) {
                flex-direction: column;
                align-items: start;
            }

            p {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.375rem;
            }

            h4 {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
                color: #299e00;
            }
        }

        &__content {
            display: flex;
            gap: 2.5rem;
            margin-top: 1.25rem;

            @media (max-width: 600px) {
                flex-direction: column;
            }

            h3 {
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }

        &__leftContent, &__rightContent {
            width: 50%;

            @media (max-width: 600px) {
                width: 100%;
            }
        }

        &__block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 0;
            box-shadow: 0px -1px 0px 0px #0000001a inset;

            p {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5rem;
            }

            h3 {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }

        &__circleText {
            border-radius: 50%;
            text-align: center;
            padding: 0.25rem 0.625rem;
        }

        &__calendars {
            display: flex;
            align-items: center;
            gap: 0.625rem;

            span {
                width: 0.438rem;
                height: 0.125rem;
                background: #000;
            }
        }

        &__calendar {
            div {
                padding: 0.5rem 0.75rem;
                background-color: #00000008;
                border-radius: 6.25rem;
                text-align: center;
                cursor: pointer;
                width: 13.563rem;

                @media (max-width: 600px) {
                    padding: 0.25rem 0.75rem;
                    background: #0071da0d;
                    border-radius: 6.25rem;
                    color: #0071da;
                    margin-top: 0.5rem;
                    height: 1.5rem;
                    width: 6.313rem;
                }

                input {
                    width: 100%;
                    text-align: center;
                    color: #000;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 1.625rem;
                    cursor: pointer;
                    padding: 0;

                    @media (max-width: 600px) {
                        color: #0071da;
                        font-weight: 500;
                        font-size: 0.75rem;
                        line-height: 1rem;
                    }
                }
            }
        }
    }


    &-services {
        margin-top: 2.5rem;

        &-addButton {
            & span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(24);
                height: rem(24);
                background: #FF0000;
                border-radius: 50%;
                font-size: rem(14);
                font-weight: 500;
                line-height: 22px;
                color: #fff;
            }
            @media (max-width: 1020px) {
                background: #00A7B5 !important;

                & p {
                    color: #fff !important;
                }

                & svg {
                    display: none;
                }
            }
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
                display: flex;
                align-items: center;
                color: #007BFF;
                cursor: pointer;

                h4 {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }

            & button {
                color: #fff;
                font-weight: 600;
                font-size: 0.875rem;
                border: none;
                line-height: 1.5rem;
                background: #5A00FE;
                border-radius: 0.5rem;
                padding: 0.5rem 1.875rem;
                cursor: pointer;
            }
        }


        &__content {
            @media (max-width: 1020px) {
                background: #FFFFFF;
                margin-top: rem(-10);
            }
        }


        &-content {
            width: 100%;
            position: relative;
            background: #ffffff;
            border-radius: 0.5rem;

            @media (max-width: 1020px) {
                background: unset;
                width: unset;
                margin: 0 rem(16);
            }

            &-mobile {
                &__header {
                    display: flex;
                    flex-direction: column;
                    gap: rem(10);
                    background: transparent;

                    &__icon {
                        transform: rotate(90deg);
                        opacity: .3;

                        & svg {
                            width: rem(30);
                            height: rem(30);
                        }
                    }

                    &__icon.active {
                        transform: translateX(7px) rotate(270deg);
                        opacity: 1;

                        & svg {
                            & path {
                                fill: #00A7B5;
                            }
                        }
                    }
                }

                &__headerItem.opened {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-bottom: 1px solid #0000001A;
                }

                &__headerItem {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #FFFFFF;
                    padding: rem(12) rem(20);
                    border-radius: rem(10);
                    height: rem(50);


                    & p {
                        font-size: rem(16);
                        font-weight: 500;
                        line-height: rem(26);
                    }
                }

                &__headerRight {
                    display: flex;
                    align-items: center;
                    gap: rem(10);
                }

                &__headerCount {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: rem(24);
                    height: rem(24);
                    border-radius: 50%;
                    background: #00A7B5;
                    font-size: rem(12);
                    font-weight: 500;
                    line-height: rem(20);
                    color: #FFFFFF;
                }
            }

            &__header {
                display: flex;
                align-items: center;
                gap: 2.5rem;
                padding: 0 1.25rem;
                box-shadow: 0px -1px 0px 0px #0000001a inset;

                & p {
                    display: flex;
                    align-items: center;
                    gap: rem(10);
                    font-weight: 500;
                    opacity: 0.5;
                    font-size: 1rem;
                    line-height: 1.625rem;
                    padding: 1rem 0;
                    cursor: pointer;

                    & span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: rem(24);
                        height: rem(24);
                        border-radius: 50%;
                        background: #0000001A;
                        opacity: 1;
                    }
                }
            }

            &__activeTitle {
                border-bottom: 1px solid #00A7B5;
                font-weight: 700;
                opacity: 1 !important;
            }

            &__approveBlock {
                @media (max-width: 1020px) {
                    justify-content: start !important;
                    align-items: start !important;
                    flex-direction: column;
                    gap: rem(10) !important;
                }
            }

            &__block {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.938rem 1.875rem;
                box-shadow: 0px -1px 0px 0px #0000001a inset;
                gap: 2.5rem;
                width: 100%;

                @media (max-width: 1020px) {
                    box-shadow: unset;
                }
            }

            &__approveName {
                font-size: rem(16);
                font-weight: 600;
                line-height: rem(24);
            }

            &__button {
                display: flex;
                align-items: center;
                background: #00A7B51A;
                padding: rem(7) rem(10);
                color: #00A7B5;
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(22);
                border-radius: rem(8);
                cursor: pointer;

                @media (max-width: 1020px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: rem(50);
                }
            }

            &__number {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
                text-decoration: underline;
                color: #007bff;
            }

            &__date {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
            }

            &__text {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
            }

            &__name {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                color: #007bff;
                text-decoration: underline;

                @media (max-width: 600px) {
                    text-decoration: unset;
                }
            }

            &__status {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
            }

            &__seperator {
                margin-left: auto;
            }

            &__mobileContent {
                display: flex;
                flex-direction: column;
                padding: 0.938rem 1.875rem;
                box-shadow: 0px -1px 0px 0px #0000001a inset;
                gap: 1rem;
            }

            &__mobileBlock {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &-sport {
        display: grid;
        grid-template-columns: repeat(3, minmax(rem(315), 1fr));
        grid-auto-rows: auto;
        grid-gap: 1.25rem;

        @media (max-width: 1020px) {
            margin: 0 rem(16);
            grid-template-columns: repeat(2, minmax(rem(275), 1fr));
            row-gap: rem(10);
        }

        @media (max-width: 600px) {
            grid-template-columns: repeat(1, minmax(rem(200), 1fr));
        }

        &__block {
            background: #fff;
            padding: rem(20);
            border-radius: rem(16);


            &__top {
                display: flex;
                justify-content: space-between;
            }

            &__notification {
                cursor: pointer;
            }

            &__left {
                display: flex;
                align-items: center;
                gap: rem(10);
            }

            &__day {
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(44);
                height: rem(44);
                color: #fff;
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(22);
                background: #00A7B5;
                border-radius: 50%;
            }

            &__week {
                font-size: rem(14);
                font-weight: 700;
                line-height: rem(20);
            }

            &__month {
                font-size: rem(12);
                font-weight: 600;
                line-height: rem(18);
                opacity: .5;
            }

            &__content {
                display: flex;
                flex-direction: column;
                margin-top: rem(10);
            }

            &__type {
                font-size: rem(12);
                font-weight: 500;
                line-height: rem(20);
                opacity: .5;
            }

            &__text {
                font-size: rem(24);
                font-weight: 600;
                line-height: rem(32);

                @media (max-width: 1020px) {
                    font-size: rem(20);
                }

            }

            &__times {
                display: flex;
                align-items: center;
                gap: rem(5);
                margin-top: rem(10);

                & p {
                    padding: rem(4) rem(12);
                    background: #0000000D;
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: rem(22);
                    border-radius: rem(100);
                }

                & span {
                    width: rem(10);
                    height: rem(1);
                    background: #000000;
                }
            }
        }
    }


    &-colleagues {
        &-header {
            &__calendar {
                cursor: pointer;
                border-radius: rem(8);
                display: flex;
                align-items: center;
                background: #FFFFFF;
                padding: rem(13) rem(20);

                &__title {
                    margin-left: rem(10);
                    font-size: rem(14);
                    font-weight: 600;
                    line-height: 24px;
                }

                & span {
                    margin-left: rem(20);
                }
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: rem(10);
        }
        &-block {
            display: flex;
            gap: rem(20);
            justify-content: space-between;
            align-items: center;
            background: #FFFFFF;
            padding: rem(10) rem(20) rem(10) rem(10);
            border-radius: rem(16);

            &__left {
                display: flex;
                gap: rem(20);
                align-items: center;
            }

            &__image {
                width: rem(60);
                height: rem(60);
            }

            &__desc {
                display: flex;
                flex-direction: column;
                gap: rem(4);

                & h1 {
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: 20px;
                }

                & p {
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: 18px;
                }
            }

            &__middle {
                display: flex;
                flex-direction: column;
                gap: rem(4);
            }

            &__placeholder {
                font-size: rem(12);
                font-weight: 500;
                line-height: 16px;
                opacity: .6;
            }

            &__date {
                font-size: rem(16);
                font-weight: 500;
                line-height: 24px;
            }

            &__button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(120);
                padding: rem(7) rem(10);
                border-radius: rem(8);
                cursor: pointer;
                background: #00A7B5;
                font-size: rem(14);
                font-weight: 600;
                line-height: 22px;
                color: #fff;
            }

            &__workDays {
                font-size: rem(16);
                font-weight: 600;
                line-height: 24px;
            }

            &__ambulanceDate {
                font-size: rem(14);
                font-weight: 500;
                line-height: 22px;
            }
        }

        &-replacing {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rem(20);
            background: #FFFFFF;
            padding: rem(10) rem(20) rem(10) rem(10);
            border-radius: rem(16);

            &__content{
                display: flex;
                align-items: center;
                width: 90%;
                gap: rem(20);
            }

            & svg {
                cursor: pointer;
            }
        }

        &-modal {
            &__vacationContent {
                display: flex;
                flex-direction: column;
                gap: rem(10);
                margin-top: rem(50);

                &__block {
                    &__title {
                        opacity: 1;
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: 22px;
                    }
                }

                &__information {
                    display: flex;
                    flex-direction: column;
                    gap: rem(10);
                    margin-top: rem(10);
                    &__title {
                        font-weight: 700;
                        font-size: rem(16);
                    }

                    &__block {
                        &__title {
                            font-size: rem(16);
                            font-weight: 600;
                            line-height: 24px;
                        }
                    }
                }
            }
            &__content {
                margin-top: rem(10);

                &__block {
                    display: flex;
                    flex-direction: column;
                    gap: rem(4);

                    &__icon {
                        width: 100%;
                        padding: rem(10) 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                }
            }

            &__placeholder {
                opacity: .6;
                font-size: rem(12);
                font-weight: 600;
                line-height: 20px;
            }

            &__information {
                display: flex;
                flex-direction: column;
                gap: rem(10);
                margin-top: rem(20);

                & h1 {
                    font-size: rem(16);
                    font-weight: 700;
                    line-height: 24px;
                }

                &__dates {
                    & h1 {
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: 24px;
                    }
                }
            }

            &__buttons {
                display: flex;
                flex-direction: column;
                gap: rem(10);
                margin-top: rem(20);

                & button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: rem(50);
                    border-radius: rem(8);
                    font-size: rem(16);
                    font-weight: 600;
                    line-height: 26px;
                    border: none;
                    outline: none;
                }

                &__accept {
                    background: #00A7B5;
                    color: #fff;
                }

                &__reject {
                    background: #FF00001A;
                    color: #FF0000;
                }
            }
        }
    }
}