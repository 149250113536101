.dropdown {
    &__block {
        min-width: rem(203);
        border-radius: rem(100);
        background: rgba(255, 255, 255);
        box-shadow: 0px 10px 32px 0px #00000008;
        height: rem(50);
        margin-bottom: 0;

        .form-icon {
            &-left, &-right {
                z-index: 1;
            }
        }

        .filter-select {
            height: 100%;
        }

        .react-select-prefix {
            &__control {
                border-radius: rem(8);
                height: 100%;
                border: 1px solid transparent;

                &--is-focused {
                    border-color: #00A7B5;
                }
            }
            &__placeholder {
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(22);
                color: #000;
            }

            &__option {
                width: 100%;
                border-radius: rem(8);
                padding: rem(12) rem(16);
                display: flex;
                align-items: center;
                gap: rem(8);

                &:hover {
                    background: #00000008;
                }
            }
            &__menu {
                box-shadow: 4px 4px 8px 0 #0000000D;
                border-radius: rem(8);
                margin-top: rem(8);

                &-list {
                    border-radius: 0;
                    padding: rem(5);
                    gap: rem(5);
                }
            }
        }

        &.left {
            .react-select-prefix__control {
                padding-left: rem(15 + 25);
            }
        }

        &.right {
            .react-select-prefix__control {
                padding-right: rem(15 + 25);
            }
        }

        .filter-select {
            width: 100%;
        }

        &.disabled {
            cursor: no-drop;
        }

		&.default-list {
			margin-bottom: rem(20);

			.react-select-prefix {
				&__value-container {
					padding: rem(9) 0;
				}

				&__indicator {
					padding-right: 8px;
				}

				&__placeholder {
					margin-left: 0;
				}
			}

			div .react-select-prefix {
				&__menu {
					overflow: inherit;

					&-list {
						width: 100%;
						border: none;
                        border-radius: 0 !important;
						box-shadow: 0 4px 50px rgba(0, 49, 102, 0.16);
					}
				}
			}
		}
    }

	&__label {
		color: #00316653;
		font-size: rem(15);
		font-weight: 500;
	}
}

div .react-select-prefix {
    &__single-value {
        color: #000 !important;
        font-size: rem(16);
        font-weight: 600;
        line-height: rem(24);
    }

    &__option {
        color: #000 !important;
        font-size: rem(14);
        font-weight: 500;
        line-height: rem(24);

        &--is-selected {
            color: #00A7B5 !important;
            background:  none;
        }

    }
    &__control {
        width: 100%;
        cursor: pointer;
        border-radius: rem(100);
        box-shadow: none;
        height: auto;

        &--menu-is-open {
            border-radius: rem(8);
            border-bottom: 0;

            .react-select-prefix__indicator {
                svg {
                    transform: rotate(180deg);

                    path {
                        stroke: #00A7B5;
                    }
                }
            }
        }
    }

    &__indicator {
        svg {
            //@include transition()

            path {
                stroke: rgba(0, 0, 0, 0.2);
            }
        }
    }

    &__single {
        &-value {
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__value {
        &-container {
            padding: rem(9) rem(15);
        }
    }

    &__menu {
        display: flex;
        align-self: center;
        justify-content: center;
        background: none;
        box-shadow: 0px 10px 32px 0px #00000008;
        border: none;
        cursor: pointer;
        color: #FFFFFF;
        z-index: 5;
        margin: 0;
        border-top: 0;

        overflow: hidden;


        div {
            cursor: pointer;
        }

        &-list {
            border-radius: rem(8);
            justify-self: center;
            align-self: center;
            width: 100%;
            background: #FFFFFF;
        }
    }

    &__menu-list {
        z-index: 5;
        padding: 0;
        max-height: rem(200);
        overflow: auto;
    }

    &__option {
        padding: rem(13) rem(10);

        //&:hover {
        //    border-radius: 4px;
        //    background: linear-gradient(90deg, rgba(74, 128, 238, 0.20) 0%, rgba(31, 72, 165, 0.20) 100%);
        //}
        //
        &--is-focused {
            background: none;
        }
    }

}

.header__map {
    &-dropdown {
        border-radius: rem(100);

        div .react-select-prefix__control {
            //background-color: #2E2F37;
            border-radius: rem(100);

            &--menu-is-open {
                border-radius: rem(25) rem(25) 0 0;
            }
        }
    }
}
