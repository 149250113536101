.form {
	&-block {
		margin-bottom: rem(30);

		label {
			display: block;
			margin-bottom: rem(8);
		}

		.error-message {
			margin-top: rem(8);
			font-size: rem(14);
			line-height: rem(24);
			color: $error;
		}

		.form {
			&-group {
				&:first-of-type {
					.field__holder {
						border-radius: rem(10) rem(10) rem(3) rem(3);
					}
				}

				&:last-of-type {
					.field__holder {
						border-radius: rem(3) rem(3) rem(10) rem(10);
					}
				}
			}
		}
	}

	&-email {
		position: relative;

		.confirm {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: rem(15);
			font-size: rem(16);
			font-weight: 400;
			line-height: rem(26);
			color: $blue-second;
			border: none;
			background-color: transparent;
			cursor: pointer;
		}
	}

	&-group {
		position: relative;
		margin-bottom: rem(-2);

		&.error {
			.field__holder {
				border-color: $red;
			}
		}

		&-btn {
			margin: rem(15) 0 rem(30);
		}
	}

	&-datepicker {
		display: flex;
		width: 100%;
		z-index: 5;
		border-radius: 0;
		background-color: #FFFFFF;
		padding: 0.5rem 1rem;
		transition: background-color 200ms ease-in-out;

		.MuiInputBase-formControl {
			height: 100%;

			&:hover {
				fieldset {
					border-color: #C6D2E1;
				}
			}
		}

		fieldset {
			//border: rem(2) solid #C6D2E1;
			bottom: rem(-2);
			top: 0;

			legend {
				display: none;
			}
		}

		label {
			left: rem(16);
			top: 50%;
			transform: translateY(-50%);
			color: rgba(0, 49, 102, 0.53);
			font-size: rem(16);
			font-weight: 500;
			transition: 200ms ease-in-out;
			transition-property: top, transform;
		}

		.MuiInputLabel-shrink {
			top: 0;
			transform: translateY(5px);
		}

		input {
			padding: rem(28) rem(16) rem(8) rem(16);
			font-size: rem(15);
		}
	}
}

.error-message {
	display: block;
	visibility: hidden;
	overflow: hidden;
	opacity: 0;
	height: 0;
	color: $red;
	font-size: rem(13);
	font-weight: 600;
	transition: all 0.3s ease-in-out;

	&.active {
		height: rem(15);
		visibility: visible;
		overflow: auto;
		opacity: 1;
		margin-top: rem(10);
	}
}

.not-found-page {
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	h3 {
		margin: rem(15) 0;
	}
}

.vertical-center {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: rem(10) rem(20);
	height: 100vh;
}

.checkbox {
	&_block {
		position: relative;
		padding-bottom: rem(15);
		display: flex;
	}
}

.radio {
	&_block {
		position: relative;
	}
}

.custom_checkbox {
	position: absolute;
	z-index: -1;
	opacity: 0;

	&_check {
		display: flex !important;
		justify-content: center;
		align-items: center;
		width: rem(24);
		height: rem(24);
		flex-shrink: 0;
		border: rem(2) solid #00000030;
		border-radius: rem(4);
		margin-top: rem(5);
		cursor: pointer;
		padding: 0 !important;

		&.active {
			border-color: #007aff;
		}
	}

	&_label {
		font-size: rem(15);
		font-weight: 400;
		color: #003166;
		cursor: pointer;
		display: flex;

		span {
			display: block;
			padding-left: rem(15);
			font-size: rem(14);
			font-weight: 400;
			line-height: rem(20);
			color: #003166;
			cursor: pointer;
		}

		button {
			font-size: rem(12);
			font-weight: 600;
			color: #007aff;
			cursor: pointer;

			&:hover, &:focus {
				color: #0270e8;
			}
		}
	}
}

.custom_radio {
	position: absolute;
	z-index: -1;
	opacity: 0;

	&:checked {
		+ .custom_radio_label {
			&:after {
				border-color: #007AFF;
				background-color: #007AFF;
			}
		}
	}

	&:not(:disabled):not(:checked) {
		+ .custom_checkbox_label {
			&:hover:before {
				border-color: #b3d7ff;
			}
		}
	}

	&:not(:disabled):active {
		+ .custom_checkbox_label {
			&:before {
				background-color: #b3d7ff;
				border-color: #b3d7ff;
			}
		}
	}

	&:focus:not(:checked) {
		+ .custom_checkbox_label {
			&:before {
				border-color: #80bdff;
			}
		}
	}

	&:disabled {
		+ .custom_checkbox_label {
			&:before {
				background-color: #e9ecef;
			}
		}
	}

	&_label {
		font-size: rem(15);
		font-weight: 400;
		color: #003166;
		cursor: pointer;
		display: flex;
		align-items: center;

		&:before {
			content: '';
			display: inline-block;
			min-width: rem(24);
			max-width: rem(24);
			min-height: rem(24);
			max-height: rem(24);
			border: rem(2) solid rgba(25, 88, 250, 0.2);
			border-radius: 50%;
		}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: rem(2);
			display: inline-block;
			width: rem(20);
			height: rem(20);
			border-radius: 50%;
		}

		span {
			display: block;
			padding-left: rem(15);
			font-size: rem(16);
			font-weight: 400;
			line-height: rem(24);
			color: $black;
			cursor: pointer;
		}

		button {
			font-weight: 600;
			color: #007aff;

			&:hover, &:focus {
				color: #0270e8;
			}
		}
	}
}

.gap-3 {
	gap: rem(10);
}

.home__swiper {
	width: 100%;
	border-radius: rem(8);
	overflow: hidden;
	position: relative;

	&-info {
		padding: rem(30);

		&__text {
			padding-bottom: rem(26);
		}

		&__btn {
			max-width: rem(185);
		}
	}

	img {
		object-fit: cover;
	}

	.swiper-pagination-fraction,
	.swiper-pagination-custom,
	.swiper-container-horizontal > .swiper-pagination-bullets {
		text-align: left;
		padding: rem(30) rem(30 - 5);
		bottom: 0;

		.swiper-pagination-bullet {
			background-color: $white;
			opacity: 0.5;
			margin: 0 rem(5);
			width: rem(10);
			height: rem(10);
		}

		.swiper-pagination-bullet-active {
			background-color: $white;
			opacity: 1;
		}
	}
}

.nav {
	&-tabs {
		list-style: none;
		display: flex;
		padding: 0 rem(20 - 12.5);
	}

	&-item {
		padding: 0 rem(12.5);
	}

	&-link {
		display: block;
		font-size: rem(16);
		font-weight: 500;
		line-height: rem(26);
		padding: rem(10) 0;
		border: none;
		background-color: transparent;
		border-bottom: rem(2) solid transparent;

		&.active {
			border-bottom-color: #1958FA;
		}
	}
}

.tab {
	&-content {}

	&-pane {
		display: none;

		&.active {
			display: flex;
		}
	}
}

.nav-drawer {
	display: block;
    width: rem(360);
    height: 100%;
    position: fixed;
    left: rem(-360);
    top: 0;
    background-color: $white;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 6;

	&.active {
		left: 0;
	}

	&__content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&__close {
		display: flex;
		background-color: transparent;
		border: none;
		margin: rem(28) rem(60) 0;
	}

	&__profile {
		display: flex;
		align-items: center;
		padding: rem(30) rem(30) 0;

		&-img {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $white;
			border-radius: 50%;
			overflow: hidden;

			img{
				width: rem(60);
				height: rem(60);
			}
		}

		&-name {
			padding-left: rem(15);

			.title {
				font-size: rem(20);
				font-weight: 700;
				line-height: rem(28);
				margin-bottom: rem(8);
			}

			.header__profile-star {
				margin-left: 0;
				margin-right: rem(10);
			}
		}

		&-icons {
			display: flex;
			align-items: center;
		}
	}

	&__list {
		list-style: none;
		margin: 0 rem(30);
		padding-top: rem(10 - 2);
	}

	&__item {
		padding-top: rem(4);

		.btn {
			justify-content: flex-start;
			background-color: transparent;
			color: $black;
			border-radius: rem(8);
			padding: rem(13) 0;

			span {
				font-size: rem(16);
				font-weight: 500;
				display: block;
				margin: 0 rem(15);
			}

			&:hover, &:focus {
				color: $black;
			}

			.count {
				display: flex;
				align-items: center;
				justify-content: center;
				min-width: rem(26);
				min-height: rem(26);
				font-size: rem(12);
				font-weight: 600;
				line-height: rem(20);
				background-color: #1a59fb;
				padding: rem(1) rem(4);
				color: $white;
				border-radius: rem(100);
				margin-left: auto;
				margin-right: 0;
			}
		}

		&.active {
			.btn {
				//padding: rem(16) rem(15);
				//background-color: rgba(25, 88, 250, 0.05);

				span {
					font-weight: 600;
				}
			}
		}
	}

	&__block {
		overflow-y: auto;
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */

		&::-webkit-scrollbar {
			display: none;  /* Safari and Chrome */
		}
	}

	&__footer {
		padding: rem(20) rem(30) 0;
		border-top: 1px solid rgba(0, 0, 0, 0.1);

		.btn {
			width: 100%;
			margin-bottom: rem(10);

			&-link {
				color: $black;
				min-height: rem(60);
				font-size: rem(12);
				font-weight: 600;
			}
		}

		.btn + .btn {
			margin-left: 0;
		}
	}
}

.spinner {
	animation: rotate 2s linear infinite;
	z-index: 2;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
	width: 50px;
	height: 50px;

	.path {
		stroke: #99A2AD;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	&__wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
		display: none;
		align-items: center;
		justify-content: center;
		z-index: 5;

		&.active {
			display: flex;
		}
	}

	&-block {
		width: 88px;
		height: 88px;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.08), 0 16px 16px rgba(0, 0, 0, 0.08);
		background-color: #F7F8FA;
		border-radius: 8px;
	}
	&__container {
		display: none;
		position: relative;
		width: 100%;
		height: 50px;

		&.active {
			display: block;
		}
	}
}

.breadcrumb {
	display: flex;
	align-items: center;
	list-style: none;

	&-item {
		&:first-of-type:before {
			content: none;
		}

		&:before {
			display: inline-block;
			padding-right: rem(10);
			color: #6c757d;
			content: ">";
		}

		& + & {
			padding-left: rem(10);
		}

		a {
			opacity: 0.5;
		}
	}

	&-item, a {
		font-size: rem(14);
		font-weight: 600;
		line-height: rem(22);
	}
}

.btn {
	&.setting {
		&__icon {
			position: absolute;
			top: rem(12);
			right: rem(12);
			width: rem(36);
			cursor: pointer;
			min-height: rem(36);
			max-height: rem(36);
			display: flex;
			align-items: center;
			opacity: 0;
			z-index: 100;
			box-shadow: 0 rem(4) rem(20) rgba(0, 49, 102, 0.22);
			transition: opacity 0.2s ease-in-out;
			border-radius: 50%;
			justify-content: center;
			background-color: #FFFFFF;

			&:hover {
				opacity: 1;
				background-color: #FFFFFF;
			}

			div {
				display: flex;
			}
		}
	}
}

.blank-ava {
	&-container {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		text-transform: capitalize;

		p {
			font-weight: 600;
			font-size: rem(30);
		}
	}
}

.dot {
	width: rem(8);
	cursor: pointer;
	height: rem(8);
	margin-right: rem(12);
	border-radius: 50%;
	background-color: #00000014;

	&.selected {
		background-color: #195aff;
	}

	&s {
		&__container {
			width: 100%;
			margin: rem(16) rem(20);
			display: flex;
			justify-content: flex-start;
		}
	}
}

.ml-0 {
	margin-left: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.cursor-pointer {
	cursor: pointer;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
	-webkit-background-clip: text;
	-webkit-text-fill-color: #ffffff;
	transition: background-color 5000s ease-in-out 0s;
	box-shadow: inset 0 0 20px 20px transparent;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
