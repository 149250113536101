@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
	url('../../public/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
	url('../../public/fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
	url('../../public/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
	url('../../public/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
	url('../../public/fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat Black'), local('Montserrat-Black'),
	url('../../public/fonts/montserrat/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'icomoon';
	src: url('../../public/fonts/icomoon/icomoon.eot?remyi6');
	src: url('../../public/fonts/icomoon/icomoon.eot?remyi6#iefix') format('embedded-opentype'),
	url('../../public/fonts/icomoon/icomoon.ttf?remyi6') format('truetype'),
	url('../../public/fonts/icomoon/icomoon.woff?remyi6') format('woff'),
	url('../../public/fonts/icomoon/icomoon.svg?remyi6#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-eye:before {
  content: "\e900";
}
.icon-news:before {
  content: "\e901";
}
.icon-notification:before {
  content: "\e902";
}
.icon-percentage:before {
  content: "\e903";
}
.icon-question .path1:before {
  content: "\e905";
  color: rgb(233, 240, 249);
}
.icon-question .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(0, 49, 102);
  opacity: 0.53;
}
.icon-search:before {
  content: "\e907";
}
.icon-services:before {
  content: "\e908";
}
.icon-speech-buble .path1:before {
  content: "\e90a";
  color: rgb(25, 88, 250);
}
.icon-speech-buble .path2:before {
  content: "\e90b";
  margin-left: -1.125em;
  color: rgb(86, 185, 233);
}
.icon-staff:before {
  content: "\e90c";
}
.icon-star:before {
  content: "\e909";
}
.icon-user:before {
  content: "\e90d";
}
.icon-arrow:before {
  content: "\e90f";
}
.icon-chart:before {
  content: "\e911";
}
.icon-chat_bubble:before {
  content: "\e916";
}
.icon-check_all:before {
  content: "\e917";
}
.icon-clock:before {
  content: "\e918";
}
.icon-close:before {
  content: "\e90e";
}
.icon-facebook_like:before {
  content: "\e910";
}
.icon-home:before {
  content: "\e912";
}
.icon-hot_air_balloon:before {
  content: "\e913";
}
.icon-list:before {
  content: "\e914";
}
.icon-megaphone:before {
  content: "\e915";
}
.icon-menu:before {
  content: "\e919";
}
