.workspace {
    position: relative;
    width: 100%;
    height: 100%;

    &-select {
        width: rem(203);
        & .react-select-prefix__control {
            border-radius: 0.5rem;

            & .react-select-prefix__placeholder {
                font-weight: 500;
                font-size: 0.875rem;
                color: #000;
            }

            &--menu-is-open {
                .react-select-prefix__indicator {
                    & svg {
                        & path {
                            opacity: .5;
                            stroke-width: 2px;
                            stroke: #00A7B5 !important;
                        }
                    }
                }
            }
        }

        .react-select-prefix__indicator {
            & svg {
                & path {
                    opacity: .5;
                    stroke-width: 2px;
                    stroke: #000 !important;
                }
            }
        }
    }

    @media (max-width: 1500px) {
        padding-right: 1.25rem;
    }

    &-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        #cities_popup {
            background: #fff;
            color: #000;
            border-radius: 0.5rem;
            width: 12.375rem;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.375rem;
            z-index: 1;

            @media (max-width: 768px) {
                background: none;
                font-size: 0.875rem;
            }

            div {
                opacity: unset;
            }

            svg {
                fill: #5900FF;
            }
        }

        h1 {
            font-weight: 700;
            font-size: 2rem;

            @media (max-width: 768px) {
                font-size: 1.125rem;
            }
        }
    }

    &-content {
        display: flex;
        align-items: center;
        gap: 1.25rem;
    }

    &-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.25rem;
        background: #ffffff;
        padding: 1.25rem;
        border-radius: 1rem;
        width: 19.688rem;
        height: 10rem;
        margin-top: 1rem;
        cursor: pointer;

        h3 {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5rem;

            @media (max-width: 768px) {
                font-size: 0.875rem;
                text-align: center;
            }
        }
    }
}