.employees {
    display: flex;
    flex-direction: column;
    gap: rem(30);
    padding: rem(10) rem(60);

    @media (max-width: 1020px) {
        padding: rem(83) rem(15) rem(50) rem(15);
        gap: rem(20);
    }

    &-header {
        &__right {
            display: flex;
            align-items: center;
            gap: rem(10);
            height: rem(50);

            @media (max-width: 1020px) {
                width: 100%;

                .form-group {
                    width: 100%;
                }
            }
        }

        &__structure {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: rem(10);
            width: rem(231);
            height: rem(50);
            background: #fff;
            padding: rem(20) rem(13);
            border-radius: rem(8);
            box-shadow: 0px 10px 32px 0px #00000008;

            @media (max-width: 1020px) {
                width: 100%;
            }

            @media (max-width: 570px) {
                margin-top: rem(-10);
            }


            & p {
                font-size: rem(14);
                font-weight: 600;
                line-height: rem(22);
                text-wrap: nowrap;
            }
        }
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(5, minmax(rem(245), 1fr));
        grid-auto-rows: 1fr;
        grid-gap: rem(20);

        @media (max-width: 1440px) {
            grid-template-columns: repeat(4, minmax(rem(210), 1fr));
        }

        @media (max-width: 1020px) {
            grid-template-columns: repeat(3, minmax(rem(180), 1fr));
        }

        @media (max-width: 760px) {
            grid-template-columns: repeat(2, minmax(rem(167), 1fr));
            grid-gap: rem(10);
        }

        @media (max-width: 370px) {
            grid-template-columns: repeat(1, minmax(rem(167), 1fr));
            grid-gap: rem(10);
        }
    }

    &-block {
        display: flex;
        flex-direction: column;
        gap: rem(20);
        background: #fff;
        padding: rem(20);
        border-radius: rem(16);

        &__top {
            display: flex;
            justify-content: space-between;
        }

        &__likeIcon {
            position: relative;
            height: fit-content;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__likeIcon.active {
            & svg {
                fill: #00A7B5;

                & g {
                    opacity: 1;
                }

                & path {
                    stroke: #00A7B5;
                }
            }
        }

        &__image {
            width: rem(60);
            height: rem(60);
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: rem(6);
            cursor: pointer;
        }

        &__title {
            width: 70%;
            font-size: rem(16);
            font-weight: 600;
            line-height: rem(20);

            @media (max-width: 1020px) {
                width: 100%;
                font-size: rem(14);
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &__text, &__position {
            font-size: rem(14);
            font-weight: 500;
            line-height: rem(18);
        }
    }

    &-modal {

        &__top {
            display: flex;
            align-items: center;
            position: absolute;
            gap: rem(20);
            top: rem(20);
            height: rem(60);

            & img {
                width: rem(60);
                height: rem(60);
            }

            & h1 {
                width: 80%;
                font-size: rem(20);
                font-weight: 600;
                line-height: rem(26);

                @media (max-width: 1020px) {
                    width: 50%;
                }
            }
        }

        &__contacts {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rem(10);
            margin-top: rem(50);

            @media (max-width: 1020px) {
                flex-direction: column;
            }


            &__block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: rem(44);
                padding: rem(11) rem(10);
                background: #00A7B50D;
                gap: rem(10);
                border-radius: rem(8);

                @media (max-width: 1020px) {
                    width: 100%;
                }

               & p {
                   font-size: rem(14);
                   font-weight: 500;
                   line-height: rem(22);
                   text-align: left;
               }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: rem(10);
            margin-top: rem(20);

            &__placeholder {
                font-size: rem(12);
                font-weight: 600;
                line-height: rem(20);
                opacity: .6;
            }

            &__value {
                font-size: rem(14);
                font-weight: 500;
                line-height: rem(22);
            }

            &__list {
                display: flex;
                flex-direction: column;

                &__block {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-left: rem(5);

                    & span {
                        position: absolute;
                        width: rem(3);
                        height: rem(3);
                        background: #000;
                        border-radius: 50%;
                        content: '';
                    }

                    & p {
                        font-size: rem(14);
                        font-weight: 500;
                        line-height: rem(22);
                        margin-left: rem(15);
                    }
                }
            }
        }

        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: rem(20);
            height: rem(50);
            width: 100%;
            border: 1px solid #0000001A;
            border-radius: rem(8);
            gap: rem(10);
            cursor: pointer;

            & p {
                font-size: rem(14);
                font-weight: 500;
                line-height: rem(22);
            }
        }

        &__button.active {
            background: #00A7B50D;
            border: none;
        }

        &__people {
            display: flex;
            flex-direction: column;
            gap: rem(10);
            margin-top: rem(20);

            &__block {
                display: flex;
                align-items: center;
                gap: rem(20);
                padding: rem(15);
                border-radius: rem(16);
                margin-top: rem(10);
                background: #00000008;

                @media (max-width: 1020px) {
                    align-items: start;
                }


                &__content {
                    display: flex;
                    flex-direction: column;
                    gap: rem(4);

                    & h1 {
                        font-size: rem(16);
                        font-weight: 600;
                        line-height: rem(24);
                    }

                    & div {
                        display: flex;
                        align-items: center;
                        gap: rem(10);

                        & p {
                            font-size: rem(14);
                            font-weight: 500;
                            line-height: rem(22);
                            text-align: left;

                        }
                    }
                }
                & img {
                    width: rem(60);
                    height: rem(60);
                }
            }
        }
    }
}